import _ from 'lodash';
import { DropdownProps } from 'primereact/dropdown';
import { ReactNode } from 'react';

import store from '../../redux/store';

export function getCountryString() {
  return process.env.REACT_APP_COUNTRY || 'mk';
}

export function saveFile(
  data: any,
  name: string,
  type: string = 'application/pdf'
): void {
  const url = window.URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);

  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function httpQueryObject(obj: Record<string, any>): Record<string, any> {
  const keysToPick = Object.keys(obj).filter((k) => {
    const value = obj[k];

    if (typeof value === 'number') {
      return !isNaN(value);
    }

    return !!value;
  });

  return _.pick(obj, keysToPick);
}

export function tabViewIndexFromLocation(
  pathname: string,
  baseRoute: string,
  tabPaths: string[]
) {
  const currentTabPath = pathname.replace(`${baseRoute}/`, '');

  const currentTabIndex = tabPaths.findIndex((r) => r === currentTabPath);

  return currentTabIndex === -1 ? 0 : currentTabIndex;
}

export function getOldWebUrl(): string {
  const accessToken = store.getState().user.access_token;

  return `${process.env.REACT_APP_OLD_WEB_URL}?transfer-token=${accessToken}`;
}

export function nonEmptyObjectsOnly(objectsArr: object[]) {
  return objectsArr.filter((o) => Object.keys(o).length > 0);
}

export function placeItemTemplate(option: {
  label: string;
  postal_code: string | number | null;
}): string {
  return option.postal_code
    ? `${option.label} (${option.postal_code})`
    : option.label;
}

export function placeValueTemplate(
  option: {
    label: string;
    postal_code: string | number | null;
  } | null,
  props: DropdownProps
): ReactNode {
  if (!option) {
    if (props.placeholder) {
      return <span>{props.placeholder}</span>;
    }

    // Primereact's Dropdown component has a CSS bug when this element is empty
    return <span style={{ visibility: 'hidden' }}>empty</span>;
  }

  return option.postal_code
    ? `${option.label} (${option.postal_code})`
    : option.label;
}
