import './App.scss';
import './styles/global.scss';
import './styles/theme-override.scss';

import * as Sentry from '@sentry/react';
import { History } from 'history';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { MenuContextProvider } from './context/MenuContext';
import { PhoneOptionsContextProvider } from './context/PhoneOptionsContext';
import { SidebarFiltersContextProvider } from './context/SidebarFiltersContext';
import ToastContext from './context/ToastContext';
import ErrorDialog from './features/Dialogs/ErrorDialog/ErrorDialog';
import FaviconLoader from './features/Favicon/FaviconLoader';
import { Header } from './features/Header/Header';
import NetworkNotifier from './features/Network/Notifier/NetworkNotifier';
import MainSection from './MainContent';
import { dismissError } from './redux/actions/httpActions';
import { ReduxState } from './types/redux';

type Props = {
  history: History;
};

function App({ history }: Props): JSX.Element {
  const toastRef = useRef<Toast>(null);
  const bottomRightToastRef = useRef<Toast>(null);

  const [routerLocation, setRouterLocation] = useState<string | null>(null);

  const dispatch = useDispatch();

  const httpState = useSelector<ReduxState, ReduxState['http']>(
    (state) => state.http,
    shallowEqual
  );

  const isUserLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['isLoggedIn']
  >((state) => state.user.isLoggedIn);

  return (
    <PhoneOptionsContextProvider>
      <ToastContext.Provider value={{ toastRef, bottomRightToastRef }}>
        <MenuContextProvider>
          <FaviconLoader />
          <NetworkNotifier />

          <ReactTooltip
            id="tooltip-top"
            place="top"
            effect="solid"
            multiline
            className="main-tooltip"
          />
          <ReactTooltip
            id="tooltip-right"
            place="right"
            effect="solid"
            multiline
            className="main-tooltip"
          />
          <ReactTooltip
            id="tooltip-left"
            place="left"
            effect="solid"
            multiline
            className="main-tooltip"
          />
          <ReactTooltip
            id="tooltip-bottom"
            place="bottom"
            effect="solid"
            multiline
            className="main-tooltip"
          />

          <Router history={history}>
            {routerLocation?.includes('/track/') && !isUserLoggedIn ? null : (
              <Header />
            )}
            <SidebarFiltersContextProvider>
              <MainSection
                routerLocation={routerLocation}
                setRouterLocation={setRouterLocation}
              />
            </SidebarFiltersContextProvider>
          </Router>

          <ErrorDialog
            isVisible={httpState.hasErrorOccured}
            onHide={() => {
              dispatch(dismissError());
            }}
          >
            {httpState.message}
          </ErrorDialog>

          <Toast ref={toastRef} />
          <Toast ref={bottomRightToastRef} position="bottom-right" />
        </MenuContextProvider>
      </ToastContext.Provider>
    </PhoneOptionsContextProvider>
  );
}

export default process.env.NODE_ENV !== 'development'
  ? Sentry.withProfiler(App)
  : App;
