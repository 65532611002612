import classNames from 'classnames';

import {
  getEmailActionLabels,
  getEmailRecipientLabels,
} from '../../../configs/email';
import { EmailAction } from '../../../enums/email';
import styles from './EmailConfiguration.module.scss';
import Recipient from './Recipient';

type Props = {
  emailAction: EmailAction;
  emailActionLabels: ReturnType<typeof getEmailActionLabels>;
  emailRecipientLabels: ReturnType<typeof getEmailRecipientLabels>;
};

function Action({
  emailAction,
  emailActionLabels,
  emailRecipientLabels,
}: Props): JSX.Element {
  return (
    <section className={classNames(styles.action, 'p-mb-5')}>
      <h3>{emailActionLabels[emailAction]}</h3>

      {Object.keys(emailRecipientLabels).map((key) => (
        <Recipient
          key={key}
          emailAction={emailAction}
          emailRecipient={parseInt(key)}
          emailRecipientLabels={emailRecipientLabels}
        />
      ))}
    </section>
  );
}

export default Action;
