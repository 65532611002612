import classNames from 'classnames';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { HTMLAttributes, ReactNode, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SidebarFiltersContext } from '../../../../context/SidebarFiltersContext';
import { useSidebarFiltersHeight } from '../hooks/useSidebarFilterHeight';
import styles from './CollapsableFilters.module.scss';

type Props = {
  children: ReactNode;
  activeFiltersCount?: number;
  childrenWrapperClassName?: string;
} & HTMLAttributes<HTMLElement>;

function CollapsableFilterSection({
  childrenWrapperClassName,
  className,
  children,
  activeFiltersCount,
  ...other
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { isFiltersExpanded, setIsFiltersExpanded } = useContext(
    SidebarFiltersContext
  );

  const accordionRef = useRef<HTMLDivElement>(null);
  const accordionHeight = useSidebarFiltersHeight(
    accordionRef,
    isFiltersExpanded
  );

  return (
    <section
      className={classNames(
        styles.sidebarItem,
        styles.expandedFilters,
        {
          [styles.collapsedFilters]: !isFiltersExpanded,
        },
        className
      )}
      ref={accordionRef}
      style={{ height: accordionHeight }}
      onClick={() => {
        if (!isFiltersExpanded) {
          setIsFiltersExpanded(true);
        }
      }}
      {...other}
    >
      <h3>
        <div>
          <i className="fa fa-filter p-mr-4 p-text-secondary p-overlay-badge">
            {typeof activeFiltersCount !== 'undefined' &&
              activeFiltersCount > 0 && (
                <Badge
                  style={{ scale: '.9' }}
                  severity="danger"
                  value={activeFiltersCount}
                />
              )}
          </i>
          {isFiltersExpanded && t('Filters')}
        </div>
      </h3>

      {isFiltersExpanded && (
        <Button
          icon={isFiltersExpanded ? 'fa fa-chevron-left' : undefined}
          iconPos={isFiltersExpanded ? 'right' : undefined}
          onClick={() => {
            setIsFiltersExpanded(false);
          }}
          className={classNames(
            styles.filtersCollapseButton,
            'p-button-secondary p-button-text'
          )}
        />
      )}

      <div
        className={classNames(childrenWrapperClassName, {
          [styles.collapsedChildren]: !isFiltersExpanded,
        })}
      >
        {children}
      </div>
    </section>
  );
}

export default CollapsableFilterSection;
