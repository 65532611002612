import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  OrdinaryFilter,
  RequiredFilter,
  getLabel,
  getOrdinaryTooltip,
  getRequiredTooltip,
} from './TableHeaderFilters.function';

type Props = {
  ordinary: OrdinaryFilter[];
  required?: RequiredFilter[];
};

function TableHeaderFilters({ ordinary, required = [] }: Props): JSX.Element {
  const { t } = useTranslation();

  const filters = useMemo<JSX.Element>(() => {
    const requiredFilters = required.map((f, i) => {
      const label = getLabel(f);
      const tooltip = getRequiredTooltip(f);

      const buttonClassName = classNames(
        'p-button-sm p-button-rounded p-button-outlined p-button-secondary filter-chip',
        // "Filters" button in Table always has a margin-right CSS property set,
        //  so margin-left on the first chip button is superfluous
        { 'p-ml-2': i !== 0 }
      );

      const button = (
        <Button
          type="button"
          label={label}
          disabled
          className={buttonClassName}
          style={{ color: 'var(--surface-800)' }}
        />
      );

      return tooltip.length > 0 ? (
        <span key={f.label} data-tip={tooltip} data-for="tooltip-top">
          {button}
        </span>
      ) : (
        <Fragment key={f.label}>{button}</Fragment>
      );
    });

    const ordinaryFilters = ordinary.map((f) => {
      const label = getLabel(f);
      const tooltip = getOrdinaryTooltip(t, f);

      return (
        <Button
          key={f.label}
          type="button"
          label={label}
          icon="fas fa-times-circle"
          iconPos="right"
          tooltip={tooltip}
          tooltipOptions={{ position: 'top' as const }}
          onClick={f.onDelete}
          className="p-ml-2 p-button-sm p-button-rounded p-button-outlined p-button-danger filter-chip"
        />
      );
    });

    return <>{[...requiredFilters, ...ordinaryFilters]}</>;
  }, [ordinary, required, t]);

  return filters;
}

export default TableHeaderFilters;
