import {
  CalculatorResponse,
  Payer,
  Payment,
  PaymentMethod,
  ServiceType,
} from '../../types/api/orders';
import { Unpacked } from '../../types/util';

export function paymentToPayer(
  klient_id: number,
  klient_od_id: number,
  klient_do_id: number
): Payer {
  switch (klient_id) {
    case klient_od_id:
      return Payer.Sender;
    case klient_do_id:
      return Payer.Recipient;
    default:
      return Payer.Orderer;
  }
}

// Calculator request parameters are translated into english.
//  This function to translate the same from macedonian into english.
export function servicePayerAttributeEn(service: ServiceType) {
  switch (service) {
    case ServiceType.Postage:
      return 'shipping_payer';
    case ServiceType.Redemption:
      return 'redemption_payer';
    case ServiceType.ReturnDocument:
      return 'return_document_payer';
    case ServiceType.Insurance:
      return 'insurance_payer';
    default:
      return 'shipping_payer';
  }
}

// Calculator request parameters are translated into english.
//  This function to translate the same from macedonian into english.
export function serviceCashAttributeEn(service: ServiceType) {
  switch (service) {
    case ServiceType.Postage:
      return 'shipping_pay_cash';
    case ServiceType.Redemption:
      return 'redemption_pay_cash';
    case ServiceType.ReturnDocument:
      return 'return_document_pay_cash';
    case ServiceType.Insurance:
      return 'insurance_pay_cash';
    default:
      return 'shipping_pay_cash';
  }
}

// Calculator responses are translated into english.
//  This function is used to translate them back.
export function calculatorPaymentToPayment(
  p: Unpacked<CalculatorResponse>
): Partial<Payment> {
  return {
    klient_id: p.customer_id,
    opis: p.description,
    gotovina: p.cash as PaymentMethod,
    kolicina: p.quantity,
    ediznos: p.unit_price,
    iznos: p.price,
    tarifa_id: p.tariff_id,
    cenovnik_id: p.pricelist_id,
    usluga_id: p.service_id as ServiceType,
    usluga_ime: p.service_name,
    ddv: p.vat_percent,
    created: p.created,
  };
}
