import { TFunction, Trans } from 'react-i18next';

export function passwordFeedback(t: TFunction) {
  return (
    <>
      <b className="p-d-block p-py-2">
        {t('components.password.feedbackTitle')}
      </b>

      <Trans t={t} i18nKey="components.password.feedbackContent">
        <ul className="p-m-0 p-px-4 p-py-2">
          <li>Minimum of 8 characters in length.</li>
          <li>At least 1 digit.</li>
          <li>At least 1 lowercase letter.</li>
          <li>At least 1 uppercase letter.</li>
          <li className="p-mb-2">
            At least 1 special character, such as # ? ! @ $ % ^ & * - . _ ( or )
          </li>
        </ul>
      </Trans>
    </>
  );
}

// Characters have to be properly escaped, ESLint is wrong here
/* eslint-disable no-useless-escape */
export const mediumStrenthRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-\._\(\)]).{8,}$/;

export const strongStrenthRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-\._\(\)]).{12,}$/;
/* eslint-enable */
