import './Menu.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import { escapeRegex } from '../../../../../../utils/helpers/escapeRegex';
import Tooltip from '../../../../../Misc/Tooltip/Tooltip';
import { MenuItem } from './MenuLinks';

export function renderMenuLink(
  link: MenuItem,
  location: ReturnType<typeof useHistory>['location'],
  isMenuExpanded: boolean
) {
  const linkClassName = classNames('menu-link', {
    active:
      location.pathname.match(escapeRegex(link.path)) &&
      !link.excludePaths?.includes(location.pathname),
  });

  return isMenuExpanded ? (
    <Link to={link.path}>
      <div className={linkClassName}>
        <FontAwesomeIcon icon={link.icon} className="link-icon" />
        <span className="link-label">{link.label}</span>
      </div>
    </Link>
  ) : (
    <Tooltip
      text={link.label}
      children={
        <Link to={link.path}>
          <div className={linkClassName}>
            <FontAwesomeIcon icon={link.icon} className="link-icon" />
          </div>
        </Link>
      }
    />
  );
}
