import classNames from 'classnames';
import { Location } from 'history';
import { Button } from 'primereact/button';
import {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useMemo,
} from 'react';
import {
  DeepPartial,
  FieldValues,
  FormProvider,
  SubmitHandler,
  UnpackNestedValue,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CollapsableFilterSection from './CollapsableFiltersSection';
import styles from './SidebarFormFilters.module.scss';

type Props<T extends FieldValues> = {
  setHttpFiltersObj: Dispatch<
    SetStateAction<T & { page: number; limit: number }>
  >;
  page: number;
  limit: number;
  getDefaultValues: (
    location: Location<unknown>
  ) => UnpackNestedValue<DeepPartial<T>> | undefined;
  getNullValues: () => UnpackNestedValue<DeepPartial<T>>;
  useSidebarFilters: ({ methods }: { methods: UseFormReturn<T> }) => {
    filtersForm: JSX.Element;
    filtersCount: number;
  };
  formName: string;
} & HTMLAttributes<HTMLFormElement>;

function SidebarFormFilters<T extends FieldValues>({
  setHttpFiltersObj,
  page,
  limit,
  getDefaultValues,
  getNullValues,
  useSidebarFilters,
  formName,
  ...other
}: Props<T>): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation();

  const defaultValues = useMemo(
    () => getDefaultValues(location),
    [getDefaultValues, location]
  );

  const methods = useForm<T>({
    defaultValues,
  });

  const { filtersForm, filtersCount } = useSidebarFilters({ methods });

  function onSubmit(data: T) {
    setHttpFiltersObj({ ...data, page, limit });
  }

  useEffect(() => {
    setHttpFiltersObj((prev) => ({ ...prev, page, limit }));
  }, [limit, page, setHttpFiltersObj]);

  return (
    <CollapsableFilterSection
      childrenWrapperClassName={classNames(styles.filtersWrapper)}
      activeFiltersCount={filtersCount}
    >
      <FormProvider {...methods}>
        <form
          className={styles.filterForm}
          id={formName}
          onSubmit={methods.handleSubmit(onSubmit as SubmitHandler<T>)}
          {...other}
        >
          {filtersForm}
        </form>
      </FormProvider>
      <div className={styles.filterButtons}>
        <Button
          className="p-button-outlined"
          label={t('Reset')}
          onClick={() => {
            methods.reset(getNullValues());
            onSubmit(getNullValues() as T);
          }}
        />
        <Button
          className={styles.applyButton}
          form={formName}
          label={t('Apply')}
          type="submit"
        />
      </div>
    </CollapsableFilterSection>
  );
}

export default SidebarFormFilters;
