import { TFunction } from 'react-i18next';

import { noColumnStyle } from '../../../configs/primereact';

export function getColumnHeadersMap(t: TFunction) {
  return {
    no: t('No.'),
    name: t('Name'),
  };
}

export function additionalColumnProperties(column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'name':
      return {
        style: {
          width: 'auto',
        },
        bodyStyle: {
          width: 'auto',
        },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t: TFunction,
  handleExportToExcel: () => void,
  handleExportToCsv: () => void
) {
  return [
    {
      label: t('Export to Excel'),
      icon: 'fas fa-file-excel',
      command: handleExportToExcel,
    },
    {
      label: t('Export to CSV'),
      icon: 'fas fa-file-csv',
      command: handleExportToCsv,
    },
  ];
}
