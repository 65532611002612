import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export function yupRequiredField(
  t: TFunction<'translation'>,
  fieldName: string
): string {
  return t('{{fieldName}} is a required field', {
    fieldName: fieldName.trim(),
  });
}

export function yupMinField(
  t: TFunction<'translation'>,
  fieldName: string,
  min: number
): string {
  return t('{{fieldName}} has to be at least {{length}} character(s) long', {
    fieldName: fieldName.trim(),
    length: min,
  });
}

export function yupMaxField(
  t: TFunction<'translation'>,
  fieldName: string,
  max: number
): string {
  return t('{{fieldName}} has to be at most {{length}} character(s) long', {
    fieldName: fieldName.trim(),
    length: max,
  });
}

export function conditionallyRequiredField(
  field: Yup.AnySchema,
  isRequired: boolean,
  requiredMessage: string
) {
  return isRequired ? field.required(requiredMessage) : field;
}

export function yupRequiredPasswordField(t: TFunction, fieldName: string) {
  return Yup.string()
    .min(8, t('components.password.validation.min8Chars'))
    .matches(/[0-9]/, t('components.password.validation.atLeastOneDigit'))
    .matches(
      // Characters have to be properly escaped, ESLint is wrong here
      // eslint-disable-next-line no-useless-escape
      /[#?!@$%^&*\-\._\(\)]/,
      t('components.password.validation.atLeastOneSpecialChar')
    )
    .matches(
      // Characters have to be properly escaped, ESLint is wrong here
      // eslint-disable-next-line no-useless-escape
      /^[a-zA-Z0-9#?!@$%^&*\-\._\(\)]*$/,
      t('components.password.validation.invalidChars')
    )
    .required(yupRequiredField(t, fieldName));
}
