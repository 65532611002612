import './WebImporter.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ToastContext from '../../../../../context/ToastContext';
import useAxios from '../../../../../hooks/useAxios';
import usePrevious from '../../../../../hooks/usePrevious';
import { ClientResource } from '../../../../../types/api/clients';
import { ListTypeEntityPropertyCollection } from '../../../../../types/api/entityproperties';
import { ImportListTypeResource } from '../../../../../types/api/importlisttypes';
import { PlaceResource } from '../../../../../types/api/places';
import { ReduxState } from '../../../../../types/redux';
import { errorToast } from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import { Step } from '../../../../Stepper/HeadlessStepper';
import Stepper from '../../../../Stepper/Inline/Stepper';
import ImportResult from '../ImportResult';
import Content from './Steps/Content';
import ListTypeStep from './Steps/ListType';
import Payments from './Steps/Payments';
import SenderAddress from './Steps/SenderAddress';
import {
  FormValues,
  generatePropertyName,
  getDefaultValues,
  getValidationSchema,
  toApiData,
} from './WebImporter.functions';

type StepWithValidation<T> = Step & {
  fieldNames: (keyof T)[];
};

function WebImporter(): JSX.Element {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const history = useHistory();

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const { data: clientData, isLoading: isClientLoading } =
    useAxios<ClientResource>(
      {
        url: `/clients/${clientLoggedIn}`,
      },
      { skipWhen: !clientLoggedIn }
    );

  const { data: placeData, isLoading: isPlaceLoading } =
    useAxios<PlaceResource>(`/places/${clientData?.mesto_id}`, {
      skipWhen: !clientData?.mesto_id,
    });

  const defaultValues = useMemo(
    () => getDefaultValues(clientData, placeData),
    [clientData, placeData]
  );

  const resolver = useMemo(() => yupResolver(getValidationSchema(t)), [t]);
  const methods = useForm<FormValues>({
    defaultValues,
    resolver,
  });
  const { handleSubmit, control, trigger, reset, setValue } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [hasSucceeded, setHasSucceeded] = useState<boolean>(false);
  const [hasFailed, setHasFailed] = useState<boolean>(false);

  const _clientListType = useWatch<FormValues, '_clientListType'>({
    name: '_clientListType',
    control,
  });

  const {
    data: listTypeData,
    error: listTypeError,
    isLoading: isListTypeLoading,
  } = useAxios<ImportListTypeResource>(
    `/importlisttypes/${_clientListType?.id}?lookup=true`,
    {
      skipWhen: !_clientListType?.id,
    }
  );

  const { data: entityProperties, isLoading: isEntityPropertiesLoading } =
    useAxios<ListTypeEntityPropertyCollection>('/entityproperties');

  const prevListTypeError = usePrevious(listTypeError);

  useEffect(() => {
    if (
      !listTypeError ||
      listTypeError === prevListTypeError ||
      !toastRef?.current
    ) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while reading list data.')
    );
  }, [prevListTypeError, listTypeError, t, toastRef]);

  useEffect(() => {
    setValue('_listType', listTypeData ?? null);
    setValue('osiguruvanje', listTypeData?.plaka_osiguruvanje_isprakac ?? '');
    setValue('otkup', listTypeData?.plaka_otkup_isprakac ?? '');
    setValue('postarina', listTypeData?.plaka_postarina_isprakac ?? '');
    setValue(
      'povraten_dokument',
      listTypeData?.plaka_povraten_dokument_isprakac ?? ''
    );
  }, [listTypeData, setValue]);

  const initializeBulkOrderContent = useCallback(() => {
    const fieldValues = listTypeData?.fields?.map((field) => {
      return {
        id: field.id,
        name: field.name,
        value: field.default_value ?? '',
        default_value: field.default_value ?? '',
        isRequired: field.isRequired === '1' ? true : false,
        property_id: field.property_id,
        property_name: generatePropertyName(
          entityProperties,
          field.property_id
        ),
      };
    });

    setValue('_fields', fieldValues ? [{ fields: fieldValues }] : []);
  }, [entityProperties, listTypeData?.fields, setValue]);

  useEffect(() => {
    initializeBulkOrderContent();
  }, [initializeBulkOrderContent]);

  const steps = useMemo<StepWithValidation<FormValues>[]>(() => {
    let returnArray: StepWithValidation<FormValues>[] = [
      {
        component: () => ListTypeStep({ isListTypeLoading }),
        fieldNames: ['_clientListType'],
      },
    ];

    if (listTypeData?.klient_isSender !== '0') {
      returnArray.push({
        component: SenderAddress,
        fieldNames: [
          '_municipality',
          'place',
          '_zip',
          'street',
          'broj',
          'vlez',
          'stan',
        ],
      });
    }

    if (listTypeData?.changeable_payment === '1') {
      returnArray.push({
        component: Payments,
        fieldNames: ['postarina', 'otkup', 'povraten_dokument', 'osiguruvanje'],
      });
    }

    returnArray.push({
      component: () => Content({ entityProperties }),
      fieldNames: ['_fields'],
    });

    return returnArray;
  }, [
    entityProperties,
    isListTypeLoading,
    listTypeData?.changeable_payment,
    listTypeData?.klient_isSender,
  ]);

  const {
    data: formSubmissionData,
    error: formSubmissionError,
    isLoading: isFormSubmissionLoading,
    reload: formSubmissionReload,
  } = useAxios<{ generalMsg?: string }, { generalMsg?: string }>();

  const bulkOrderDataPrevious = usePrevious(formSubmissionData);
  const bulkOrderErrorPrevious = usePrevious(formSubmissionError);

  useEffect(() => {
    if (!formSubmissionData || formSubmissionData === bulkOrderDataPrevious) {
      return;
    }

    setHasSucceeded(true);
  }, [formSubmissionData, bulkOrderDataPrevious, t, toastRef]);

  useEffect(() => {
    if (
      !formSubmissionError ||
      formSubmissionError === bulkOrderErrorPrevious
    ) {
      return;
    }

    setHasFailed(true);
  }, [bulkOrderErrorPrevious, t, toastRef, formSubmissionError]);

  function onFormSubmit(values: FormValues) {
    formSubmissionReload({
      method: 'POST',
      url: '/orders/import',
      data: toApiData(values),
    });
  }

  return isFormSubmissionLoading ? (
    <DialogSpinner />
  ) : hasSucceeded ? (
    <ImportResult
      subtitle={formSubmissionData?.generalMsg}
      onImportAgainBtnClick={() => {
        initializeBulkOrderContent();
        setHasSucceeded(false);
      }}
    />
  ) : hasFailed ? (
    <ImportResult
      hasFailed
      subtitle={formSubmissionError?.response?.data.generalMsg}
      onImportAgainBtnClick={() => {
        setHasFailed(false);
      }}
    />
  ) : (
    <FormProvider {...methods}>
      <form id="webImportForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stepper
          title={t('Web Import')}
          steps={steps}
          nextButtonProps={{ form: 'webImportForm', 'data-cy': 'submit-btn' }}
          onNextBtnClick={async ({ stepIndex }) => {
            return await trigger(steps[stepIndex].fieldNames);
          }}
          onCancelBtnClick={() => history.push('/orders/batch')}
          isStepIndicatorShown={!!listTypeData && !!_clientListType}
          isLoading={
            isClientLoading ||
            isPlaceLoading ||
            isListTypeLoading ||
            isEntityPropertiesLoading ||
            isFormSubmissionLoading
          }
          submitBtnLabel={t('Import')}
          className="bulk-orders-web-importer"
        />
      </form>
    </FormProvider>
  );
}

export default WebImporter;
