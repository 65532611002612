import { RefObject, useEffect, useState } from 'react';

export function useSidebarFiltersHeight(
  ref: RefObject<HTMLDivElement>,
  expanded: boolean
) {
  const [height, setHeight] = useState(500);

  useEffect(() => {
    function handleResize() {
      if (ref.current) {
        const rect = ref.current.offsetTop;
        setHeight(
          window.innerHeight >= 576 &&
            (window.innerHeight - rect > 450 || window.innerWidth >= 768)
            ? window.innerHeight - rect - 36
            : 900
        );
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, [ref, expanded]);

  return height;
}
