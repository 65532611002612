import dayjs from 'dayjs';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { dateToFilterText } from '../../../utils/helpers/dataTable';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import TableHeaderFilters from '../../DataTable/Table/HeaderFilters/TableHeaderFilters';
import { filterByValue } from '../../DataTable/Table/HeaderFilters/TableHeaderFilters.function';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>> | undefined,
  limit: number
) {
  const { t } = useTranslation();

  const location = useLocation();

  const [headerFiltersCount, setHeaderFiltersCount] = useState(0);

  const [dateFromFilter, setDateFromFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? dayjs().subtract(1, 'month').toDate()
  );

  const [dateToFilter, setDateToFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );

  const [serialNoFilter, setSerialNoFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'serialNo') ?? ''
  );

  const [parcelSerialNoFilter, setParcelSerialNoFilter] = useState<string>(
    () => getSearchQueryParam(location.search, 'parcelSerialNo') ?? ''
  );

  const [referenceNo1Filter, setReferenceNo1Filter] = useState('');
  const [referenceNo2Filter, setReferenceNo2Filter] = useState('');

  const debouncedSerialNoFilter = useDebounce(serialNoFilter, debounceTimeout);
  const debouncedParcelSerialNo = useDebounce(
    parcelSerialNoFilter,
    debounceTimeout
  );
  const debouncedReferenceNo1Filter = useDebounce(
    referenceNo1Filter,
    debounceTimeout
  );
  const debouncedReferenceNo2Filter = useDebounce(
    referenceNo2Filter,
    debounceTimeout
  );

  useEffect(() => {
    if (!setPage) {
      return;
    }

    setPage(1);
  }, [
    dateFromFilter,
    dateToFilter,
    debouncedSerialNoFilter,
    debouncedParcelSerialNo,
    setPage,
  ]);

  function resetAllFilters() {
    setSerialNoFilter('');
    setParcelSerialNoFilter('');
  }

  const filtersArr = useMemo(
    () => [
      debouncedSerialNoFilter,
      debouncedParcelSerialNo,
      dateFromFilter,
      dateToFilter,
    ],
    [
      dateFromFilter,
      dateToFilter,
      debouncedParcelSerialNo,
      debouncedSerialNoFilter,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  const httpFiltersObj = useMemo(
    () => ({
      date_from: dateFromFilter ? httpDateFormat(dateFromFilter) : undefined,
      date_to: dateToFilter ? httpDateFormat(dateToFilter) : undefined,
      serial: debouncedSerialNoFilter,
      parcel_serial: debouncedParcelSerialNo,
      reference1: debouncedReferenceNo1Filter,
      reference2: debouncedReferenceNo2Filter,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      dateFromFilter,
      dateToFilter,
      debouncedParcelSerialNo,
      debouncedReferenceNo1Filter,
      debouncedReferenceNo2Filter,
      debouncedSerialNoFilter,
      haveFiltersChanged,
      limit,
      page,
    ]
  );

  useSearchQueryParam('serialNo', debouncedSerialNoFilter);
  useSearchQueryParam('parcelSerialNo', debouncedParcelSerialNo);

  useSearchQueryDateParam('date_from', dateFromFilter);
  useSearchQueryDateParam('date_to', dateToFilter);

  const headerFiltersForm = useMemo<JSX.Element>(
    () => (
      <>
        <div className="filter">
          <label htmlFor="filter_date">{t('Date From')}</label>

          <Calendar
            value={dateFromFilter ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date"
            onChange={(e) => {
              setDateFromFilter(e.value as Date | null);
            }}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_date">{t('Date To')}</label>

          <Calendar
            value={dateToFilter ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date"
            onChange={(e) => {
              setDateToFilter(e.value as Date | null);
            }}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_serial_no">{t('Serial No.')}</label>
          <InputText
            name="filter_serial_no"
            value={serialNoFilter}
            onChange={(e) => setSerialNoFilter(e.target.value)}
            id="filter_serial_no"
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_parcel_serial_no">
            {t('Order Serial No.')}
          </label>

          <InputText
            name="filter_parcel_serial_no"
            id="filter_parcel_serial_no"
            value={parcelSerialNoFilter}
            onChange={(e) => setParcelSerialNoFilter(e.target.value)}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_reference1">{t('Reference No. 1')}</label>

          <InputText
            name="filter_reference1"
            id="filter_reference1"
            value={referenceNo1Filter}
            onChange={(e) => setReferenceNo1Filter(e.target.value)}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_reference2">{t('Reference No. 2')}</label>

          <InputText
            name="filter_reference2"
            id="filter_reference2"
            value={referenceNo2Filter}
            onChange={(e) => setReferenceNo2Filter(e.target.value)}
          />
        </div>
      </>
    ),
    [
      dateFromFilter,
      dateToFilter,
      parcelSerialNoFilter,
      referenceNo1Filter,
      referenceNo2Filter,
      serialNoFilter,
      t,
    ]
  );

  const headerFilters = useMemo(() => {
    const required = [
      {
        label: t('Date'),
        value: dateToFilterText([dateFromFilter, dateToFilter]),
      },
    ].filter(filterByValue);

    const ordinary = [
      {
        label: t('Serial No.'),
        value: serialNoFilter,
        onDelete: () => setSerialNoFilter(''),
      },
      {
        label: t('Order Serial No.'),
        value: parcelSerialNoFilter,
        onDelete: () => setParcelSerialNoFilter(''),
      },
      {
        label: t('Reference No. 1'),
        value: referenceNo1Filter,
        onDelete: () => setReferenceNo1Filter(''),
      },
      {
        label: t('Reference No. 2'),
        value: referenceNo2Filter,
        onDelete: () => setReferenceNo2Filter(''),
      },
    ].filter(filterByValue);

    setHeaderFiltersCount(required.length + ordinary.length);

    return <TableHeaderFilters required={required} ordinary={ordinary} />;
  }, [
    dateFromFilter,
    dateToFilter,
    parcelSerialNoFilter,
    referenceNo1Filter,
    referenceNo2Filter,
    serialNoFilter,
    t,
  ]);

  return {
    headerFiltersForm,
    headerFilters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
