import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import Step from '../../../../Stepper/Inline/Step';
import { CustomerRole } from '../CreateEditRecreate.functions';

function General(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Step title={t('General Info')}>
      <FieldWithErrors
        name="customer_role"
        label={t('My role')}
        labelClassName="p-mb-3"
      >
        <Controller
          name="customer_role"
          render={({ field }) => (
            <>
              <div className="p-field-radiobutton">
                <RadioButton
                  name="customer_role"
                  inputId="sender"
                  value="sender"
                  checked={field.value === CustomerRole.Sender}
                  onChange={(e) => field.onChange(e.value)}
                />
                <label htmlFor="sender" data-cy="sender">
                  {t('I am the sender')}
                </label>
              </div>

              <div className="p-field-radiobutton">
                <RadioButton
                  name="customer_role"
                  inputId="recipient"
                  value="recipient"
                  checked={field.value === CustomerRole.Recipient}
                  onChange={(e) => field.onChange(e.value)}
                />
                <label htmlFor="recipient">{t('I am the recipient')}</label>
              </div>

              <div className="p-field-radiobutton">
                <RadioButton
                  name="customer_role"
                  inputId="orderer"
                  value="orderer"
                  checked={field.value === CustomerRole.Orderer}
                  onChange={(e) => field.onChange(e.value)}
                />
                <label htmlFor="orderer" data-cy="orderer">
                  {t('I am the orderer')}
                </label>
              </div>
            </>
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="internal_shipment"
        label={false}
        className="p-mt-5"
      >
        <Controller
          name="internal_shipment"
          render={({ field }) => (
            <div className="p-field-checkbox">
              <Checkbox
                name="internal_shipment"
                inputId="internal_shipment"
                checked={field.value}
                onChange={(e) => field.onChange(e.checked)}
              />

              <label
                htmlFor="internal_shipment"
                className="p-checkbox-label"
                data-cy="internal_shipment"
              >
                {t('Internal order')}
              </label>
            </div>
          )}
        />
      </FieldWithErrors>
    </Step>
  );
}

export default General;
