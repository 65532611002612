import dayjs from 'dayjs';

import { StringBool } from '../../enums/boolean';

export function getSearchQueryParam(
  searchQuery: string,
  param: string
): string | undefined {
  const searchParams = new URLSearchParams(searchQuery);
  const value = searchParams.get(param);

  return value ? decodeURI(value) : undefined;
}

export function getBoolSearchQueryParam(
  searchQuery: string,
  param: string
): boolean | undefined {
  const queryParam = getSearchQueryParam(searchQuery, param);

  if (!queryParam) {
    return undefined;
  }

  return queryParam === StringBool.True;
}

export function getDateRangeSearchQueryParam(
  searchQuery: string,
  param: string,
  defaultValue: [Date, Date] = [new Date(), new Date()]
): [Date, Date] {
  const queryParam = getSearchQueryParam(searchQuery, param);

  return queryParam
    ? tryDateRangeSearchParam(queryParam) ?? defaultValue
    : defaultValue;
}

export function dateSearchParamToString(date: Date): string {
  const _date = dayjs(date);

  return _date.isValid() ? _date.format('DD.MM.YYYY') : '';
}

export function dateRangeSearchParamToString(date: [Date, Date]): string {
  const firstDate = dayjs(date[0]).format('DD.MM.YYYY');
  const secondDate = dayjs(date[1]).format('DD.MM.YYYY');

  return `${firstDate}-${secondDate}`;
}

export function tryDateSearchParam(param: string): Date | undefined {
  if (!param) {
    return undefined;
  }

  const parsed = dayjs(param, 'DD.MM.YYYY');

  return parsed.isValid() ? parsed.toDate() : undefined;
}

export function tryDateRangeSearchParam(
  param: string
): [Date, Date] | undefined {
  const split = param.split('-');

  if (split.length !== 2) {
    return undefined;
  }

  const firstDate = dayjs(split[0], 'DD.MM.YYYY');
  const secondDate = dayjs(split[1], 'DD.MM.YYYY');

  if (!firstDate.isValid() || !secondDate.isValid()) {
    return undefined;
  }

  return [firstDate.toDate(), secondDate.toDate()];
}

export function tryMultiSelectParam(param: string): string[] | undefined {
  return param.length > 0 ? decodeURIComponent(param).split(',') : undefined;
}
