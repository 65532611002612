import './Header.scss';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { MenuItem } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SplitButton } from 'primereact/splitbutton';
import { Dispatch, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { logos } from '../../configs/logos';
import { ClientPost } from '../../enums/clients';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ReduxState } from '../../types/redux';
import LanguageSelector from './LanguageSelector';

type Props = {
  setIsMenuExpanded: Dispatch<SetStateAction<boolean>>;
};

function AuthHeader({ setIsMenuExpanded }: Props): JSX.Element {
  const { t } = useTranslation();
  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const overlayOptionsPanelRef = useRef<OverlayPanel>(null);

  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user,
    shallowEqual
  );

  const history = useHistory();

  const splitBtnMenuModel: MenuItem[] = [
    {
      label: t('Profile'),
      icon: 'fas fa-address-card',
      command: () => history.push('/profile'),
    },
    {
      label: t('Email Configuration'),
      icon: 'fas fa-envelope-open-text',
      command: () => history.push('/email-configuration'),
    },
    {
      label: t('Notifications'),
      icon: 'fas fa-bell',
      command: () => history.push('/notifications'),
    },
    {
      label: t('Log out'),
      icon: 'fas fa-sign-out-alt',
      command: () => history.push('/logout'),
    },
  ];

  function toggleMenu() {
    setIsMenuExpanded(true);
  }

  const rigthMenu = (
    <>
      <LanguageSelector />

      <SplitButton
        icon="fas fa-user-alt"
        label={`${user.client_name}`}
        model={splitBtnMenuModel}
        onClick={() => {}}
      />
    </>
  );

  return (
    <>
      <div className="menu-btn" onClick={() => toggleMenu()}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      <Link to="/" className="logo">
        <img
          src={
            logos[
              (process.env.REACT_APP_CLIENT_NAME as keyof typeof logos) ??
                ClientPost.GrabIT
            ]
          }
          alt="Logo"
        />
      </Link>

      <div className="right-section">
        {isOnMobile && (
          <div>
            <Button
              type="button"
              icon="fas fa-ellipsis-h"
              className="p-button-text"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                overlayOptionsPanelRef.current?.toggle(e, null);
              }}
            />

            <OverlayPanel ref={overlayOptionsPanelRef} showCloseIcon>
              <div className="overlay-menu">{rigthMenu}</div>
            </OverlayPanel>
          </div>
        )}
        {!isOnMobile && rigthMenu}
      </div>
    </>
  );
}

export default AuthHeader;
