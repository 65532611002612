import './Menu.scss';

import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MenuContext } from '../../../../../../context/MenuContext';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { renderMenuLink } from './Menu.functions';
import { MenuItem, getLinks } from './MenuLinks';

function Menu(): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const { isMenuExpanded } = useContext(MenuContext);

  const menuClassName = classNames('menu', {
    'mobile-menu': isOnMobile,
    'desktop-menu-expanded': !isOnMobile && isMenuExpanded,
    'desktop-menu-collapsed': !isOnMobile && !isMenuExpanded,
  });

  const allLinks = useMemo(() => getLinks(t), [t]);

  const menuItems = useMemo(() => {
    return allLinks.map((link: MenuItem) => {
      return (
        <div key={link.label}>
          {renderMenuLink(link, location, isMenuExpanded)}
        </div>
      );
    });
  }, [allLinks, isMenuExpanded, location]);

  return (
    <div className={menuClassName}>
      <div className="menu-links">{menuItems}</div>
    </div>
  );
}

export default Menu;
