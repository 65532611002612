import { InputText } from 'primereact/inputtext';
import { useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import TableHeaderFilters from '../../DataTable/Table/HeaderFilters/TableHeaderFilters';
import {
  OrdinaryFilter,
  filterByValue,
} from '../../DataTable/Table/HeaderFilters/TableHeaderFilters.function';

function useTableFilters() {
  const { t } = useTranslation();

  const location = useLocation();

  const [headerFiltersCount, setHeadersFilterCount] = useState<number>(0);

  const [nameFilter, setNameFilter] = useState(
    getSearchQueryParam(location.search, 'name') ?? ''
  );

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);

  useSearchQueryParam('name', debouncedNameFilter);

  const httpFiltersObj = useMemo(
    () => ({
      name: debouncedNameFilter,
    }),
    [debouncedNameFilter]
  );

  const headerFiltersForm = useMemo<JSX.Element>(
    () => (
      <div className="filter">
        <label htmlFor="filter_name">{t('Name')}</label>
        <InputText
          id="filter_name"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
        />
      </div>
    ),
    [nameFilter, t]
  );

  const headerFilters = useMemo(() => {
    const ordinary: OrdinaryFilter[] = [
      {
        label: t('Name'),
        value: nameFilter,
        onDelete: () => setNameFilter(''),
      },
    ].filter(filterByValue);

    setHeadersFilterCount(ordinary.length);

    return <TableHeaderFilters ordinary={ordinary} />;
  }, [nameFilter, t]);

  function resetAllFilters() {
    setNameFilter('');
  }

  return {
    headerFiltersForm,
    headerFilters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
