import { TFunction } from 'react-i18next';

import { noColumnStyle } from '../../../configs/primereact';
import { OrderStatus } from '../../../enums/orders';
import { ImportListCollection } from '../../../types/api/importlists';
import { ContextMenuItems, Unpacked } from '../../../types/util';
import { emptyCell } from '../../../utils/constants/tables';
import { contextMenuModel } from '../../../utils/helpers/primereact';

export function getColumnHeadersMap(
  t: TFunction
): Record<
  | keyof Pick<
      Unpacked<ImportListCollection>,
      | 'import_list_type_name'
      | 'name'
      | 'created'
      | 'client_name'
      | 'barcode'
      | 'status'
      | 'parcels_count'
    >
  | 'no',
  string
> {
  return {
    no: t('No.'),
    import_list_type_name: t('List Type'),
    status: t('Status'),
    name: t('Name'),
    created: t('Created At'),
    client_name: t('Client'),
    barcode: t('Barcode'),
    parcels_count: t('Total'),
  };
}

export function additionalColumnProperties(column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'name':
      return {
        body: (row: Unpacked<ImportListCollection>) =>
          typeof row[column] === 'string' ? row.name.split(' ')[0] : emptyCell,
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'status':
      return {
        body: (row: Unpacked<ImportListCollection>) => (
          <span className={`status-badge ${row.status_tip}`}>{row.status}</span>
        ),
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'parcels_count':
      return {
        style: {
          width: 120,
        },
        bodyStyle: {
          width: 120,
        },
      };

    default:
      return {
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };
  }
}

export function generateContextMenu(
  t: TFunction,
  bulkOrder: Unpacked<ImportListCollection> | null,
  handleViewImportLogClick: () => void,
  handleViewShipmentsClick: () => void,
  handlePrintAddressBookClick: () => void,
  handlePrintStickersClick: () => void,
  handlePrintSpecificationClick: () => void,
  handleExportToCsv: () => void,
  handleDeleteListAndAddresses: () => void
): ContextMenuItems[] {
  const hasParcels =
    !!bulkOrder?.parcels_count && parseInt(bulkOrder.parcels_count) > 0;

  const canDelete =
    !!bulkOrder?.max_status_id &&
    parseInt(bulkOrder.max_status_id) < OrderStatus.Cancelled;

  const viewItem = {
    label: t('View'),
    icon: 'fa fa-search',
    items: [
      {
        label: t('View Import Log'),
        icon: 'fas fa-boxes',
        command: handleViewImportLogClick,
      },
      {
        label: t('View Orders'),
        icon: 'fas fa-edit',
        command: handleViewShipmentsClick,
      },
    ],
  };

  const printItem = {
    label: t('Print'),
    icon: 'fa fa-print',
    items: [
      {
        label: t('Print Address Book'),
        icon: 'fas fa-address-book',
        command: handlePrintAddressBookClick,
      },
      {
        label: t('Print Stickers'),
        icon: 'fas fa-ticket-alt',
        command: handlePrintStickersClick,
      },
      {
        label: t('Print Specification'),
        icon: 'fas fa-clipboard-list',
        command: handlePrintSpecificationClick,
      },
    ],
  };

  const exportItem = {
    label: t('Export to CSV'),
    icon: 'fas fa-file-csv',
    command: handleExportToCsv,
  };

  const deleteItem = {
    label: t('Delete list and addresses'),
    icon: 'fas fa-trash',
    command: handleDeleteListAndAddresses,
  };

  return contextMenuModel([
    [[true, viewItem]],
    [[hasParcels, printItem]],
    [[true, exportItem]],
    [[canDelete, deleteItem]],
  ]);
}
