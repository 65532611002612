import './Notifications.scss';

import { Form, Formik, FormikProps } from 'formik';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ToastContext from '../../../context/ToastContext';
import useAxios from '../../../hooks/useAxios';
import usePrevious from '../../../hooks/usePrevious';
import { ClientResource } from '../../../types/api/clients';
import { ReduxState } from '../../../types/redux';
import { errorToast, successToast } from '../../../utils/helpers/primereact';
import DialogSpinner from '../../Dialogs/DialogSpinner/DialogSpinner';
import { NotificationContent } from './NotificationContent';
import {
  FormValues,
  editDialogApiData,
  getInitialValues,
} from './Notifications.functions';

function Notifications() {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const { toastRef } = useContext(ToastContext);

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const { data: clientData, isLoading } = useAxios<ClientResource>(
    `/clients/${clientLoggedIn}`
  );

  const {
    reload: formSubmissionRequest,
    data: editData,
    error: editError,
  } = useAxios();

  function handleEdit() {
    formSubmissionRequest({
      url: `/clients/${clientLoggedIn}/self`,
      data: editDialogApiData(formRef.current?.values),
      method: 'PUT',
    });
  }

  const previousEditData = usePrevious(editData);

  useEffect(() => {
    if (!editData || editData === previousEditData) {
      return;
    }

    successToast(
      toastRef!,
      t('Successfuly updated'),
      t('Notification settings successfuly edited.')
    );
  }, [editData, previousEditData, t, toastRef]);

  const previousEditError = usePrevious(editError);

  useEffect(() => {
    if (!editError || editError === previousEditError) {
      return;
    }

    errorToast(
      toastRef!,
      t('Error'),
      t('An error occured while editing settings.')
    );
  }, [editError, previousEditError, t, toastRef]);

  const initialValues = useMemo(
    () => getInitialValues(clientData),
    [clientData]
  );

  return (
    <div className="notifications">
      {isLoading ? (
        <>
          <DialogSpinner />
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleEdit}
          innerRef={formRef}
        >
          <Form>
            <NotificationContent isLoading={isLoading} />
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default Notifications;
