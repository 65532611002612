import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MyRole } from '../../../../enums/orders';
import useAxios from '../../../../hooks/useAxios';
import useSearchQueryDateParam from '../../../../hooks/useSearchQueryDateParam';
import useSearchQueryParam from '../../../../hooks/useSearchQueryParam';
import { WithData } from '../../../../types/api';
import { ClientSubsidiaryCollection } from '../../../../types/api/clients';
import { ReduxState } from '../../../../types/redux';
import { queryString } from '../../../../utils/helpers/http';
import { renderIcon } from '../../../../utils/helpers/icon';
import { tryString } from '../../../../utils/helpers/parse';
import { Status } from '../Orders.functions';
import styles from './useSidebarFilters.module.scss';
import { FormValues } from './useSidebarFilters.types';

type Props = {
  methods: UseFormReturn<FormValues>;
};

function useSidebarFilters({ methods }: Props) {
  const { t } = useTranslation();

  const { control, getValues, setValue } = methods;

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const {
    serial,
    phone_from,
    phone_to,
    client_from_name,
    client_to_name,
    client_orderer_name,
    reference1,
    reference2,
    branch,
    roles,
    tracking_number,
    status,
    date_from,
    date_to,
    pickup_date_from,
    pickup_date_to,
    delivery_date_from,
    delivery_date_to,
  } = getValues();

  const {
    data: statusesData,
    isLoading: isLoadingStatuses,
    error: errorStatuses,
  } = useAxios<WithData<Status[]>>(
    {
      url:
        '/orders/statuses' +
        queryString(_.omit(getValues(), ['status', 'branch'])),
    },
    {
      skipWhen: !date_to || !dayjs(date_to).isValid(),
    }
  );

  const { data: subsidiaries } = useAxios<ClientSubsidiaryCollection>({
    url: `/clients/${clientLoggedIn}/subsidiaries`,
  });

  const statusesOptions = useMemo<{ label: string; value: string }[]>(
    () =>
      statusesData?.data?.map((status: Status) => {
        return {
          label: `${status.name} [${status.total}]`,
          value: String(status.id),
        };
      }) ?? [],
    [statusesData]
  );

  const rolesOptions = useMemo(() => {
    return [
      { label: t('Any'), value: MyRole.Any },
      { label: t('Sender'), value: MyRole.Sender },
      { label: t('Receiver'), value: MyRole.Receiver },
      { label: t('Orderer'), value: MyRole.Orderer },
    ];
  }, [t]);

  useSearchQueryParam('serial', serial);
  useSearchQueryParam('phone_from', phone_from);
  useSearchQueryParam('phone_to', phone_to);
  useSearchQueryParam('client_from_name', client_from_name);
  useSearchQueryParam('client_to_name', client_to_name);
  useSearchQueryParam('client_orderer_name', client_orderer_name);
  useSearchQueryParam('tracking_number', tracking_number);
  useSearchQueryParam('roles', roles);
  useSearchQueryParam('reference1', reference1);
  useSearchQueryParam('reference2', reference2);
  useSearchQueryParam('status', tryString(status) ?? null);

  useSearchQueryDateParam('date_from', date_from);
  useSearchQueryDateParam('date_to', date_to);
  useSearchQueryDateParam('pickup_date_from', pickup_date_from);
  useSearchQueryDateParam('pickup_date_to', pickup_date_to);
  useSearchQueryDateParam('delivery_date_from', delivery_date_from);
  useSearchQueryDateParam('delivery_date_to', delivery_date_to);

  useSearchQueryParam('subsidiaries', branch ? 'true' : 'false');

  const basicFiltersActiveFilterCount = Object.values({
    serial,
    date_from,
    date_to,
    pickup_date_from,
    pickup_date_to,
    delivery_date_from,
    delivery_date_to,
    status,
    phone_from,
    phone_to,
    client_from_name,
    client_to_name,
    client_orderer_name,
    branch,
    roles,
    tracking_number,
  }).filter(Boolean).length;

  const additionalFiltersActiveFilterCount = Object.values({
    reference1,
    reference2,
  }).filter(Boolean).length;

  const basicFiltersHeaderTemplate = (
    <div className={styles.accordionHeaderTemplate}>
      <span>{t('Basic filters')}</span>
      {basicFiltersActiveFilterCount > 0 && (
        <span className={styles.badge}>{basicFiltersActiveFilterCount}</span>
      )}
    </div>
  );

  const additionalFiltersHeaderTemplate = (
    <div className={styles.accordionHeaderTemplate}>
      <span>{t('Additional Filters')}</span>
      {additionalFiltersActiveFilterCount > 0 && (
        <span className={styles.badge}>
          {additionalFiltersActiveFilterCount}
        </span>
      )}
    </div>
  );

  const filtersForm = (
    <Accordion multiple activeIndex={[0]}>
      <AccordionTab disabled headerTemplate={basicFiltersHeaderTemplate}>
        <div className="sidebar_filter">
          <label htmlFor="serial">{t('Serial No')}</label>
          <Controller<FormValues, 'serial'>
            name="serial"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value ?? ''}
                id={field.name}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
              />
            )}
          />
        </div>

        {subsidiaries?.length && (
          <div className="sidebar_filter">
            <label htmlFor="branch">{t('Include Subsidiaries')}</label>
            <Controller<FormValues, 'branch'>
              name="branch"
              control={control}
              render={({ field }) => (
                <InputSwitch
                  {...field}
                  checked={field.value}
                  className={classNames({
                    sidebar_filter_active: field.value,
                  })}
                  inputId="branch"
                />
              )}
            />
          </div>
        )}

        <div className="sidebar_filter">
          <label htmlFor="tracking_number">{t('Tracking Number')}</label>
          <Controller<FormValues, 'tracking_number'>
            name="tracking_number"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value ?? ''}
                id={field.name}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
              />
            )}
          />
        </div>

        <div className="sidebar_filter">
          <label htmlFor="client_from_name">{t('Sender')}</label>
          <Controller<FormValues, 'client_from_name'>
            name="client_from_name"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value ?? ''}
                id={field.name}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
              />
            )}
          />
        </div>

        <div className="sidebar_filter">
          <label htmlFor="client_to_name">{t('Recipient')}</label>
          <Controller<FormValues, 'client_to_name'>
            name="client_to_name"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value ?? ''}
                id={field.name}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
              />
            )}
          />
        </div>

        <div className="sidebar_filter">
          <label htmlFor="client_orderer_name">{t('Orderer')}</label>
          <Controller<FormValues, 'client_orderer_name'>
            name="client_orderer_name"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value ?? ''}
                id={field.name}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
              />
            )}
          />
        </div>

        <div className="sidebar_filter">
          <label htmlFor="phone_from">{t('Sender phone number')}</label>
          <Controller<FormValues, 'phone_from'>
            name="phone_from"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value ?? ''}
                id={field.name}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
              />
            )}
          />
        </div>

        <div className="sidebar_filter">
          <label htmlFor="phone_to">{t('Recipient phone number')}</label>
          <Controller<FormValues, 'phone_to'>
            name="phone_to"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value ?? ''}
                id={field.name}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
              />
            )}
          />
        </div>

        <div className="sidebar_filter">
          <label htmlFor="status">{t('Status')}</label>
          <Controller<FormValues, 'status'>
            name="status"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                inputId="filter_roles"
                disabled={isLoadingStatuses ?? errorStatuses}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
                options={statusesOptions}
                showClear
                filter
              />
            )}
          />
        </div>

        <div className="sidebar_filter">
          <label htmlFor="roles">{t('My Role')}</label>

          <Controller<FormValues, 'roles'>
            name="roles"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                disabled={isLoadingStatuses ?? errorStatuses}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
                options={rolesOptions}
                filter
              />
            )}
          />
        </div>

        <div className="sidebar_filter">
          <label htmlFor="filter_date_from">{t('Created Date')}</label>

          <div className="sidebar_filter_row">
            <Controller<FormValues, 'date_from'>
              name="date_from"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  onChange={(e) => {
                    if (e.value) {
                      field.onChange(e.value);
                    }
                  }}
                  value={field.value as unknown as Date}
                  name={field.name}
                  dateFormat="dd/mm/yy"
                  maxDate={new Date()}
                  monthNavigator
                  yearNavigator
                  yearRange={`2009:${dayjs().format('YYYY')}`}
                  inputClassName={classNames({
                    sidebar_filter_active: field.value,
                  })}
                />
              )}
            />
            <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
            <Controller<FormValues, 'date_to'>
              name="date_to"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  value={field.value as unknown as Date}
                  onChange={(e) => {
                    if (e.value) {
                      field.onChange(e.value);
                    }
                  }}
                  name={field.name}
                  dateFormat="dd/mm/yy"
                  maxDate={new Date()}
                  monthNavigator
                  yearNavigator
                  yearRange={`2009:${dayjs().format('YYYY')}`}
                  inputClassName={classNames({
                    sidebar_filter_active: field.value,
                  })}
                />
              )}
            />
          </div>
        </div>
        <div className="sidebar_filter">
          <label htmlFor="filter_modified_date">{t('Received Date')}</label>

          <div className="sidebar_filter_row">
            <Controller<FormValues, 'pickup_date_from'>
              name="pickup_date_from"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  value={field.value as unknown as Date}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    if (!getValues().pickup_date_to) {
                      setValue('pickup_date_to', e.target.value as Date);
                    }
                  }}
                  name={field.name}
                  dateFormat="dd/mm/yy"
                  maxDate={new Date()}
                  monthNavigator
                  yearNavigator
                  yearRange={`2009:${dayjs().format('YYYY')}`}
                  inputClassName={classNames({
                    sidebar_filter_active: field.value,
                  })}
                />
              )}
            />
            <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
            <Controller<FormValues, 'pickup_date_to'>
              name="pickup_date_to"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  value={field.value as unknown as Date}
                  name={field.name}
                  dateFormat="dd/mm/yy"
                  maxDate={new Date()}
                  monthNavigator
                  yearNavigator
                  yearRange={`2009:${dayjs().format('YYYY')}`}
                  inputClassName={classNames({
                    sidebar_filter_active: field.value,
                  })}
                />
              )}
            />
          </div>
        </div>
        <div className="sidebar_filter">
          <label htmlFor="filter_modified_date">{t('Shipping Date')}</label>

          <div className="sidebar_filter_row">
            <Controller<FormValues, 'delivery_date_from'>
              name="delivery_date_from"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  value={field.value as unknown as Date}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    if (!getValues().delivery_date_to) {
                      setValue('delivery_date_to', e.target.value as Date);
                    }
                  }}
                  name={field.name}
                  dateFormat="dd/mm/yy"
                  maxDate={new Date()}
                  monthNavigator
                  yearNavigator
                  yearRange={`2009:${dayjs().format('YYYY')}`}
                  inputClassName={classNames({
                    sidebar_filter_active: field.value,
                  })}
                />
              )}
            />
            <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
            <Controller<FormValues, 'delivery_date_to'>
              name="delivery_date_to"
              control={control}
              render={({ field }) => (
                <Calendar
                  {...field}
                  value={field.value as unknown as Date}
                  name={field.name}
                  dateFormat="dd/mm/yy"
                  maxDate={new Date()}
                  monthNavigator
                  yearNavigator
                  yearRange={`2009:${dayjs().format('YYYY')}`}
                  inputClassName={classNames({
                    sidebar_filter_active: field.value,
                  })}
                />
              )}
            />
          </div>
        </div>
      </AccordionTab>
      <AccordionTab headerTemplate={additionalFiltersHeaderTemplate}>
        <div className="sidebar_filter">
          <label htmlFor="reference1">{t('Reference 1')}</label>
          <Controller<FormValues, 'reference1'>
            name="reference1"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value ?? ''}
                id={field.name}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
              />
            )}
          />
        </div>

        <div className="sidebar_filter">
          <label htmlFor="reference2">{t('Reference 2')}</label>
          <Controller<FormValues, 'reference2'>
            name="reference2"
            control={control}
            render={({ field }) => (
              <InputText
                {...field}
                value={field.value ?? ''}
                id={field.name}
                className={classNames({
                  sidebar_filter_active: field.value,
                })}
              />
            )}
          />
        </div>
      </AccordionTab>
    </Accordion>
  );

  return {
    filtersForm,
    filtersCount:
      basicFiltersActiveFilterCount + additionalFiltersActiveFilterCount,
  };
}

export default useSidebarFilters;
