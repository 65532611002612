import { DataTable, DataTableSortOrderType } from 'primereact/datatable';
import { Dispatch, RefObject, SetStateAction, useRef, useState } from 'react';

import { TableProps } from '../features/DataTable/Table/Table';

export type TableStateReturn<T> = {
  tableRef: RefObject<DataTable>;
  sortField: TableProps['sortField'];
  setSortField: TableProps['setSortField'];
  defaultSortField: string;
  sortOrder: TableProps['sortOrder'];
  setSortOrder: TableProps['setSortOrder'];
  selection: T | null;
  setSelection: Dispatch<SetStateAction<T | null>>;
  selectionMultiple: T[];
  setSelectionMultiple: Dispatch<SetStateAction<T[]>>;
  page: TableProps['rows'];
  setPage: TableProps['setPage'];
  limit: number;
  setLimit: TableProps['setLimit'];
};

function useTableState<T extends object>(
  defaultSortField: string = 'id'
): TableStateReturn<T> {
  const tableRef = useRef<DataTable>(null);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(30);
  const [sortOrder, setSortOrder] = useState<DataTableSortOrderType>(1);
  const [sortField, setSortField] = useState<string>(defaultSortField);
  const [selection, setSelection] = useState<T | null>(null);
  const [selectionMultiple, setSelectionMultiple] = useState<T[]>([]);

  return {
    tableRef,
    sortField,
    setSortField,
    defaultSortField,
    sortOrder: sortOrder ?? null,
    setSortOrder,
    selection,
    setSelection,
    selectionMultiple,
    setSelectionMultiple,
    page,
    setPage,
    limit,
    setLimit,
  };
}

export default useTableState;
