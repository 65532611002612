import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { UserActivateRequestPayload } from '../../../../types/api/users';
import { yupRequiredField } from '../../../../utils/helpers/yup';

export type FormFields = {
  code: string;
  password: string;
  repassword: string;
};

export type SuccessObj = {
  message?: string;
};

export function getInitialValues(code: string): FormFields {
  return {
    code: code,
    password: '',
    repassword: '',
  };
}

export function toApiData(formValues: FormFields): UserActivateRequestPayload {
  return {
    code: formValues.code,
    password: formValues.password,
    repassword: formValues.repassword,
  };
}

export function getValidationSchema(
  t: TFunction<'translation'>
): Yup.AnyObjectSchema {
  return Yup.object().shape({
    password: Yup.string()
      .min(8, t('Password is too short - should be 8 chars minimum.'))
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
        t(
          'Must Contain 8 to 16 Characters, One Uppercase, One Lowercase and One Number'
        )
      )
      .required(t('Password is required')),
    repassword: Yup.string()
      .required(yupRequiredField(t, 'Confirm Password'))
      .test(
        'passwords-match',
        t('Passwords must match'),
        (value, { parent }) => value === parent.new_password
      ),
  });
}

export function generateErrorMsg(t: TFunction, err: any) {
  const errorMsg = err.error_description.code;

  switch (errorMsg) {
    case 'invalid_parameter': {
      return t(
        'The code is invalid. Code does not exist or has expired or is associated with a non-existent user.'
      );
    }

    case 'empty': {
      return t('There is no code. Code is required for user activation.');
    }

    case 'invalid_user_status': {
      return t('Invalid status. Client is already registered..');
    }

    default: {
      return t('Unknown error');
    }
  }
}
