import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { ClientImportListTypeCollection } from '../../../types/api/clients';
import { ReduxState } from '../../../types/redux';
import { Unpacked } from '../../../types/util';
import { downloadFile, getFileName } from '../../../utils/helpers/files';
import Table from '../../DataTable/Table/Table';
import {
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
} from './BulkOrderTemplates.functions';
import useTableFilters from './useTableFilters';

function BulkOrderTemplates(): JSX.Element {
  const { t } = useTranslation();

  usePageTitle(t('Batch Order Templates'));

  const { bottomRightToastRef } = useContext(ToastContext);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const {
    tableRef,
    page,
    limit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Unpacked<ClientImportListTypeCollection>>();

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties
    );

  const {
    headerFilters,
    headerFiltersForm,
    headerFiltersCount,
    resetAllFilters,
    httpFiltersObj,
  } = useTableFilters();

  const clientID = useSelector<ReduxState, ReduxState['user']['client_id']>(
    (state) => state?.user?.client_id
  );

  const { data, error, isLoading, reload } =
    useAxios<ClientImportListTypeCollection>(
      `/clients/${clientID}/importlists/types`
    );

  function handleCmExportToCSVClick() {
    if (!selection) {
      return;
    }

    downloadFile(
      `/importlisttypes/${selection.id}/export/csv`,
      getFileName(t('BatchOrderTemplates'), selection.name),
      FileTypes.CSV,
      bottomRightToastRef
    );
  }

  function handleCmExportToExcelClick() {
    if (!selection) {
      return;
    }

    downloadFile(
      `/importlisttypes/${selection.id}/export/excel`,
      getFileName(t('BatchOrderTemplates'), selection.name),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  const dataToDisplay = useMemo(
    () =>
      data?.filter((item) =>
        item.name.toLowerCase().startsWith(httpFiltersObj.name.toLowerCase())
      ),
    [data, httpFiltersObj.name]
  );

  return (
    <div className="list-types">
      <h1 className="title">{t('Batch Order Templates')}</h1>
      <p className="subtitle">{t('View assigned batch order templates.')}</p>

      <Table
        columnOptions={columnOptions}
        columns={columns}
        data={dataToDisplay}
        hasError={!!error}
        isLoading={isLoading}
        rebuildTooltip
        ref={tableRef}
        reload={reload}
        rows={limit}
        selectedColumns={selectedColumns}
        headerFilters={headerFilters}
        headerFiltersForm={headerFiltersForm}
        headerFiltersCount={headerFiltersCount}
        onHeaderFiltersResetAllBtnClick={resetAllFilters}
        selection={selection}
        setSelectedColumns={setSelectedColumns}
        setSelection={setSelection}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        sortField={sortField}
        sortOrder={sortOrder}
        storageString="listTypes_dataTable"
        lazy={false}
        contextMenuModel={generateContextMenu(
          t,
          handleCmExportToExcelClick,
          handleCmExportToCSVClick
        )}
      />
    </div>
  );
}

export default BulkOrderTemplates;
