import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MenuContext } from '../../../../../../../context/MenuContext';
import useMediaQuery from '../../../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../../../hooks/usePrevious';
import { renderIcon } from '../../../../../../../utils/helpers/icon';
import Menu from '../Menu';
import styles from './SideBarWrapper.module.scss';

function SideBarWrapper(): JSX.Element {
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();

  const { isMenuExpanded, setIsMenuExpanded } = useContext(MenuContext);

  const pathname = useLocation().pathname;
  const prevPathname = usePrevious(pathname);

  const onHide = useCallback(
    () => setIsMenuExpanded(false),
    [setIsMenuExpanded]
  );

  useEffect(() => {
    // Prevents close upon rerender
    if (pathname === prevPathname || prevPathname === undefined) {
      return;
    }

    if (isOnMobile) onHide();
  }, [isOnMobile, onHide, pathname, prevPathname]);

  function toggleMenuCollapse() {
    setIsMenuExpanded(!isMenuExpanded);
  }

  return !isOnMobile ? (
    <div className={isMenuExpanded ? styles.sideMenuExpanded : styles.sideMenu}>
      <div className={styles.itemsWrapper}>
        <Menu />
      </div>

      <Button
        onClick={toggleMenuCollapse}
        icon={
          isMenuExpanded
            ? renderIcon(faChevronLeft)
            : renderIcon(faChevronRight)
        }
        iconPos="left"
        label={isMenuExpanded ? t('Collapse') : ''}
        className={classNames('p-button-secondary p-button-text', {
          [styles.menuButtonExpanded]: isMenuExpanded,
          [styles.menuButton]: !isMenuExpanded,
        })}
      />
    </div>
  ) : (
    <Sidebar
      showCloseIcon={false}
      baseZIndex={999999}
      blockScroll
      visible={isMenuExpanded}
      position="top"
      onHide={() => setIsMenuExpanded(false)}
      style={{
        height: isOnMobile ? 'auto' : undefined,
        width: isOnMobile ? '100%' : 'auto',
      }}
    >
      <Menu />
    </Sidebar>
  );
}

export default SideBarWrapper;
