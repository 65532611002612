import './ErrorPage.scss';

import { useTranslation } from 'react-i18next';

import illustration403 from '../../assets/svg/403-error-forbidden-amico.svg';

function Page403(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="page-403">
      <div className="error-container p-shadow-1">
        <h1>{t('Forbidden')}</h1>
        <p>{t("You don't have permissions to see this page")}.</p>

        <img src={illustration403} alt="" />

        <p>{t('Please contact the GrabIT team if this is an error')}.</p>
      </div>
    </div>
  );
}

export default Page403;
