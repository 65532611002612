import { DataTable } from 'primereact/datatable';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import ToastContext from '../../../../context/ToastContext';
import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import useTableColumns from '../../../../hooks/useTableColumns';
import useTableScrollHeight from '../../../../hooks/useTableScrollHeight';
import useTableState from '../../../../hooks/useTableState';
import { getDataTableProps } from '../../../../utils/globals';
import { currencyFormat } from '../../../../utils/helpers/formatting';
import { errorToast } from '../../../../utils/helpers/primereact';
import DialogSpinner from '../../../Dialogs/DialogSpinner/DialogSpinner';
import GoBackWithHistory from '../../../Misc/GoBack/GoBackWithHistory';
import {
  CODParcels,
  RouteParams,
  SingleWarrant,
  additionalColumnOptions,
  getColumnHeadersMap,
} from './ViewOrders.functions';

function ViewOrders() {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();

  const dataTableProps = useMemo(() => getDataTableProps(t), [t]);

  const parcelsColumnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { toastRef } = useContext(ToastContext);

  const { data, isLoading, error } = useAxios<CODParcels[]>(
    `/cashondeliveries/${id}/parcels`,
    {
      skipWhen: !id,
    }
  );

  const { data: warrantData } = useAxios<SingleWarrant>(
    `/cashondeliveries/${id}`,
    {
      skipWhen: !id,
    }
  );

  const prevError = usePrevious(error);

  const { tableRef, page, limit } = useTableState();

  const { columns } = useTableColumns(
    page,
    limit,
    parcelsColumnHeadersMap,
    parcelsColumnHeadersMap,
    (c) => additionalColumnOptions(c)
  );

  const scrollHeight = useTableScrollHeight(tableRef);

  const tableFooter = useMemo(() => {
    const length = data ? Object.keys(data).length : 0;

    return length > 0 ? (
      <div className="text-right">
        <span>
          {t('Total')}: {length} {length === 1 ? t('order') : t('orders')}
        </span>
      </div>
    ) : null;
  }, [t, data]);

  useEffect(() => {
    if (!error || error === prevError || !toastRef?.current) {
      return;
    }

    errorToast(
      toastRef,
      t('Error'),
      t('An error occured while trying to load order data.')
    );
  }, [t, error, prevError, toastRef]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  const vkupenOtkup = useMemo(() => {
    if (data) {
      const parcels = [...data];
      return parcels.reduce(
        (acc: any, item: any) => acc + parseInt(item.otkup),
        0
      );
    }
  }, [data]);

  return (
    <div>
      {isLoading || isLoading ? (
        <DialogSpinner />
      ) : (
        <>
          <GoBackWithHistory to="/orders" text={t('Back to Orders')} />

          <h1 className="title">{t('Details for COD warrant')}</h1>
          <p className="subtitle p-my-4">
            {t('COD warrant {{serialNumber}}', {
              serialNumber: warrantData?.seriski ?? '',
            })}
          </p>
          {vkupenOtkup && (
            <p className="p-mt-0 p-mb-4">
              {t('Total redemption')}:{' '}
              <b>
                {currencyFormat(vkupenOtkup, {
                  showCurrency: true,
                })}
              </b>
            </p>
          )}

          <DataTable
            ref={tableRef}
            footer={tableFooter}
            value={data ?? []}
            scrollable
            scrollHeight={`${scrollHeight}px`}
            loading={isLoading}
            emptyMessage={
              !!error
                ? dataTableProps.emptyMessageError
                : t('The selected list does not contain any orders')
            }
            className="p-datatable-sm p-datatable-striped"
          >
            {columns}
          </DataTable>
        </>
      )}
    </div>
  );
}
export default ViewOrders;
