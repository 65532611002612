import { InputTextarea } from 'primereact/inputtextarea';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';

function Note(): JSX.Element {
  const { t } = useTranslation();

  return (
    <FieldWithErrors
      label={t('Note')}
      name="komentar"
      className="p-mt-5 data-cy-note"
    >
      <Controller
        name="komentar"
        render={({ field }) => (
          <InputTextarea
            id="komentar"
            name="komentar"
            rows={3}
            autoResize
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </FieldWithErrors>
  );
}

export default Note;
