import './ShipmentsDialog.scss';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../context/ToastContext';
import { FileTypes } from '../../../../../../enums/files';
import useAxios from '../../../../../../hooks/useAxios';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import useRouteDialog from '../../../../../../hooks/useRouteDialog';
import useTableColumns from '../../../../../../hooks/useTableColumns';
import useTableState from '../../../../../../hooks/useTableState';
import {
  downloadAddressBook,
  downloadSticker,
  getFileName,
} from '../../../../../../utils/helpers/files';
import Table from '../../../../../DataTable/Table/Table';
import DialogSpinner from '../../../../../Dialogs/DialogSpinner/DialogSpinner';
import CreateEditRecreate from '../../../../Orders/CreateEditRecreate/CreateEditRecreate';
import { ViewOrderDialog } from '../../../../Orders/Dialogs/View/Order/ViewOrderDialog';
import {
  Parcel,
  additionalColumnProperties,
  generateContextMenu,
  getColumHeadersMap,
} from './ShipmentsDialog.functions';

type Props = {
  id: string | undefined;
  name?: string;
  visible: boolean;
  onHide: () => void;
  showImportLogDialog: () => void;
};

function ShipmentsDialog({
  id,
  name,
  visible,
  onHide,
  showImportLogDialog,
}: Props) {
  const { t } = useTranslation();

  const columnHeadersMap = useMemo(() => getColumHeadersMap(t), [t]);
  const { bottomRightToastRef } = useContext(ToastContext);

  const { data, error, isLoading, reload } = useAxios<Parcel[]>(
    `/importlists/${id}/parcels`,
    {
      skipWhen: !id,
    }
  );

  const {
    tableRef,
    page,
    limit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Parcel>();

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties
    );

  const dataTableFooter = (
    <div className="text-right">
      <span>
        Total: {data?.length} {data?.length === 1 ? 'order' : 'orders'}
      </span>
    </div>
  );

  const {
    show: showViewDialog,
    hide: hideViewDialog,
    isVisible: isViewDialogVisible,
  } = useRouteDialog(`/orders/batch/${id}/orders`, `${selection?.id}/view`);

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(`/orders/batch/${id}/orders`, `${selection?.id}/edit`);

  function handlePrintAddressBookClick() {
    if (!selection) {
      return;
    }

    const fileName = getFileName(t('Orders'), [
      selection?.klient_od_ime ?? '',
      selection?.klient_do_ime ?? '',
      selection?.seriski_broj ?? '',
      t('AddressDocument'),
    ]);

    downloadAddressBook(
      {
        shipment_ids: [selection.id],
      },
      fileName,
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  function handlePrintStickerClick() {
    if (!selection) {
      return;
    }

    const fileName = getFileName(t('Orders'), [
      selection?.klient_od_ime ?? '',
      selection?.klient_do_ime ?? '',
      selection?.seriski_broj ?? '',
      t('Sticker'),
    ]);

    downloadSticker(
      {
        id: selection.id,
      },
      fileName,
      FileTypes.PDF,
      bottomRightToastRef
    );
  }
  const dialogFooter = (
    <>
      <Button
        type="button"
        className="p-button-text"
        label={t('Show Import Log')}
        onClick={showImportLogDialog}
      />
      <Button type="button" label={t('Close')} onClick={onHide} />
    </>
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <ViewOrderDialog
        visible={isViewDialogVisible}
        onHide={hideViewDialog}
        onEdit={showEditDialog}
        id={selection?.id}
      />

      <CreateEditRecreate
        isDialog={true}
        onHide={hideEditDialog}
        onFormSubmission={reload}
        isDialogVisible={isEditDialogVisible}
        orderId={selection?.id}
      />

      <Dialog
        header={t('Orders for Batch Order {{orderID}}', { orderID: name })}
        footer={dialogFooter}
        visible={visible}
        onHide={onHide}
        maximizable
        maximized={isOnMobile}
        className="bulk-orders-shipments-dialog"
      >
        {isLoading ? (
          <DialogSpinner />
        ) : (
          <Table
            headerTitle="Shipments"
            footer={dataTableFooter}
            columnOptions={columnOptions}
            columns={columns}
            data={data}
            hasError={!!error}
            isLoading={isLoading}
            rebuildTooltip
            ref={tableRef}
            reload={reload}
            rows={limit}
            selectedColumns={selectedColumns}
            selection={selection}
            setSelectedColumns={setSelectedColumns}
            setSelection={setSelection}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            sortField={sortField}
            sortOrder={sortOrder}
            storageString="bulkOrders_shipments_dataTable"
            lazy={false}
            onRowDoubleClick={showViewDialog}
            contextMenuModel={generateContextMenu(
              t,
              showViewDialog,
              showEditDialog,
              handlePrintAddressBookClick,
              handlePrintStickerClick
            )}
          />
        )}
      </Dialog>
    </>
  );
}

export default ShipmentsDialog;
