import { SplitButton } from 'primereact/splitbutton';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeLanguage } from '../../redux/actions/languageActions';
import { ReduxState } from '../../types/redux';

function LanguageSelector(): JSX.Element {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const language = useSelector((state: ReduxState) => state.language);

  useLayoutEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const languageToDisplay = (() => {
    switch (language) {
      case 'sr-Cyrl':
        return 'СР';
      case 'sr-Latn':
        return 'SR';
      default:
        return language.toUpperCase();
    }
  })();

  const languages = [
    {
      label: 'English',
      icon: 'flag us-flag',
      command: () => {
        dispatch(changeLanguage('en'));
      },
    },
    {
      label: 'Македонски',
      icon: 'flag mk-flag',
      command: () => {
        dispatch(changeLanguage('mk'));
      },
    },
    {
      label: 'Српски (кирилица)',
      icon: 'flag rs-flag',
      command: () => {
        dispatch(changeLanguage('sr-Cyrl'));
      },
    },
    {
      label: 'Srpski (latinica)',
      icon: 'flag rs-flag',
      command: () => {
        dispatch(changeLanguage('sr-Latn'));
      },
    },
  ];

  return (
    <SplitButton
      icon={`flag ${
        language === 'sr-Cyrl' || language === 'sr-Latn'
          ? 'rs-flag'
          : language === 'en'
          ? 'us-flag'
          : `${language}-flag`
      }`}
      label={languageToDisplay}
      model={languages}
      style={{ marginRight: '4px' }}
    />
  );
}

export default LanguageSelector;
