import './Table.scss';

import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { ContextMenu, ContextMenuProps } from 'primereact/contextmenu';
import {
  DataTable,
  DataTableProps,
  DataTableRowEventParams,
  DataTableSelectionChangeParams,
  DataTableSortOrderType,
  DataTableSortParams,
} from 'primereact/datatable';
import { MultiSelect, MultiSelectChangeParams } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';
import { PaginatorPageState } from 'primereact/paginator';
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import XLSX from 'xlsx';

import useMediaQuery from '../../../hooks/useMediaQuery';
import useTableDataToDisplay from '../../../hooks/useTableDataToDisplay';
import useTableScrollHeight from '../../../hooks/useTableScrollHeight';
import { WithPagination } from '../../../types/api';
import { isDevEnv } from '../../../utils/constants/misc';
import { getDataTableProps } from '../../../utils/globals';
import { tryInt } from '../../../utils/helpers/parse';
import { paginatorTemplate } from './Table.functions';

type RequiredProps = 'sortField' | 'rows' | 'sortOrder' | 'selection';

export type TableProps = Required<Pick<DataTableProps, RequiredProps>> &
  Omit<DataTableProps, RequiredProps> & {
    data: WithPagination<any> | object | undefined;
    storageString: string;
    sortField: string;
    setSortField: Dispatch<SetStateAction<string>>;
    setSortOrder: Dispatch<SetStateAction<DataTableSortOrderType>>;
    setSelection: Dispatch<SetStateAction<any | any[]>>;
    setContextMenuSelection?: Dispatch<SetStateAction<any>>;
    columns: JSX.Element | JSX.Element[];
    columnOptions: { field: string; header: string; label: string }[];
    selectedColumns: object[];
    setSelectedColumns: Dispatch<SetStateAction<object[]>>;
    reload: () => void;
    isReloadDisabled?: boolean;
    setPage?: Dispatch<SetStateAction<number>>;
    setLimit?: Dispatch<SetStateAction<number>>;
    grid?: boolean;
    striped?: boolean;
    size?: 'small' | 'normal' | 'large';
    customDataModifier?: (data: any) => any[];
    contextMenuModel?: ContextMenuProps['model'];
    headerTitle?: string;
    headerFilters?: JSX.Element;
    headerFiltersForm?: JSX.Element;
    headerFiltersCount?: number;
    onHeaderFiltersResetAllBtnClick?: () => void;
    rebuildTooltip?: boolean;
    isLoading: boolean;
    hasError: boolean;
    exportToCSVButton?: boolean;
    onExportToCSVButtonClick?: () => void;
    exportToExcelButton?: boolean;
    onExportToExcelButtonClick?: () => void;
    selectionModifier?: (value: any) => any;
    displayActionColumn?: boolean;
    groupActionsModel?: ContextMenuProps['model'];
  };

const Table = forwardRef(
  (
    {
      data,
      rows,
      setPage,
      setLimit,
      sortField,
      setSortField,
      sortOrder,
      setSortOrder,
      selection,
      setSelection,
      storageString,
      contextMenuModel,
      customDataModifier,
      columns,
      columnOptions,
      selectedColumns,
      setSelectedColumns,
      headerFilters,
      headerFiltersForm,
      headerFiltersCount,
      onHeaderFiltersResetAllBtnClick,
      isLoading,
      hasError,
      reload,
      isReloadDisabled = false,
      exportToCSVButton,
      onExportToCSVButtonClick,
      exportToExcelButton,
      onExportToExcelButtonClick,
      header,
      headerTitle,
      selectionMode = 'single',
      onContextMenuSelectionChange,
      paginatorLeft,
      paginatorRight,
      selectionModifier,
      rebuildTooltip = false,
      lazy = true,
      grid = true,
      striped = true,
      size = 'small',
      className,
      displayActionColumn,
      contextMenuSelection,
      setContextMenuSelection,
      groupActionsModel,
      ...otherProps
    }: TableProps,
    ref: any
  ) => {
    const { t } = useTranslation();

    const dataTableProps = useMemo(() => getDataTableProps(t), [t]);

    const contextMenuRef = useRef<ContextMenu>(null);
    const groupActionsRef = useRef<ContextMenu>(null);

    const scrollHeight = useTableScrollHeight(ref);

    const isOnMobile = useMediaQuery('(max-width: 768px)');

    const [isSelectAllChecked, setIsSelectAllChecked] =
      useState<boolean>(false);

    const containerClassName = useMemo(() => {
      return isOnMobile
        ? 'cols-two'
        : headerFiltersCount && headerFiltersCount <= 9
        ? 'cols-three'
        : headerFiltersCount &&
          headerFiltersCount >= 10 &&
          headerFiltersCount <= 16
        ? 'cols-four'
        : 'cols-five';
    }, [headerFiltersCount, isOnMobile]);

    useEffect(() => {
      if (rebuildTooltip) {
        ReactTooltip.rebuild();
      }
    }, [data, rebuildTooltip]);

    // In case of passing paginated data in non-lazy mode
    useEffect(() => {
      if (isDevEnv && !lazy && (data as WithPagination<any>)?.pagination) {
        throw Error('Table cannot be passed paginated data in non-lazy mode.');
      }
    }, [data, lazy]);

    // In case of missing out required props in lazy mode
    useEffect(() => {
      if (
        isDevEnv &&
        lazy &&
        (setPage === undefined || setLimit === undefined)
      ) {
        throw Error(
          'setPage and setLimit are required props for Table in lazy mode.'
        );
      }
    }, [lazy, setLimit, setPage]);

    // In case of using an unsupported selection mode
    useEffect(() => {
      if (
        !isDevEnv ||
        !selectionMode ||
        ['single', 'multiple'].includes(selectionMode)
      ) {
        return;
      }

      throw Error(
        "Table currently supports 'single' and 'multiple' modes. Please extend it."
      );
    }, [selectionMode]);

    const dataToDisplay = useTableDataToDisplay(
      lazy ? (data as WithPagination<any>)?.data : data,
      sortField,
      sortOrder,
      customDataModifier
    );

    function handlePaginationChange(e: PaginatorPageState): void {
      if (setPage === undefined || setLimit === undefined) {
        return;
      }

      setPage(e.page ? e.page + 1 : 1);
      setLimit(e.rows);
    }

    function handleSortChange(e: DataTableSortParams): void {
      setSortOrder(e.sortOrder);
      setSortField(e.sortField);
    }

    function handleSelectionChange(e: DataTableSelectionChangeParams): void {
      setSelection(
        typeof selectionModifier === 'function'
          ? selectionModifier(e.value)
          : e.value
      );
    }

    function handleContextMenu(e: DataTableRowEventParams) {
      if (!contextMenuModel || !contextMenuRef.current) {
        return;
      }

      contextMenuRef.current.show(e.originalEvent);
    }

    const overlayPanelRef = useRef<OverlayPanel>(null);
    const overlayOptionsPanelRef = useRef<OverlayPanel>(null);

    function xlsx_getWorkbook(fields: any) {
      const worksheet = XLSX.utils.aoa_to_sheet(fields);
      const newWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(newWorkbook, worksheet);

      return newWorkbook;
    }

    const headerToDisplay = useMemo(() => {
      if (isDevEnv && headerFiltersForm && headerFiltersCount === undefined) {
        throw Error(
          'Prop headerFiltersCount must be present together with headerFiltersForm in Table.'
        );
      }

      const rightHeaderContent = (
        <>
          <Button
            type="button"
            icon="fas fa-sync-alt"
            tooltip={t('Reload')}
            tooltipOptions={{ position: 'top' }}
            disabled={isReloadDisabled}
            onClick={reload}
            className="p-mr-2 p-button-text p-button-plain"
          />

          {exportToExcelButton && (
            <Button
              type="button"
              icon="fas fa-file-excel"
              tooltip={t('Export Excel')}
              tooltipOptions={{ position: 'top' }}
              onClick={() => {
                if (onExportToExcelButtonClick) {
                  onExportToExcelButtonClick();
                } else {
                  let tableHeaders = ref.current?.props?.children?.map(
                    (child: any) => child?.props?.header
                  );

                  let tableFields = ref.current?.props?.children?.map(
                    (child: any) => child?.props?.field
                  );

                  let rows = ref.current?.props?.value?.map((row: any) => {
                    let rowsData = tableFields?.map((field: string) => {
                      return Object.keys(row).includes(field) && row[field];
                    });

                    return rowsData;
                  });

                  let tableData = _.concat([tableHeaders], rows);

                  XLSX.writeFile(xlsx_getWorkbook(tableData), `export.xlsx`);
                }
              }}
              className="p-mr-2 p-button-text p-button-plain"
              data-cy="export-xlsx-btn"
            />
          )}

          {exportToCSVButton && (
            <Button
              type="button"
              icon="fas fa-file-csv"
              tooltip={t('Export CSV')}
              tooltipOptions={{ position: 'top' }}
              onClick={() => {
                if (onExportToCSVButtonClick) {
                  onExportToCSVButtonClick();
                } else {
                  ref?.current?.exportCSV();
                }
              }}
              className="p-mr-2 p-button-text p-button-plain"
            />
          )}

          <MultiSelect
            inputId={`${storageString}-customize-columns`}
            value={selectedColumns}
            options={columnOptions}
            maxSelectedLabels={1}
            selectedItemsLabel={t('{0} columns')}
            onChange={handleColumnChange}
          />
        </>
      );

      const rightHeaderContentWrapper = (
        <>
          {Array.isArray(selection) && selection?.length > 1 && (
            <Button
              type="button"
              label={t('Selected ( {{selectionCount}} )', {
                selectionCount: Array.isArray(selection) ? selection.length : 1,
              })}
              className="p-button-outlined p-mr-2"
              onClick={(e) => groupActionsRef?.current?.show(e)}
            />
          )}

          {isOnMobile && (
            <>
              <Button
                type="button"
                icon="fas fa-ellipsis-h"
                className="p-button-text"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  overlayOptionsPanelRef.current?.toggle(e, null);
                }}
              />

              <OverlayPanel
                ref={overlayOptionsPanelRef}
                showCloseIcon
                className="custom-datatable-filters"
              >
                {selectionMode === 'multiple' && (
                  <div className="p-field-checkbox p-ml-2">
                    <Checkbox
                      onChange={(e) => {
                        if (e.checked) {
                          setSelection(dataToDisplay);
                        }
                        if (!e.checked) {
                          setSelection([]);
                        }
                        setIsSelectAllChecked(e.checked);
                      }}
                      checked={isSelectAllChecked}
                    />
                    <label htmlFor="binary">
                      {isSelectAllChecked ? t('Unselect All') : t('Select All')}
                    </label>
                  </div>
                )}
                {rightHeaderContent}
              </OverlayPanel>
            </>
          )}

          {!isOnMobile && rightHeaderContent}
        </>
      );

      // If no table filters were passed
      if (!headerFiltersForm || headerFiltersCount === undefined) {
        if (isDevEnv && headerTitle === undefined) {
          throw Error(
            'Prop headerTitle must be present when no headerFiltersForm is passed in Table.'
          );
        }

        return (
          <div className="p-d-flex p-jc-between">
            <div className="p-d-flex">
              <h3 className="p-my-auto">{headerTitle}</h3>
            </div>

            <div className="p-d-flex">{rightHeaderContent}</div>
          </div>
        );
      }

      function handleColumnChange(event: MultiSelectChangeParams) {
        const newSelectedColumns =
          event.value.length > 0 ? event.value : columnOptions;

        setSelectedColumns(newSelectedColumns);

        try {
          sessionStorage.setItem(
            `${storageString}SelectedColumns`,
            JSON.stringify(newSelectedColumns)
          );
        } catch (e) {
          Sentry.captureException(e, {
            extra: {
              data: newSelectedColumns,
            },
          });
        }
      }

      const buttonClassName = classNames('p-mr-2', {
        'p-button-secondary': headerFiltersCount === 0,
        'p-button-warning': headerFiltersCount > 0,
      });

      const badgeClassName = classNames({
        'p-badge-danger': headerFiltersCount > 0,
      });

      return (
        <div className="table-header-container">
          <div>
            <Button
              type="button"
              label={t('Filters')}
              badge={String(headerFiltersCount)}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                overlayPanelRef.current?.toggle(e, null);
              }}
              badgeClassName={badgeClassName}
              className={buttonClassName}
            />

            <OverlayPanel
              ref={overlayPanelRef}
              showCloseIcon
              className="custom-datatable-filters"
            >
              <div className={`filters-container ${containerClassName}`}>
                {headerFiltersForm}
              </div>

              <div className="p-d-flex p-jc-end p-mt-3">
                <Button
                  type="button"
                  label={t('Reset all')}
                  onClick={() => {
                    if (typeof onHeaderFiltersResetAllBtnClick === 'function') {
                      onHeaderFiltersResetAllBtnClick();
                    }
                  }}
                />
              </div>
            </OverlayPanel>
          </div>

          <div className="filter-chips-container">{headerFilters}</div>

          <div>{rightHeaderContentWrapper}</div>
        </div>
      );
    }, [
      columnOptions,
      containerClassName,
      dataToDisplay,
      exportToCSVButton,
      exportToExcelButton,
      headerFilters,
      headerFiltersCount,
      headerFiltersForm,
      headerTitle,
      isOnMobile,
      isReloadDisabled,
      isSelectAllChecked,
      onExportToCSVButtonClick,
      onExportToExcelButtonClick,
      onHeaderFiltersResetAllBtnClick,
      ref,
      reload,
      selectedColumns,
      selection,
      selectionMode,
      setSelectedColumns,
      setSelection,
      storageString,
      t,
    ]);

    const tableClassName = classNames(className ?? '', 'custom-datatable', {
      'p-datatable-sm': size === 'small',
      'p-datatable-lg': size === 'large',
      'p-datatable-striped': striped,
    });

    function handleOnContextMenuSelectionChange(
      e: DataTableSelectionChangeParams
    ) {
      if (onContextMenuSelectionChange) {
        return onContextMenuSelectionChange;
      }

      if (selectionMode === 'single') {
        handleSelectionChange(e);
      }

      if (setContextMenuSelection) {
        setContextMenuSelection(e.value);
      }
    }

    return (
      <>
        <DataTable
          ref={ref}
          header={header ?? headerToDisplay}
          value={dataToDisplay}
          resizableColumns
          reorderableColumns
          rowHover
          // Laziness
          loading={isLoading}
          emptyMessage={
            hasError
              ? dataTableProps.emptyMessageError
              : dataTableProps.emptyMessage
          }
          // Sorting
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={handleSortChange}
          // Scrolling
          scrollable
          scrollHeight={`${scrollHeight}px`}
          // Selection
          selection={selection}
          selectionMode={selectionMode}
          onSelectionChange={handleSelectionChange}
          onRowClick={(e: any) =>
            contextMenuRef?.current?.hide(e.originalEvent)
          }
          // Context Menu
          contextMenuSelection={
            contextMenuSelection
              ? contextMenuSelection
              : selectionMode === 'multiple'
              ? Array.isArray(selection)
                ? selection[0]
                : {}
              : selection
          }
          onContextMenuSelectionChange={(e: DataTableSelectionChangeParams) =>
            handleOnContextMenuSelectionChange(e)
          }
          onContextMenu={handleContextMenu}
          // Other
          className={tableClassName}
          // Laziness
          lazy={lazy}
          {...(lazy
            ? {
                first:
                  tryInt(
                    Number((data as WithPagination<any>)?.pagination.from) - 1
                  ) ?? 0,
                totalRecords:
                  tryInt((data as WithPagination<any>)?.pagination.total) ?? 0,
                onPage: handlePaginationChange,
                // Pagination
                paginator: true,
                paginatorLeft:
                  paginatorLeft ?? (paginatorRight ? <></> : undefined),
                paginatorRight:
                  paginatorRight ?? (paginatorLeft ? <></> : undefined),
                paginatorTemplate: paginatorTemplate,
                currentPageReportTemplate: (data as WithPagination<any>)
                  ?.pagination
                  ? t('Showing {first} to {last} of {totalRecords} entries')
                  : t('Showing 0 of 0 entries'),
                rows: rows,
                rowsPerPageOptions: [5, 15, 30, 50, 75, 100],
              }
            : {})}
          {...otherProps}
        >
          {columns}
          {isOnMobile && displayActionColumn && (
            <Column
              style={{ float: 'right', flexGrow: 'auto' }}
              header={t('Actions')}
              body={(rowData) => {
                return (
                  <div>
                    <Button
                      icon="fas fa-ellipsis-h"
                      onClick={(e) => {
                        if (selectionMode === 'single') {
                          setSelection(rowData);
                        } else if (
                          selectionMode === 'multiple' &&
                          !selection.includes(rowData)
                        ) {
                          setSelection([...selection, rowData]);
                        }
                        contextMenuRef?.current?.show(e);
                      }}
                    />
                  </div>
                );
              }}
            />
          )}
        </DataTable>

        <ContextMenu model={contextMenuModel} ref={contextMenuRef} />

        {groupActionsModel && (
          <ContextMenu
            className="group-actions-menu"
            model={groupActionsModel}
            ref={groupActionsRef}
          />
        )}
      </>
    );
  }
);

export default Table;
