import Quantity from '../Fields/Quantity';
import ReferenceNo1 from '../Fields/ReferenceNo1';
import ReferenceNo2 from '../Fields/ReferenceNo2';
import ReturnDocument from '../Fields/ReturnDocument';
import styles from './OrderTypes.module.scss';

function NotaryDocument(): JSX.Element {
  return (
    <div className={styles.fourColumnLayout}>
      <Quantity />
      <ReturnDocument />
      <ReferenceNo1 />
      <ReferenceNo2 />
    </div>
  );
}

export default NotaryDocument;
