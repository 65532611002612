import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

type Props = {
  to: string;
  text: string;
};

function GoBackWithHistory({ to, text }: Props): JSX.Element {
  const { t } = useTranslation();

  const history = useHistory();

  // History == 2 means the page was opened from a new tab,
  //   so if we go back when this is the case,
  //   the user will end up back to the new tab page
  return history.length > 2 ? (
    <div
      onClick={() => history.goBack()}
      className="title-go-back-link"
      data-cy="go-back-btn"
    >
      <FontAwesomeIcon icon={faChevronLeft} /> {t('Go back')}
    </div>
  ) : (
    <Link to={to} className="title-go-back-link" data-cy="go-back-btn">
      <FontAwesomeIcon icon={faChevronLeft} /> {text}
    </Link>
  );
}

export default GoBackWithHistory;
