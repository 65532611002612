import classNames from 'classnames';
import { Button } from 'primereact/button';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getEmailRecipientLabels } from '../../../configs/email';
import { EmailAction, EmailRecipient } from '../../../enums/email';
import styles from './EmailConfiguration.module.scss';
import Fields from './Fields';

type Props = {
  emailAction: EmailAction;
  emailRecipient: EmailRecipient;
  emailRecipientLabels: ReturnType<typeof getEmailRecipientLabels>;
};

function Recipient({
  emailAction,
  emailRecipient,
  emailRecipientLabels,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const { setValue, getValues } = useFormContext();

  function handleAddBtnClick() {
    setValue(`email_configuration.${emailAction}.${emailRecipient}`, [
      ...getValues()['email_configuration'][emailAction][emailRecipient],
      {
        email: '',
      },
    ]);
  }

  return (
    <section className={classNames(styles.recipient, 'p-pl-3')}>
      <h4 className="p-mb-2">{emailRecipientLabels[emailRecipient]}</h4>

      <Fields emailAction={emailAction} emailRecipient={emailRecipient} />

      <Button
        type="button"
        label={t('Add')}
        icon="fas fa-plus"
        onClick={handleAddBtnClick}
        className="p-button-sm p-button-outlined"
      />
    </section>
  );
}

export default Recipient;
