import { Location } from 'history';
import omit from 'lodash/omit';

import { MyRole, OrderStatus } from '../../../../enums/orders';
import { tryEnumValue, tryInt } from '../../../../utils/helpers/parse';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../../utils/helpers/searchQuery';
import { FormValues } from './useSidebarFilters.types';

export function getDefaultValues<T>(location: Location<T>): FormValues {
  return {
    branch:
      getSearchQueryParam(location.search, 'subsidiaries') === 'true' ?? false,
    phone_from: getSearchQueryParam(location.search, 'phone_from') ?? null,
    phone_to: getSearchQueryParam(location.search, 'phone_to') ?? null,
    client_from_name:
      getSearchQueryParam(location.search, 'client_from_name') ?? null,
    client_to_name:
      getSearchQueryParam(location.search, 'client_to_name') ?? null,
    client_orderer_name:
      getSearchQueryParam(location.search, 'client_orderer_name') ?? null,

    tracking_number:
      getSearchQueryParam(location.search, 'tracking_number') ?? null,
    serial: getSearchQueryParam(location.search, 'serial') ?? null,
    reference1: getSearchQueryParam(location.search, 'reference1') ?? null,
    reference2: getSearchQueryParam(location.search, 'reference2') ?? null,
    status:
      tryEnumValue(
        tryInt(getSearchQueryParam(location.search, 'status')),
        OrderStatus
      ) ?? null,
    roles:
      tryEnumValue(getSearchQueryParam(location.search, 'roles'), MyRole) ??
      MyRole.Any,
    date_from:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? new Date(),
    date_to:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date(),
    pickup_date_from:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'pickup_date_from') ?? ''
      ) ?? null,
    pickup_date_to:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'pickup_date_to') ?? ''
      ) ?? null,
    delivery_date_from:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'delivery_date_from') ?? ''
      ) ?? null,
    delivery_date_to:
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'delivery_date_to') ?? ''
      ) ?? null,
    summary: 1,
  };
}

export function getNullValues(): FormValues {
  return {
    client_from_name: null,
    client_to_name: null,
    client_orderer_name: null,
    phone_from: null,
    phone_to: null,
    serial: null,
    reference1: null,
    reference2: null,
    status: null,
    date_from: new Date(),
    date_to: new Date(),
    pickup_date_from: null,
    pickup_date_to: null,
    delivery_date_from: null,
    delivery_date_to: null,
    summary: null,
    roles: MyRole.Any,
    branch: false,
    tracking_number: null,
  };
}

export function toQueryString(
  values: FormValues,
  clientLoggedIn: number | undefined
) {
  return {
    ...omit(values, ['branch', 'roles']),
    branch: values.branch ? '1' : '',
    client_from_id: values.roles === MyRole.Sender ? clientLoggedIn : undefined,
    client_to_id: values.roles === MyRole.Receiver ? clientLoggedIn : undefined,
    client_orderer_id:
      values.roles === MyRole.Orderer ? clientLoggedIn : undefined,
    summary: 1,
  };
}
