import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import usePageTitle from '../../../hooks/usePageTitle';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { WithPagination } from '../../../types/api';
import { downloadFile, getFileName } from '../../../utils/helpers/files';
import { queryString } from '../../../utils/helpers/http';
import Table from '../../DataTable/Table/Table';
import useTableFilters from './useTableFilters';
import {
  COD,
  additionalColumnOptions,
  generateContextMenu,
  getColumnHeadersMap,
} from './Warrants.functions';

function Warrants(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  usePageTitle(t('Warrants'));

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { bottomRightToastRef } = useContext(ToastContext);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<COD>();

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnOptions
    );

  const {
    headerFilters,
    headerFiltersCount,
    headerFiltersForm,
    resetAllFilters,
    httpFiltersObj,
  } = useTableFilters(page, setPage, limit);

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, error, isLoading, reload } = useAxios<WithPagination<COD[]>>(
    '/cashondeliveries' + queryString(httpFiltersObj),
    {
      skipWhen: !canLoadData,
    }
  );

  function handleCMViewParcelsClick() {
    if (!selection) {
      return;
    }

    history.push(`/warrants/${selection?.id}/parcels`);
  }

  function handleCmExportToCSVClick() {
    if (!selection) {
      return;
    }

    downloadFile(
      `/cashondeliveries/${selection.id}/export/csv`,
      getFileName(t('Warrants'), [
        selection.seriski ?? '',
        selection.client_name,
      ]),
      FileTypes.CSV,
      bottomRightToastRef
    );
  }

  function handleCmExportToExcelClick() {
    if (!selection) {
      return;
    }

    downloadFile(
      `/cashondeliveries/${selection.id}/export/excel`,
      getFileName(t('Warrants'), [
        selection.seriski ?? '',
        selection.client_name,
      ]),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  function handleCmExportToPdfClick() {
    if (!selection) {
      return;
    }

    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/cod?cod_id=${selection.id}`,
      getFileName(t('Warrants'), [
        selection.seriski ?? '',
        selection.client_name,
      ]),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  return (
    <div className="warrants">
      <h1 className="title">{t('Warrants')}</h1>
      <p className="subtitle">{t('View and manage COD warrants')}</p>

      <Table
        columnOptions={columnOptions}
        columns={columns}
        data={data}
        hasError={!!error}
        isLoading={isLoading}
        rebuildTooltip
        ref={tableRef}
        reload={reload}
        isReloadDisabled={!canLoadData}
        rows={limit}
        selectedColumns={selectedColumns}
        selection={selection}
        setLimit={setLimit}
        setPage={setPage}
        setSelectedColumns={setSelectedColumns}
        setSelection={setSelection}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        sortField={sortField}
        sortOrder={sortOrder}
        storageString="warrants_dataTable"
        headerFilters={headerFilters}
        headerFiltersCount={headerFiltersCount}
        headerFiltersForm={headerFiltersForm}
        onHeaderFiltersResetAllBtnClick={resetAllFilters}
        contextMenuModel={generateContextMenu(
          t,
          handleCMViewParcelsClick,
          handleCmExportToCSVClick,
          handleCmExportToExcelClick,
          handleCmExportToPdfClick
        )}
        onRowDoubleClick={handleCMViewParcelsClick}
        displayActionColumn
      />
    </div>
  );
}

export default Warrants;
