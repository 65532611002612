// DBTable "email_action_type"
export enum EmailAction {
  COD = 1,
  Invoice,
}

// DBTable "email_recipient_type"
export enum EmailRecipient {
  To = 1,
  Cc,
  Bcc,
}
