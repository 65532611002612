import './FileImport.scss';

import { Field, useFormikContext } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload, FileUploadSelectParams } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';

import { FileTypes } from '../../../../../../../enums/files';
import {
  cleanUpCsv,
  detectCsvDelimiter,
} from '../../../../../../../utils/helpers/files';
import FieldWithErrors from '../../../../../../Forms/FieldWithErrors/FieldWithErrors';

function FileImport(): JSX.Element {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<any>();

  async function uploadHandler(evt: FileUploadSelectParams) {
    const file = evt.files[0];

    if (file.size > 1000000) {
      return;
    }

    const fileReader = new FileReader();

    if (file.type === FileTypes.CSV) {
      fileReader.onload = (e) => {
        const delimiter = detectCsvDelimiter(
          (e.target?.result as string | null) ?? ''
        );

        const csvData = cleanUpCsv(
          (e.target?.result as string | null) ?? '',
          delimiter
        );

        setFieldValue('csv', csvData);
        setFieldValue('delimiter', delimiter);
      };

      fileReader.readAsText(file);
    } else {
      fileReader.onload = (e) => {
        if (!e.target?.result || typeof e.target.result === 'string') {
          return;
        }

        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];

        const csvData = cleanUpCsv(
          XLSX.utils.sheet_to_csv(firstSheet, {
            FS: '\t',
            forceQuotes: true,
          }),
          '\t'
        );

        setFieldValue('csv', csvData);
        setFieldValue('delimiter', '\t');
      };

      fileReader.readAsArrayBuffer(file);
    }
  }

  return (
    <div className="upload-file">
      <FieldWithErrors name="csv" label={false} className="upload-file">
        <Field
          name="csv"
          as={FileUpload}
          id="csv-file-upload"
          customUpload
          mode="advanced"
          accept=".xlsx, .xls, .csv, .tsv, .ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          maxFileSize={1000000}
          onSelect={uploadHandler}
          chooseLabel={t('Upload')}
          className="p-button-outlined"
          onRemove={() => setFieldValue('csv', '')}
          icon="fas fa-upload"
          disabled={!!values?.csv}
        />
      </FieldWithErrors>

      <Tooltip target=".import-all-or-nothing" />

      <div
        className="p-d-inline-block p-pr-3 import-all-or-nothing"
        data-pr-tooltip={t(
          'If an error occurs in a single order, all orders will be rolled back.'
        )}
      >
        <Field
          inputId="import_all_or_none"
          name="import_all_or_none"
          as={Checkbox}
          checked={values.import_all_or_none}
        />

        <label htmlFor="import_all_or_none">{t('Import all or nothing')}</label>
      </div>
    </div>
  );
}

export default FileImport;
