import { WithPagination } from '../api';
import { ReduxState } from '../redux';

// GET /orders
export type OrderCollection = WithPagination<
  {
    id: string;
    seriski_broj: string;
    klient_od_ime: string;
    klient_do_ime: string;
    status_id: string;
  }[]
>;

// GET /orders/:id
export type OrderResource = {
  id: number;
  klient_naracatel_id: string;
  klient_od_ime: string;
  klient_od_id: number;
  klient_do_id: number;
  klient_do_ime: string;
  adresa_od: string | null;
  adresa_do: string | null;
  mobilen_do: string;
  telefon_do: string;
  mesto_do_ime: string;
  mesto_do_id: string;
  street_to_name: string;
  ulica_do_id: string;
  broj_do: string;
  vlez_do: string;
  stan_do: string;
  mobilen_od: string;
  telefon_od: string;
  mesto_od_id: string;
  mesto_od_ime: string;
  ulica_od_id: string;
  street_from_name: string;
  broj_od: string;
  vlez_od: string;
  stan_od: string;
  otkup: string;
  volumen: string;
  kolicina: string;
  sirina: string;
  visina: string;
  dolzina: string;
  cena: string | null;
  datum_kreiranje: string | null;
  datum_isporaka: string | null;
  datum_priem: string | null;
  dokument_broj: string | null;
  komentar: string | null;
  seriski_broj: string;
  barkod: string;
  status_id: string;
  status_name: string;
  tezina: string;
  payments: Payment[];
  proizvod_id: string;
  proizvod_ime: string;
  order_id: string;
  vrednost: string;
  povraten_dokument: string;
  hub_od_id: string;
  hub_do_id: string;
  hub_od_ime: string;
  hub_do_ime: string;
  return_shipment_id: string | null;
  origin_return_shipment_id: string | null;
  return_document_id: string | null;
  origin_return_document_id: string | null;
  delivery_location_type_id: ShippingLocation;
  pickup_location_type_id: ShippingLocation;
  reference1: string | null;
  reference2: string | null;
  reference_id: string | null;
  klient_otkup_id: number | null;
};

// RequestPayload<
//  | POST /orders/add
//  | PUT /orders/:id
//  >
export type AddEditOrderRequestPayload = {
  // Sender
  klient_od_id: number;
  klient_od_ime: string;
  mobilen_od: string;
  telefon_od: string;
  mesto_od_id: string;
  mesto_od_ime: string;
  ulica_od_id: string;
  adresa_od: string;
  broj_od: string;
  vlez_od: string;
  stan_od: string;
  hub_od_id?: string | null;
  pickup_location_type_id: ShippingLocation;

  // Recipient
  klient_do_id: number;
  klient_do_ime: string;
  mobilen_do: string;
  telefon_do: string;
  mesto_do_id: string;
  mesto_do_ime: string;
  ulica_do_id: string;
  adresa_do: string;
  broj_do: string;
  vlez_do: string;
  stan_do: string;
  hub_do_id?: string | null;
  delivery_location_type_id: ShippingLocation;

  // Order settings
  proizvod_id: OrderType;
  kolicina: string;
  tezina: string;
  volumen: string;
  otkup?: string;
  vrednost?: string;
  reference1: string;
  reference2: string;
  reference_id: string;
  povraten_dokument: ReturnDocument;
  komentar: string;
  klient_otkup_id: string | null;

  // Payments
  payments?: Partial<Payment>[];

  // Misc
  klient_naracatel_id: ReduxState['user']['client_id'];
  calc_price: '0';
};

// RequestPayload<POST /orders/calculator>
export type CalculatorRequestPayload = {
  // Required
  product_id: string;
  pickup_city: string;
  delivery_city: string;

  // Optional
  sender_id?: string;
  recipient_id?: string;
  orderer_id?: string;
  pickup_address?: string;
  pickup_zip?: string;
  delivery_address?: string;
  delivery_zip?: string;
  pickup_hub_id?: string;
  delivery_hub_id?: string;
  secured_shipment?: string;
  return_document?: string;
  weight?: string;
  cod_amount?: string;
  value_amount?: string;
  shipping_payer?: Payer;
  redemption_payer?: Payer;
  return_document_payer?: Payer;
  insurance_payer?: Payer;
  shipping_pay_cash?: PaymentMethod;
  redemption_pay_cash?: PaymentMethod;
  return_document_pay_cash?: PaymentMethod;
  insurance_pay_cash?: PaymentMethod;
  volume?: string;
  quantity?: string;
};

// POST /orders/calculator
export type CalculatorResponse = {
  customer_id: number;
  description: string;
  cash: PaymentMethod;
  quantity: number;
  unit_price: number;
  price: number;
  tariff_id: number;
  pricelist_id: number;
  service_id: number;
  service_name: string;
  vat_percent: number;
  created: string;
}[];

// ------------------------------------- //
// --------------- Other --------------- //
// ------------------------------------- //
export enum UnregisteredClientId {
  Sender = 1,
  Recipient = 2,
}

export enum PaymentMethod {
  Cash = 1,
  Invoice = 0,
}

export enum ServiceType {
  Postage = 1,
  Redemption = 2,
  ReturnDocument = 3,
  Insurance = 4,
}

export type Payment = {
  klient_id: number;
  usluga_id: ServiceType;
  opis: string;
  gotovina: PaymentMethod;
  iznos: number;
  usluga_ime: string;
  ddv: number;
  created: string;
  tarifa_id: number;
  cenovnik_id: number | null;
  kolicina: number;
  ediznos: number;
};

export enum OrderType {
  Package = '1',
  Letter = '2',
  NotaryDocument = '3',
}

export enum ReturnDocument {
  None = '0',
  Include = '1',
  ThisShipmentIsAReturnDoc = '2',
}

export enum ShippingLocation {
  Hub = 1,
  HomeAddress = 2,
}

export enum Payer {
  Orderer = '0',
  Sender = '1',
  Recipient = '2',
}
