import { TFunction } from 'react-i18next';

import {
  contextMenuSeparator,
  noColumnStyle,
} from '../../../../../../configs/primereact';
import { emptyCell } from '../../../../../../utils/constants/tables';
import { currencyFormat } from '../../../../../../utils/helpers/formatting';
import { formatPhoneOrMobileNumber } from '../../../../../../utils/helpers/phoneNumbers';

export type Parcel = {
  id: string;
  seriski_broj: string;
  datum_kreiranje: string;
  datum_priem: string;
  datum_isporaka: string;
  klient_od_ime: string;
  adresa_od: string;
  mesto_od_id: string;
  telefon_od: string;
  mobilen_od: string;
  klient_do_ime: string;
  adresa_do: string;
  mesto_do_id: string;
  telefon_do: string;
  mobilen_do: string;
  otkup: string;
  tezina: string;
  proizvod_id: string;
  komentar: string;
  vrednost: string;
  reference1: string;
  postar_od_id: string;
  postar_do_id: string;
  klient_od_id: string;
  mesto_od_ime: string;
  opstina_od_ime: string;
  mesto_do_ime: string;
  opstina_do_ime: string;
  postar_od_ime: string;
  postar_do_ime: string;
  kolicina: string;
  prices: {
    '1': {
      price: string;
      cash: string;
      pays: string;
    };
    '2': {
      price: string;
      cash: string;
      pays: string;
    };
  };
};

export function getColumHeadersMap(
  t: TFunction
): Record<
  | keyof Pick<
      Parcel,
      | 'seriski_broj'
      | 'klient_do_ime'
      | 'adresa_do'
      | 'telefon_do'
      | 'mobilen_do'
      | 'otkup'
      | 'kolicina'
    >
  | 'no',
  string
> {
  return {
    no: t('No.'),
    seriski_broj: t('Serial No'),
    klient_do_ime: t('Recipient Name'),
    adresa_do: t('Address To'),
    telefon_do: t('Recipient Phone'),
    mobilen_do: t('Recipient Mobile Phone'),
    otkup: t('Redemption'),
    kolicina: t('Quantity'),
  };
}

export function additionalColumnProperties(column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'seriski_broj':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'klient_do_ime':
      return {
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'adresa_do':
      return {
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'mobilen_do':
    case 'telefon_do':
      return {
        body: (row: Parcel) => {
          return row[column]
            ? formatPhoneOrMobileNumber(row[column])
            : emptyCell;
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'otkup':
      return {
        body: (row: Parcel) =>
          row[column]
            ? currencyFormat(row[column], { showCurrency: true })
            : emptyCell,
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    default:
      return {};
  }
}

export function generateContextMenu(
  t: TFunction,
  handleView: () => void,
  handleEdit: () => void,
  handlePrintAddressBookClick: () => void,
  handlePrintStickersClick: () => void
) {
  return [
    {
      label: t('View shipment'),
      icon: 'fas fa-search',
      command: handleView,
    },
    {
      label: t('Edit shipment'),
      icon: 'fas fa-edit',
      command: handleEdit,
    },
    contextMenuSeparator,
    {
      label: t('Print Address Book'),
      icon: 'fas fa-address-book',
      command: handlePrintAddressBookClick,
    },
    {
      label: t('Print Stickers'),
      icon: 'fas fa-ticket-alt',
      command: handlePrintStickersClick,
    },
  ];
}
