import { TFunction } from 'react-i18next';

import {
  contextMenuSeparator,
  noColumnStyle,
} from '../../../configs/primereact';
import { currencyFormat } from '../../../utils/helpers/formatting';

export type COD = {
  id: number;
  klient_id: number;
  adresnica_id: number;
  datum: string;
  opis: string;
  seriski: string;
  adresnica_seriski: string;
  rabotnik_id: number;
  status: number;
  email_status_id: number;
  email_sent_date: string | null;
  hub_id: number;
  client_name: string;
  employee_name: string;
  hub_name: string;
  vkupen_otkup: number;
  adresnica_status: string;
};

export function getColumnHeadersMap(
  t: TFunction
): Record<
  | keyof Pick<
      COD,
      | 'datum'
      | 'adresnica_seriski'
      | 'seriski'
      | 'client_name'
      | 'adresnica_status'
      | 'vkupen_otkup'
    >
  | 'no',
  string
> {
  return {
    no: t('No.'),
    datum: t('Date'),
    adresnica_seriski: t('Order Serial No.'),
    seriski: t('Serial No.'),
    client_name: t('Client'),
    adresnica_status: t('Status'),
    vkupen_otkup: t('Redemption'),
  };
}

export function additionalColumnOptions(column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'vkupen_otkup':
      return {
        body: (row: COD) =>
          currencyFormat(row.vkupen_otkup, { showCurrency: true }),
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };

    default:
      return {
        style: { width: 200 },
        bodyStyle: { width: 200 },
      };
  }
}

export function generateContextMenu(
  t: TFunction,
  handleCMViewClick: () => void,
  handleExportToCSV: () => void,
  handleExportToExcel: () => void,
  handleExportToPdf: () => void
) {
  return [
    {
      label: t('View Orders'),
      icon: 'fas fa-search',
      command: handleCMViewClick,
    },
    contextMenuSeparator,
    {
      label: t('Export to CSV'),
      icon: 'fas fa-file-csv',
      command: handleExportToCSV,
    },
    {
      label: t('Export to Excel'),
      icon: 'fas fa-file-excel',
      command: handleExportToExcel,
    },
    {
      label: t('Export to PDF'),
      icon: 'fas fa-file-pdf',
      command: handleExportToPdf,
    },
  ];
}
