import { Dropdown } from 'primereact/dropdown';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import { getReturnDocumentOptions } from '../../../CreateEditRecreate.functions';

function ReturnDocument(): JSX.Element {
  const { t } = useTranslation();

  const returnDocumentOptions = useMemo(() => getReturnDocumentOptions(t), [t]);

  return (
    <FieldWithErrors
      name="povraten_dokument"
      label={t('Return document')}
      className="data-cy-return_document"
    >
      <Controller
        name="povraten_dokument"
        render={({ field }) => (
          <Dropdown
            name="povraten_dokument"
            inputId="povraten_dokument"
            options={returnDocumentOptions}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </FieldWithErrors>
  );
}

export default ReturnDocument;
