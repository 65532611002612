import { Field, useFormikContext } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeParams } from 'primereact/checkbox';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  getNotificationChannel,
  getNotificationRecipient,
  getNotificationType,
} from '../../../utils/constants/notifications';
import { FormValues } from './Notifications.functions';

type Props = {
  isLoading: boolean;
};

export function NotificationContent({ isLoading }: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const notificationTypes = useMemo(() => getNotificationType(t), [t]);
  const notificationChannels = useMemo(() => getNotificationChannel(t), [t]);
  const notificationRecipients = useMemo(
    () => getNotificationRecipient(t),
    [t]
  );

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const notificationsTable = useMemo(() => {
    return (
      <>
        {notificationTypes.map((notificationType) => (
          <tr key={notificationType.id}>
            <td title={notificationType.description}>
              {notificationType.title}
            </td>

            {notificationRecipients.map((notificationRecipient) =>
              notificationChannels.map((notificationChannel) => {
                const id = `${notificationType.id}-${notificationRecipient.id}-${notificationChannel.id}`;
                const checked = values?.notifications?.[id]?.isChecked;
                return (
                  <td key={id}>
                    <Field
                      as={Checkbox}
                      checked={checked}
                      name={`notifications[${id}]`}
                      disabled={notificationChannel.id === 2}
                      onChange={(e: CheckboxChangeParams) => {
                        setFieldValue(
                          `notifications[${id}].isChecked`,
                          e.checked
                        );
                      }}
                    />
                  </td>
                );
              })
            )}
          </tr>
        ))}
      </>
    );
  }, [
    notificationChannels,
    notificationRecipients,
    notificationTypes,
    setFieldValue,
    values?.notifications,
  ]);

  return (
    <>
      <h1 className="title">{t('Notifications')}</h1>

      <p className="subtitle">
        {t('Here you can view your notification settings.')}
      </p>

      <div className="container">
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th rowSpan={2}>{t('Notification type')}</th>

              {isOnMobile ? (
                <>
                  <th colSpan={2}>{t('Sender')}</th>
                  <th colSpan={2}>{t('Recipient')}</th>
                </>
              ) : (
                <>
                  <th>{t('Sender Email')}</th>
                  <th>{t('Sender SMS')}</th>
                  <th>{t('Recipient Email')}</th>
                  <th>{t('Recipient SMS')}</th>
                </>
              )}
            </tr>

            {isOnMobile && (
              <tr>
                <th className="p-text-center">Email</th>
                <th> SMS</th>
                <th>Email</th>
                <th> SMS</th>
              </tr>
            )}
          </thead>

          <tbody>{notificationsTable}</tbody>
        </table>
      </div>

      <div className="p-d-flex p-jc-end p-mt-3">
        <Button label={t('Save changes')} disabled={isLoading} />
      </div>
    </>
  );
}
