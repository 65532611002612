import _ from 'lodash';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import store from '../../../../../redux/store';
import { ClientResource } from '../../../../../types/api/clients';
import { ListTypeEntityPropertyCollection } from '../../../../../types/api/entityproperties';
import { ImportListTypeResource } from '../../../../../types/api/importlisttypes';
import { PlaceResource } from '../../../../../types/api/places';
import { Unpacked } from '../../../../../types/util';
import { yupRequiredField } from '../../../../../utils/helpers/yup';

type ApiData = {
  // User
  user_id: string;
  place: string;
  street: string;
  address: string;
  broj: string;
  vlez: string;
  stan: string;
  // Other settings
  csv: string;
  import_all_or_none: boolean;
  listtype: string;
  osiguruvanje: string;
  otkup: string;
  postarina: string;
  povraten_dokument: string;
  delimiter: string;
};

export type FormValues = Omit<
  ApiData,
  'user_id' | 'delimiter' | 'listtype' | 'csv'
> & {
  _fields: Array<{
    fields: Array<{
      id: number;
      name: string;
      isRequired: boolean;
      value: string | null;
      default_value: string | null;
      property_id: Unpacked<ImportListTypeResource['fields']>['property_id'];
      property_name: string;
    }>;
  }>;
  _clientListType: { id: string; name: string } | null;
  _listType: ImportListTypeResource | null;
  _zip: string;
  _municipality: string;
};

export function getDefaultValues(
  clientData: ClientResource | undefined,
  placeData: PlaceResource | undefined
): FormValues {
  return {
    _fields: [],
    _clientListType: null,
    _listType: null,
    _zip: placeData?.postenski_broj ?? '',
    _municipality: placeData?.opstina_id ?? '',
    place: clientData?.mesto_id ? String(clientData?.mesto_id) : '',
    street: clientData?.ulica_id ? String(clientData?.ulica_id) : '',
    address: clientData?.adresa ? String(clientData?.adresa) : '',
    broj: clientData?.broj ? String(clientData?.broj) : '',
    vlez: clientData?.vlez ? String(clientData?.vlez) : '',
    stan: clientData?.stan ? String(clientData?.stan) : '',
    import_all_or_none: true,
    osiguruvanje: '',
    otkup: '',
    postarina: '',
    povraten_dokument: '',
  };
}

export function toApiData(values: FormValues): ApiData {
  return {
    ..._.omit(values, [
      '_clientListType',
      '_listType',
      '_zip',
      '_municipality',
      '_fields',
    ]),
    listtype: values._listType!.name,
    user_id: store.getState().user.user_id!,
    csv: csvFromFields(values._fields),
    delimiter: '\t',
  };
}

function csvFromFields(fields: FormValues['_fields']): string {
  const header = fields[0].fields.map((f) => f.name).join('\t');
  const content = fields.map((fArr) =>
    fArr.fields.map((f) => f.value).join('\t')
  );

  return header + '\n' + content.join('\n');
}

function generateLeftOutRequiredFields(
  parcels: FormValues['_fields'],
  klient_isSender: string
): string {
  const allPropNames = parcels?.[0]?.fields.map((f) => f.property_name) ?? [];

  const leftOutRequiredFieldNamesPerParcel = parcels.map((parcel) => {
    const filledOutPropNames = parcel.fields
      .filter((f) => !!f.value)
      .map((f) => f.property_name);

    const leftOutRequired = parcel.fields
      .filter((f) => !f.value && f.isRequired)
      .map((f) => f.name);

    return [
      ...leftOutRequired,
      ...leftOutMandatoryFieldNames(
        allPropNames,
        filledOutPropNames,
        klient_isSender
      ),
    ];
  });

  return _.uniq(_.flatten(leftOutRequiredFieldNamesPerParcel)).join(', ');
}

// Fields that MUST be present in the list
function leftOutMandatoryFieldNames(
  allPropNames: string[],
  filledOutPropNames: string[],
  klient_isSender: string
): string[] {
  // 3 possible values, "0", "1" and "2"
  //  When "0" (orderer), both sender and recepient fields are required
  const includeSenderFields = klient_isSender !== '1';
  const includeRecepientFields = klient_isSender !== '2';

  let leftOutFields = [];

  if (includeRecepientFields) {
    if (
      !filledOutPropNames.includes('telefon_do') &&
      !filledOutPropNames.includes('mobilen_do') &&
      _.difference(['telefon_do', 'mobilen_do'], allPropNames).length < 2
    ) {
      leftOutFields.push('Телефон на примач');
    }

    if (
      !filledOutPropNames.includes('mesto_do_id') &&
      !filledOutPropNames.includes('mesto_do_ime') &&
      _.difference(['mesto_do_id', 'mesto_do_ime'], allPropNames).length < 2
    ) {
      leftOutFields.push('Место на примач');
    }

    if (
      !filledOutPropNames.includes('adresa_do') &&
      allPropNames.includes('adresa_do')
    ) {
      leftOutFields.push('Адреса на примач');
    }
  }

  if (includeSenderFields) {
    if (
      !filledOutPropNames.includes('telefon_od') &&
      !filledOutPropNames.includes('mobilen_od') &&
      _.difference(['telefon_od', 'mobilen_od'], allPropNames).length < 2
    ) {
      leftOutFields.push('Телефон на испраќач');
    }

    if (
      !filledOutPropNames.includes('mesto_od_id') &&
      !filledOutPropNames.includes('mesto_od_ime') &&
      _.difference(['mesto_od_id', 'mesto_od_ime'], allPropNames).length < 2
    ) {
      leftOutFields.push('Место на испраќач');
    }

    if (
      !filledOutPropNames.includes('adresa_od') &&
      allPropNames.includes('adresa_od')
    ) {
      leftOutFields.push('Адреса на испраќач');
    }
  }

  return leftOutFields;
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    // Step 1
    _clientListType: Yup.mixed().required(yupRequiredField(t, t('List type'))),
    // Step 2
    _municipality: Yup.string()
      .nullable()
      .when(
        '_listType',
        (_listType: FormValues['_listType'], schema: Yup.SchemaOf<any>) => {
          if (_listType?.klient_isSender === '1') {
            return schema.required(yupRequiredField(t, t('Municipality')));
          }
        }
      ),
    _zip: Yup.string(),
    place: Yup.string()
      .nullable()
      .when(
        '_listType',
        (_listType: FormValues['_listType'], schema: Yup.SchemaOf<any>) => {
          if (_listType?.klient_isSender === '1') {
            return schema.required(yupRequiredField(t, t('Place')));
          }
        }
      ),
    street: Yup.string()
      .nullable()
      .when(
        '_listType',
        (_listType: FormValues['_listType'], schema: Yup.SchemaOf<any>) => {
          if (_listType?.klient_isSender === '1') {
            return schema.required(yupRequiredField(t, t('Street')));
          }
        }
      ),
    broj: Yup.string()
      .nullable()
      .when(
        '_listType',
        (_listType: FormValues['_listType'], schema: Yup.SchemaOf<any>) => {
          if (_listType?.klient_isSender === '1') {
            return schema.required(yupRequiredField(t, t('Street No.')));
          }
        }
      ),
    vlez: Yup.string(),
    stan: Yup.string(),
    // Step 3
    postarina: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Postage'))),
    otkup: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Redemption'))),
    povraten_dokument: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Return document'))),
    osiguruvanje: Yup.string()
      .nullable()
      .required(yupRequiredField(t, t('Insurance'))),
    // Step 4
    _fields: Yup.array()
      .of(Yup.object())
      .test({
        test: function (_fields) {
          if (!_fields) {
            return false;
          }

          const leftOutRequiredFields = generateLeftOutRequiredFields(
            _fields as any,
            this.parent._listType?.klient_isSender ?? ''
          );

          if (leftOutRequiredFields.length > 0) {
            return this.createError({
              path: this.path,
              message: t(
                'Some orders are missing the following field: {{mandatoryFields}}',
                {
                  nsSeparator: false,
                  mandatoryFields: leftOutRequiredFields,
                }
              ),
            });
          }

          return true;
        },
      }),
  });
}

export function generatePropertyName(
  entityProperties: ListTypeEntityPropertyCollection | undefined,
  propertyId: Unpacked<ListTypeEntityPropertyCollection>['id']
) {
  const propertyObj = entityProperties?.find(
    (property) => property.id === propertyId
  );

  return propertyObj?.fieldname ?? '';
}
