import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../../../../hooks/useAxios';
import usePrevious from '../../../../../../hooks/usePrevious';
import {
  MunicipalityCollection,
  MunicipalityPlaceCollection,
} from '../../../../../../types/api/municipalities';
import {
  PlaceResource,
  PlaceStreetCollection,
} from '../../../../../../types/api/places';
import {
  placeItemTemplate,
  placeValueTemplate,
} from '../../../../../../utils/helpers/misc';
import FieldWithErrors from '../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import HeadlessStepperContext from '../../../../../Stepper/HeadlessStepperContext';
import Step from '../../../../../Stepper/Inline/Step';
import { FormValues } from '../WebImporter.functions';

function SenderAddress(): JSX.Element {
  const { t } = useTranslation();

  const { setIsLoading } = useContext(HeadlessStepperContext);

  const { setValue } = useFormContext<FormValues>();

  const _listType = useWatch<FormValues, '_listType'>({ name: '_listType' });
  const _municipality = useWatch<FormValues, '_municipality'>({
    name: '_municipality',
  });
  const place = useWatch<FormValues, 'place'>({ name: 'place' });
  const street = useWatch<FormValues, 'street'>({ name: 'street' });

  const isClientSender = _listType?.klient_isSender === '1';

  const { data: municipalities, isLoading: isMunicipalitiesLoading } =
    useAxios<MunicipalityCollection>('/municipalities');

  const { data: placeData, isLoading: isPlaceDataLoading } =
    useAxios<PlaceResource>(`/places/${place}`, {
      skipWhen: !place,
    });

  const { data: places, isLoading: isPlacesLoading } =
    useAxios<MunicipalityPlaceCollection>(
      `/municipalities/${_municipality}/places`,
      {
        skipWhen: !_municipality,
      }
    );

  const { data: streetsData, isLoading: isStreetsLoading } =
    useAxios<PlaceStreetCollection>(`/places/${place}/streets`, {
      skipWhen: !place,
    });

  useEffect(() => {
    setIsLoading!(
      isMunicipalitiesLoading ||
        isPlaceDataLoading ||
        isPlacesLoading ||
        isStreetsLoading
    );
  }, [
    isMunicipalitiesLoading,
    isPlaceDataLoading,
    isPlacesLoading,
    isStreetsLoading,
    setIsLoading,
  ]);

  const municipalitiesOptions = useMemo(
    () =>
      municipalities?.map((m) => ({
        label: m.ime,
        value: m.id,
      })),
    [municipalities]
  );

  const placesOptions = useMemo(
    () =>
      places?.map((m) => ({
        label: m.ime,
        value: m.id,
        postal_code: m.postenski_broj,
      })) ?? [],
    [places]
  );

  const streetsOptions = streetsData
    ? streetsData.map((m) => ({ label: m.ime, value: m.id }))
    : [];

  const prevPlaceData = usePrevious(placeData);

  useEffect(() => {
    if (!placeData || placeData === prevPlaceData) {
      return;
    }

    setValue('_zip', placeData.postenski_broj);
    setValue('_municipality', placeData.opstina_id);
  }, [placeData, prevPlaceData, setValue]);

  return (
    <Step
      title={t('Address Info')}
      subtitle={
        isClientSender
          ? t('What is the pickup location?')
          : t('What is the delivery location?')
      }
    >
      <FieldWithErrors name="_municipality" label={t('Municipality')}>
        <Controller
          name="_municipality"
          render={({ field }) => (
            <Dropdown
              name="_municipality"
              inputId="_municipality"
              options={municipalitiesOptions}
              filter
              filterPlaceholder={t('Search')}
              placeholder={
                isMunicipalitiesLoading ? t('Loading...') : undefined
              }
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                setValue('_zip', '');
                setValue('place', '');
                setValue('street', '');
                setValue('broj', '');
                setValue('vlez', '');
                setValue('stan', '');
              }}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="place"
        label={t('Place')}
        childrenWrapperClassName="place-wrapper"
      >
        <div className="p-grid">
          <div className="p-col">
            <Controller
              name="place"
              render={({ field }) => (
                <Dropdown
                  disabled={!_municipality || isPlacesLoading}
                  name="place"
                  inputId="place"
                  options={placesOptions}
                  itemTemplate={placeItemTemplate}
                  valueTemplate={placeValueTemplate}
                  filter
                  filterPlaceholder={t('Search')}
                  placeholder={isPlacesLoading ? t('Loading...') : undefined}
                  value={field.value}
                  onChange={(e) => {
                    if (e.value) {
                      const place = places?.find((p) => p.ime === e.value);

                      field.onChange(e.value);
                      setValue('_zip', place?.postenski_broj ?? '');
                      setValue('street', '');
                      setValue('broj', '');
                      setValue('vlez', '');
                      setValue('stan', '');
                    } else {
                      field.onChange('');
                      setValue('_zip', '');
                      setValue('street', '');
                      setValue('broj', '');
                      setValue('vlez', '');
                      setValue('stan', '');
                    }
                  }}
                />
              )}
            />
          </div>

          <div className="p-col-3 p-lg-4">
            <Controller
              name="_zip"
              render={({ field }) => (
                <InputText name="_zip" value={field.value} disabled />
              )}
            />
          </div>
        </div>
      </FieldWithErrors>

      <FieldWithErrors name="street" label={t('Street')}>
        <Controller
          name="street"
          render={({ field }) => (
            <Dropdown
              disabled={!_municipality || !place || isStreetsLoading}
              name="street"
              inputId="street"
              options={streetsOptions}
              filterPlaceholder={t('Search')}
              placeholder={isStreetsLoading ? t('Loading...') : undefined}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                setValue('broj', '');
                setValue('vlez', '');
                setValue('stan', '');
              }}
            />
          )}
        />
      </FieldWithErrors>

      <div className="p-grid">
        <div className="p-col">
          <FieldWithErrors name="broj" label={t('Street No.')}>
            <Controller
              name="broj"
              render={({ field }) => (
                <InputText
                  id={field.name}
                  {...field}
                  disabled={!street}
                  className="broj"
                  name="broj"
                />
              )}
            />
          </FieldWithErrors>
        </div>

        <div className="p-col">
          <FieldWithErrors name="vlez" label={t('Entrance No.')}>
            <Controller
              name="vlez"
              render={({ field }) => (
                <InputText
                  id={field.name}
                  {...field}
                  disabled={!street}
                  className="vlez"
                  name="vlez"
                />
              )}
            />
          </FieldWithErrors>
        </div>

        <div className="p-col">
          <FieldWithErrors name="stan" label={t('Flat No.')}>
            <Controller
              name="stan"
              render={({ field }) => (
                <InputText
                  id={field.name}
                  {...field}
                  disabled={!street}
                  className="stan"
                  name="stan"
                />
              )}
            />
          </FieldWithErrors>
        </div>
      </div>
    </Step>
  );
}

export default SenderAddress;
