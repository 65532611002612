import './Orders.scss';

import classNames from 'classnames';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import useMediaQuery from '../../../hooks/useMediaQuery';
import usePageTitleToggler from '../../../hooks/usePageTitleToggler';
import useResponsiveTableColumns from '../../../hooks/useResponsiveTableColumn';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableState from '../../../hooks/useTableState';
import { WithSummary } from '../../../types/api';
import { OrderCollection } from '../../../types/api/orders';
import { ReduxState } from '../../../types/redux';
import { Unpacked } from '../../../types/util';
import {
  downloadAddressBook,
  downloadFile,
  downloadSticker,
  getFileName,
} from '../../../utils/helpers/files';
import { currencyFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import Table from '../../DataTable/Table/Table';
import SidebarFormFilters from '../../Layout/flex/components/SidebarFormFilters';
import Flex from '../../Layout/flex/Flex';
import MainContent from '../../Layout/flex/MainContent';
import Sidebar from '../../Layout/flex/Sidebar';
import CreateEditRecreate from './CreateEditRecreate/CreateEditRecreate';
import CancelOrderDialog from './Dialogs/Cancel/CancelOrderDialog';
import { ViewOrderDialog } from './Dialogs/View/Order/ViewOrderDialog';
import ViewStatusDialog from './Dialogs/View/Status/ViewStatusDialog';
import useSidebarFilters from './Filters/useSidebarFilters';
import {
  getDefaultValues,
  getNullValues,
  toQueryString,
} from './Filters/useSidebarFilters.functions';
import { FormValues } from './Filters/useSidebarFilters.types';
import {
  additionalColumnProperties,
  generateContextMenu,
  generateGroupActions,
  getColumnHeadersMap,
  getMobileColumnHeadersMap,
  mobileAdditionalColumnProperties,
} from './Orders.functions';

function Orders(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const isOnBigScreen = useMediaQuery('(min-width: 1024px)');

  const [isPrintAddressBookOn, setIsPrintAddressBookOn] = useState(false);

  const [isPrintStickerOn, setIsPrintStickerOn] = useState(false);

  const [action, setAction] = useState<string>('');

  const printActionOverlayPanel = useRef(null);

  const { bottomRightToastRef } = useContext(ToastContext);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    setSortField,
    setSortOrder,
    selectionMultiple,
    setSelectionMultiple,
  } = useTableState<Unpacked<OrderCollection>>();

  const [contextMenuSelection, setContextMenuSelection] = useState<
    Unpacked<OrderCollection> | undefined
  >(undefined);

  const [caller, setCaller] = useState<'group-actions' | 'context-menu'>(
    'context-menu'
  );
  const [selectedPages, setSelectedPages] = useState<Set<number>>(new Set());

  const [httpFiltersObj, setHttpFiltersObj] = useState<
    FormValues & { page: number; limit: number }
  >({
    ...getDefaultValues(location),
    page,
    limit,
  });

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, isLoading, reload, error } = useAxios<
    WithSummary<OrderCollection['data']>
  >(
    {
      url:
        '/orders' +
        queryString(
          httpQueryObject(toQueryString(httpFiltersObj, clientLoggedIn))
        ),
    },
    { skipWhen: !canLoadData }
  );

  const tableClassName = classNames({
    datatable:
      (!isOnMobile && !isOnBigScreen) ||
      (isOnBigScreen && !data?.pagination.total),
    'datatable-responsive': isOnMobile,
  });

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  const columnMobileHeadersMap = useMemo(
    () => getMobileColumnHeadersMap(t),
    [t]
  );

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useResponsiveTableColumns(
      page,
      limit,
      columnHeadersMap,
      columnHeadersMap,
      columnMobileHeadersMap,
      (c: string) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection
        ),
      (c: string) =>
        mobileAdditionalColumnProperties(
          c as keyof typeof columnMobileHeadersMap
        )
    );

  const reloadAddressDoc = useCallback(() => {
    const queryParams = {
      shipment_ids:
        caller === 'context-menu'
          ? contextMenuSelection
            ? [contextMenuSelection.id]
            : []
          : selectionMultiple?.map((s) => parseInt(s?.id)),
    };

    const fileName = getFileName(
      t('Orders'),
      [
        ...(caller === 'context-menu'
          ? [
              contextMenuSelection?.klient_od_ime ?? '',
              contextMenuSelection?.klient_do_ime ?? '',
              contextMenuSelection?.seriski_broj ?? '',
            ]
          : [t('Group.SHE')]),
        t('AddressDocument'),
      ],
      caller !== 'context-menu'
    );

    downloadAddressBook(
      queryParams,
      fileName,
      FileTypes.PDF,
      bottomRightToastRef
    );
  }, [bottomRightToastRef, caller, contextMenuSelection, selectionMultiple, t]);

  const reloadSticker = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    const fileName = getFileName(t('Orders'), [
      contextMenuSelection?.klient_od_ime ?? '',
      contextMenuSelection?.klient_do_ime ?? '',
      contextMenuSelection?.seriski_broj ?? '',
      t('Sticker'),
    ]);

    downloadSticker(
      {
        id: contextMenuSelection.id,
      },
      fileName,
      FileTypes.PDF,
      bottomRightToastRef
    );
  }, [bottomRightToastRef, contextMenuSelection, t]);

  const reloadPickupSpec = useCallback(() => {
    function getPickupSpecQueryParams() {
      if (caller === 'context-menu') {
        return `shipment_ids[]=${contextMenuSelection?.id}`;
      }

      if (selectionMultiple?.length) {
        let queryParams: string = '';

        queryParams += selectionMultiple
          .map((shipment) => `shipment_ids[]=${shipment.id}`)
          .join('&');

        return queryParams;
      }
    }

    downloadFile(
      `${
        process.env.REACT_APP_REPORT_URL
      }/specification/pdf?${getPickupSpecQueryParams()}`,
      getFileName(
        t('Orders'),
        [
          ...(caller === 'context-menu'
            ? [
                contextMenuSelection?.klient_od_ime ?? '',
                contextMenuSelection?.klient_do_ime ?? '',
                contextMenuSelection?.seriski_broj ?? '',
              ]
            : [t('Group.SHE')]),
          t('PickupSpec'),
        ],
        caller !== 'context-menu'
      ),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }, [
    bottomRightToastRef,
    caller,
    contextMenuSelection?.id,
    contextMenuSelection?.klient_do_ime,
    contextMenuSelection?.klient_od_ime,
    contextMenuSelection?.seriski_broj,
    selectionMultiple,
    t,
  ]);

  function handleCMPrintAddressDocClick() {
    if (caller === 'context-menu' && !contextMenuSelection) {
      return;
    }

    if (caller === 'group-actions' && !selectionMultiple.length) {
      return;
    }

    reloadAddressDoc();
  }

  function handleCMPrintStickersClick() {
    if (!contextMenuSelection) {
      return;
    }

    reloadSticker();
  }

  function handleCMPickupSpecificationClick() {
    if (caller === 'context-menu' && !contextMenuSelection) {
      return;
    }

    if (caller === 'group-actions' && !selectionMultiple.length) {
      return;
    }

    reloadPickupSpec();
  }

  function handleCreateOrderClick() {
    history.push('/orders/create');
  }

  const { id: shipmentID, serialNo } =
    useParams<{ id?: string; serialNo?: string }>();

  const {
    show: showViewShipmentDialog,
    hide: hideViewShipmentDialog,
    isVisible: isViewShipmentDialogVisible,
  } = useRouteDialog(
    '/orders',
    `${shipmentID ?? contextMenuSelection?.id}/view`
  );

  const handleCMViewOrderClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    showViewShipmentDialog();
  }, [contextMenuSelection, showViewShipmentDialog]);

  const {
    show: showShipmentTrackingDialog,
    hide: hideShipmentTrackingDialog,
    isVisible: isShipmentTrackingDialogVisible,
  } = useRouteDialog(
    '/orders',
    `${serialNo ?? contextMenuSelection?.seriski_broj}/track`
  );

  function handleCMStatusTrackingClick() {
    if (!contextMenuSelection) {
      return;
    }

    showShipmentTrackingDialog();
  }

  function handleCMRecreateClick() {
    if (!contextMenuSelection) {
      return;
    }

    showRecreateShipmentDialog();
  }

  const {
    show: showEditShipmentDialog,
    hide: hideEditShipmentDialog,
    isVisible: isEditShipmentDialogVisible,
  } = useRouteDialog(
    '/orders',
    `${shipmentID ?? contextMenuSelection?.id}/edit`
  );

  const {
    show: showRecreateShipmentDialog,
    hide: hideRecreateShipmentDialog,
    isVisible: isRecreateShipmentDialogVisible,
  } = useRouteDialog(
    '/orders',
    `${shipmentID ?? contextMenuSelection?.id}/recreate`
  );

  function handleEditRecreateDialogHide() {
    hideEditShipmentDialog();
    hideRecreateShipmentDialog();
  }

  function handleCMEditShipmentClick() {
    if (!contextMenuSelection) {
      return;
    }
    showEditShipmentDialog();
  }

  function handleFromViewToEdit() {
    showEditShipmentDialog();
  }

  const {
    show: showCancelShipmentDialog,
    hide: hideCancelShipmentDialog,
    isVisible: isCancelShipmentDialogVisible,
  } = useRouteDialog('/orders', 'delete');

  usePageTitleToggler(
    !contextMenuSelection
      ? t('Loading...')
      : selectionMultiple?.length > 1
      ? t('Cancel multiple orders')
      : t('Cancel order {{serialNo}}', {
          serialNo: contextMenuSelection?.seriski_broj ?? '',
        }),
    t('Orders'),
    isCancelShipmentDialogVisible
  );

  const handleCMCancelClick = useCallback(() => {
    showCancelShipmentDialog();
  }, [showCancelShipmentDialog]);

  function handleExportExcel() {
    if (!data?.data.length) {
      return;
    }

    downloadFile(
      `/orders/export/excel` +
        queryString(
          _.omit(toQueryString(httpFiltersObj, clientLoggedIn), [
            'page',
            'limit',
          ])
        ),
      getFileName(t('Orders'), undefined, true),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  useEffect(() => {
    setAction('');
  }, [
    hideViewShipmentDialog,
    hideShipmentTrackingDialog,
    hideEditShipmentDialog,
    hideCancelShipmentDialog,
  ]);

  useEffect(() => {
    if (action && contextMenuSelection) {
      setCaller('context-menu');

      if (action === 'view-details') {
        showViewShipmentDialog();
      }
      if (action === 'edit') {
        showEditShipmentDialog();
      }
      if (action === 'track') {
        showShipmentTrackingDialog();
      }
      if (action === 'print-address' && isPrintAddressBookOn) {
        reloadAddressDoc();
      }
      if (action === 'print-sticker' && isPrintStickerOn) {
        reloadSticker();
        setIsPrintStickerOn(false);
      }
      if (action === 'cancel') {
        handleCMCancelClick();
      }
    }
  }, [
    action,
    contextMenuSelection,
    handleCMCancelClick,
    isPrintAddressBookOn,
    isPrintStickerOn,
    reloadAddressDoc,
    reloadSticker,
    selectionMultiple?.length,
    showEditShipmentDialog,
    showShipmentTrackingDialog,
    showViewShipmentDialog,
  ]);

  const [isPageSelected, setIsPageSelected] = useState(false);

  useEffect(() => {
    if (selectedPages.has(page)) {
      setIsPageSelected(true);
    } else {
      setIsPageSelected(false);
    }
  }, [page, selectedPages]);

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      <Column
        key="__checkbox__"
        selectionMode="multiple"
        reorderable={false}
        className="selection-column"
        style={{ width: '38px', flex: '0 1 auto' }}
        header={
          <Checkbox
            checked={isPageSelected}
            onChange={() => {
              if (!data) {
                return;
              }

              if (selectedPages.has(page)) {
                selectedPages.delete(page);

                const dataToKeep: OrderCollection['data'] = [];
                selectionMultiple.forEach((selection) => {
                  if (!data.data.find((entry) => entry.id === selection.id)) {
                    dataToKeep.push(selection);
                  }
                });

                setSelectionMultiple(dataToKeep);

                setSelectedPages((prev) => new Set(prev));
                return;
              }

              selectedPages.add(page);

              const dataToConcat: OrderCollection['data'] = [];

              data?.data.forEach((entry) => {
                if (
                  !selectionMultiple.find(
                    (selection) => selection.id === entry.id
                  )
                ) {
                  dataToConcat.push(entry);
                }
              });

              if (dataToConcat.length > 0) {
                setSelectionMultiple((prev) => [...prev, ...dataToConcat]);
              }
              setSelectedPages((prev) => new Set(prev));
            }}
          />
        }
      />,
      ...columns,
      ...(isOnBigScreen && data?.pagination.total
        ? [
            <Column
              key="action-column"
              header={t('Actions')}
              field="actions"
              frozen
              alignFrozen="right"
              {...additionalColumnProperties(
                t,
                'actions',
                setContextMenuSelection,
                setAction,
                setIsPrintAddressBookOn,
                setIsPrintStickerOn,
                printActionOverlayPanel
              )}
            />,
          ]
        : []),
    ],
    [
      columns,
      data,
      isOnBigScreen,
      isPageSelected,
      page,
      selectedPages,
      selectionMultiple,
      setSelectionMultiple,
      t,
    ]
  );

  const paginatorLeft = (
    <span>
      {t('Total redemption')}:{' '}
      <b>
        {currencyFormat(data?.summary?.redemption ?? '', {
          showCurrency: true,
        })}
      </b>
    </span>
  );

  return (
    <div className="shipments-page">
      <h1 className="title">{t('Orders')}</h1>
      <p className="subtitle">{t('Here you can view and filter orders.')}</p>

      <Button
        type="button"
        label={t('Create Order')}
        icon="fas fa-plus"
        className="main-btn"
        onClick={() => handleCreateOrderClick()}
      />

      <ViewOrderDialog
        id={shipmentID ?? contextMenuSelection?.id}
        visible={isViewShipmentDialogVisible}
        onHide={hideViewShipmentDialog}
        onEdit={handleFromViewToEdit}
      />

      <ViewStatusDialog
        serialNumber={serialNo ?? contextMenuSelection?.seriski_broj ?? ''}
        visible={isShipmentTrackingDialogVisible}
        onHide={hideShipmentTrackingDialog}
      />

      <CreateEditRecreate
        isDialog
        isDialogVisible={
          isEditShipmentDialogVisible || isRecreateShipmentDialogVisible
        }
        onHide={handleEditRecreateDialogHide}
        onFormSubmission={reload}
      />

      <CancelOrderDialog
        visible={isCancelShipmentDialogVisible}
        data={
          caller === 'context-menu'
            ? contextMenuSelection
              ? [contextMenuSelection]
              : []
            : selectionMultiple
        }
        onHide={hideCancelShipmentDialog}
        reloadOrders={reload}
        setSelectionMultiple={setSelectionMultiple}
      />

      <Flex>
        <Sidebar>
          <SidebarFormFilters
            formName="orders-filters"
            getDefaultValues={getDefaultValues}
            getNullValues={getNullValues}
            useSidebarFilters={useSidebarFilters}
            limit={limit}
            page={page}
            setHttpFiltersObj={setHttpFiltersObj}
          />
        </Sidebar>
        <MainContent>
          <Table
            className={tableClassName}
            responsiveLayout="stack"
            breakpoint="768px"
            ref={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading}
            reload={reload}
            isReloadDisabled={!canLoadData}
            hasError={!!error}
            headerTitle={t('Orders')}
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelectionMultiple}
            sortOrder={sortOrder}
            selection={selectionMultiple}
            contextMenuModel={generateContextMenu(
              t,
              contextMenuSelection,
              handleCMViewOrderClick,
              handleCMStatusTrackingClick,
              handleCMPrintAddressDocClick,
              handleCMPrintStickersClick,
              handleCMPickupSpecificationClick,
              handleCMRecreateClick,
              handleCMEditShipmentClick,
              handleCMCancelClick,
              setCaller
            )}
            groupActionsModel={generateGroupActions(
              t,
              selectionMultiple,
              handleCMPrintAddressDocClick,
              handleCMPickupSpecificationClick,
              handleCMCancelClick,
              setCaller
            )}
            onRowDoubleClick={(e: DataTableRowClickEventParams) => {
              setContextMenuSelection(e.data);
              handleCMViewOrderClick();
            }}
            selectionMode="multiple"
            storageString="shipments_dataTable"
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            paginatorLeft={paginatorLeft}
            exportToExcelButton
            onExportToExcelButtonClick={handleExportExcel}
            displayActionColumn
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}
export default Orders;
