import classNames from 'classnames';
import { HTMLAttributes, ReactNode, Ref, forwardRef } from 'react';

import styles from './Flex.module.scss';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Flex = forwardRef(
  ({ children, ...other }: Props, ref: Ref<HTMLDivElement>): JSX.Element => {
    return (
      <div ref={ref} {...other} className={classNames(styles.flex)}>
        {children}
      </div>
    );
  }
);

export default Flex;
