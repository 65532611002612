import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import useAxios from '../../../../hooks/useAxios';
import usePageTitleToggler from '../../../../hooks/usePageTitleToggler';
import usePrevious from '../../../../hooks/usePrevious';
import {
  ClientResource,
  ClientSubsidiaryCollection,
} from '../../../../types/api/clients';
import { ConfigCollection } from '../../../../types/api/configs';
import { HubCollection } from '../../../../types/api/hubs';
import { OrderResource } from '../../../../types/api/orders';
import { LabelValue } from '../../../../types/options';
import { ReduxState } from '../../../../types/redux';
import { EntityIdRouteParams } from '../../../../types/routing';
import {
  cleanUpObject,
  getChangedValues,
} from '../../../../utils/helpers/object';
import {
  errorToast,
  infoToast,
  successToast,
} from '../../../../utils/helpers/primereact';
import DialogSpinner from '../../../Dialogs/DialogSpinner/DialogSpinner';
import DialogStepper from '../../../Stepper/Dialog/DialogStepper';
import { HeadlessStepperProps, Step } from '../../../Stepper/HeadlessStepper';
import Stepper from '../../../Stepper/Inline/Stepper';
import {
  CustomerRole,
  FormFields,
  getActionFromPathname,
  getCalculatorData,
  getDefaultValues,
  getValidationSchema,
  toApiData,
} from './CreateEditRecreate.functions';
import OrderResult from './OrderResult/OrderResult';
import General from './Steps/General';
import OrderSettings from './Steps/OrderSettings';
import Payments from './Steps/Payments';
import Recipient from './Steps/Recipient';
import Sender from './Steps/Sender';
import StepsContext from './StepsContext';
import useCalculator from './useCalculator';
import useCustomerRoleWatcher from './useCustomerRoleWatcher';
import useOrderSettingsStep from './useOrderSettingsStep';
import useRecipientStep from './useRecipientStep';
import useSenderStep from './useSenderStep';

type StepWithValidation<T> = Step & {
  fieldNames: (keyof T)[];
};

type Props = {
  isDialog?: boolean;
  isDialogVisible?: boolean;
  onHide?: () => void;
  onFormSubmission?: () => void;
  orderId?: string;
};

function CreateEditRecreate({
  isDialog,
  onHide,
  onFormSubmission,
  isDialogVisible,
  orderId,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [hasSucceeded, setHasSucceeded] = useState<boolean>(false);
  const [hasFailed, setHasFailed] = useState<boolean>(false);

  // !!!!!!!!!! WARNING !!!!!!!!!!
  // This is a code smell.
  // When the form is submitted and the "Create new order" button is clicked,
  //  we reset all form fields. But, if the previous customer role was sender,
  //  all sender fields are initially empty (useEffects don't run).
  // So, as a workaround, we use this state to force changes for the sender fields
  //  in the next render.
  // TODO: Find a concept to avoid this and refactor
  const [isResettingCustomerRole, setIsResettingCustomerRole] = useState(false);

  const { toastRef } = useContext(ToastContext);

  const history = useHistory();
  const location = useLocation();

  const { id: routeId } = useParams<EntityIdRouteParams>();
  const id = orderId ?? routeId;

  const action = getActionFromPathname(location.pathname);
  const prevAction = usePrevious(action);

  const userClientId = useSelector<ReduxState, ReduxState['user']['client_id']>(
    (state) => state?.user.client_id
  );

  const {
    data: configs,
    error: configsError,
    isLoading: areConfigsLoading,
  } = useAxios<ConfigCollection>('/configs');

  const isMobileOrPhoneRequired = useMemo<boolean>(
    () =>
      !!configs?.data.find(
        (c) => c.section === 'Customer' && c.ident === 'RequiredMobileOrPhone'
      )?.value,
    [configs]
  );

  const isStreetNoRequired = useMemo<boolean>(
    () =>
      !!configs?.data.find(
        (c) => c.section === 'Customer' && c.ident === 'RequiredHouseNumber'
      )?.value,
    [configs]
  );

  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
  } = useAxios<ClientResource>(
    { url: `/clients/${userClientId}` },
    {
      skipWhen: !userClientId,
    }
  );

  const {
    data: subsidiaries,
    error: subsidiariesError,
    isLoading: isSubsidiariesLoading,
  } = useAxios<ClientSubsidiaryCollection>({
    url: `/clients/${userClientId}/subsidiaries`,
  });

  const {
    data: order,
    error: orderError,
    isLoading: isOrderLoading,
  } = useAxios<OrderResource>(
    {
      url: `/orders/${id}`,
    },
    { skipWhen: !id || ((isDialog && !isDialogVisible) ?? false) }
  );

  const {
    data: hubs,
    error: hubsError,
    isLoading: areHubsLoading,
  } = useAxios<HubCollection>('/hubs?limit=0');

  const hubOptions: LabelValue[] = useMemo(
    () =>
      hubs?.data?.map((hub) => {
        return { value: hub.id, label: hub.name };
      }) ?? [],
    [hubs?.data]
  );

  const prevConfigsError = usePrevious(configsError);
  const prevUserError = usePrevious(userError);
  const prevHubsError = usePrevious(hubsError);
  const prevOrderError = usePrevious(orderError);
  const prevSubsidiariesError = usePrevious(subsidiariesError);

  useEffect(() => {
    if (
      (!configsError || configsError === prevConfigsError) &&
      (!userError || userError === prevUserError) &&
      (!hubsError || hubsError === prevHubsError) &&
      (!orderError || orderError === prevOrderError) &&
      (!subsidiariesError || subsidiariesError === prevSubsidiariesError)
    ) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading order data')
      );
    }

    history.replace('/orders');
  }, [
    configsError,
    history,
    hubsError,
    orderError,
    prevConfigsError,
    prevHubsError,
    prevOrderError,
    prevSubsidiariesError,
    prevUserError,
    subsidiariesError,
    t,
    toastRef,
    userError,
  ]);

  // Document title
  usePageTitleToggler(
    action === 'create'
      ? t('Create Order')
      : action === 'edit'
      ? t('Editing {{name}}', { name: order?.seriski_broj ?? '' })
      : t('Recreating {{name}}', { name: order?.seriski_broj ?? '' }),
    t('Orders'),
    !isDialog || !!isDialogVisible
  );

  const defaultValues = useMemo(
    () => getDefaultValues(userClientId, order, subsidiaries),
    [order, subsidiaries, userClientId]
  );

  const resolver = useMemo(
    () =>
      yupResolver(
        getValidationSchema(t, isMobileOrPhoneRequired, isStreetNoRequired)
      ),
    [isMobileOrPhoneRequired, isStreetNoRequired, t]
  );

  const methods = useForm<FormFields>({
    defaultValues,
    resolver,
  });

  const { handleSubmit, trigger, reset, setValue } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // Reset on action change.
  //  Let's say the user is editing an order and for some reason they navigate to the 'create' page
  //  Without this hook, that'd be a 'recreate' action. We don't want that.
  useEffect(() => {
    if (action === prevAction) {
      return;
    }

    if (action === 'create') {
      reset(getDefaultValues(userClientId, undefined, undefined));
    }
  }, [action, prevAction, reset, userClientId]);

  // Form-related hooks
  useCalculator(methods, defaultValues, action, !order || !subsidiaries);

  const updateCustomerRoleFields = useCustomerRoleWatcher(
    methods,
    order,
    user,
    subsidiaries,
    action
  );

  const senderStepProps = useSenderStep(methods, defaultValues, action);
  const recipientStepProps = useRecipientStep(methods, defaultValues, action);
  const orderSettingsStepProps = useOrderSettingsStep(
    methods,
    defaultValues,
    action
  );

  // Run customer_role change hook when default values change.
  // Otherwise, resetting defaultFields above often overrides the previously modified fields by this hook,
  //  leaving the user with empty fields in the 'sender' step
  useEffect(() => {
    updateCustomerRoleFields();
  }, [updateCustomerRoleFields, defaultValues]);

  const steps = useMemo<StepWithValidation<FormFields>[]>(() => {
    let returnArray: StepWithValidation<FormFields>[] = [
      {
        component: General,
        fieldNames: ['customer_role', 'internal_shipment'],
      },
      {
        component: Sender,
        fieldNames: [
          'klient_od_id',
          'klient_od_ime',
          'mobilen_od',
          'telefon_od',
          'mesto_od_id',
          'mesto_od_ime',
          'ulica_od_id',
          'ulica_od_ime',
          'adresa_od',
          'broj_od',
          'vlez_od',
          'stan_od',
          'hub_od_id',
          'pickup_location_type_id',
        ],
      },
      {
        component: Recipient,
        fieldNames: [
          'klient_do_id',
          'klient_do_ime',
          'mobilen_do',
          'telefon_do',
          'mesto_do_id',
          'mesto_do_ime',
          'ulica_do_id',
          'ulica_do_ime',
          'adresa_do',
          'broj_do',
          'vlez_do',
          'stan_do',
          'hub_do_id',
          'delivery_location_type_id',
        ],
      },
      {
        component: OrderSettings,
        fieldNames: [
          'proizvod_id',
          'tezina',
          'volumen',
          'otkup',
          'vrednost',
          'reference1',
          'reference2',
          'reference_id',
          'povraten_dokument',
          'komentar',
        ],
      },
      {
        component: Payments,
        fieldNames: ['payments'],
      },
    ];

    return returnArray;
  }, []);

  const {
    data: formSubmissionData,
    error: formSubmissionError,
    isLoading: isFormSubmissionLoading,
    reload: formSubmissionReload,
  } = useAxios<{ id: number }>();

  const prevformSubmissionData = usePrevious(formSubmissionData);
  const prevformSubmissionError = usePrevious(formSubmissionError);

  useEffect(() => {
    if (
      !formSubmissionData ||
      formSubmissionData === prevformSubmissionData ||
      !toastRef?.current
    ) {
      return;
    }

    setHasSucceeded(true);
    setHasFailed(false);

    switch (action) {
      case 'edit':
        successToast(
          toastRef,
          t('Success'),
          t('Your order has been successfully updated.')
        );
        break;

      case 'recreate':
        successToast(
          toastRef,
          t('Success'),
          t('Your order has been successfully recreated.')
        );
        break;

      default:
        successToast(
          toastRef,
          t('Success'),
          t('Your order has been successfully created.')
        );
    }

    onFormSubmission?.();
    onHide?.();
  }, [
    action,
    formSubmissionData,
    onFormSubmission,
    onHide,
    prevformSubmissionData,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (
      !formSubmissionError ||
      formSubmissionError === prevformSubmissionError ||
      !toastRef?.current
    ) {
      return;
    }

    setHasSucceeded(false);
    setHasFailed(true);

    switch (action) {
      case 'edit':
        errorToast(
          toastRef,
          t('Error'),
          t(
            'An error occured while updating your order. Please check your inputs.'
          )
        );
        break;

      case 'recreate':
        errorToast(
          toastRef,
          t('Error'),
          t(
            'An error occured while recreating your order. Please check your inputs.'
          )
        );
        break;

      default:
        errorToast(
          toastRef,
          t('Error'),
          t(
            'An error occured while creating your order. Please check your inputs.'
          )
        );
    }

    onFormSubmission?.();
  }, [
    action,
    formSubmissionError,
    onFormSubmission,
    prevformSubmissionError,
    t,
    toastRef,
  ]);

  useEffect(() => {
    if (!isResettingCustomerRole) {
      return;
    }

    setValue('customer_role', CustomerRole.Sender);
    setIsResettingCustomerRole(false);
  }, [isResettingCustomerRole, setValue]);

  function onFormSubmit(values: FormFields) {
    if (action === 'edit') {
      const shouldIncludePayments =
        !_.isEqual(
          getCalculatorData(values, userClientId),
          getCalculatorData(defaultValues, userClientId)
        ) || !_.isEqual(values.payments, defaultValues.payments);

      const apiData = getChangedValues(
        toApiData(values, userClientId, shouldIncludePayments),
        toApiData(defaultValues, userClientId, shouldIncludePayments)
      );

      // If there are no changes introduced, don't contaminate the API
      if (!Object.keys(apiData).length) {
        infoToast(
          toastRef,
          t('No changes made'),
          t("You haven't made any changes yet.")
        );

        return;
      }

      formSubmissionReload({
        url: `/orders/${id}`,
        method: 'PUT',
        data: apiData,
      });
    } else {
      formSubmissionReload({
        url: '/orders/add',
        method: 'POST',
        data: cleanUpObject(toApiData(values, userClientId)),
      });
    }
  }

  const serialNumberTitle = order?.seriski_broj ? ' ' + order.seriski_broj : '';

  const title =
    action === 'create'
      ? t('Create Order')
      : action === 'edit'
      ? t('Edit Order') + serialNumberTitle
      : t('Recreate Order') + serialNumberTitle;

  const nextButtonProps = {
    form: 'create-edit-recreate-form',
    'data-cy': 'submit-btn',
  };

  const onNextBtnClick: HeadlessStepperProps['onNextBtnClick'] = async ({
    stepIndex,
  }) => {
    return await trigger(steps[stepIndex].fieldNames);
  };

  const onCancelBtnClick: Parameters<typeof Stepper>[0]['onCancelBtnClick'] =
    isDialog && onHide ? onHide : () => history.push('/orders');

  const submitBtnLabel = action === 'edit' ? t('Edit') : t('Create');

  const isStepIndicatorShown =
    !!configs &&
    !!user &&
    !!subsidiaries &&
    !!hubs &&
    (action !== 'create' ? !!order : true);

  const isLoading =
    areConfigsLoading ||
    isUserLoading ||
    isSubsidiariesLoading ||
    isOrderLoading ||
    areHubsLoading ||
    isFormSubmissionLoading ||
    senderStepProps.isPlacesDataLoading ||
    senderStepProps.isStreetsDataLoading ||
    recipientStepProps.isPlacesDataLoading ||
    recipientStepProps.isStreetsDataLoading ||
    orderSettingsStepProps.isRelatedOrderOptionsDataLoading;

  return !isDialog && isFormSubmissionLoading ? (
    <DialogSpinner />
  ) : !isDialog && (hasSucceeded || hasFailed) ? (
    <OrderResult
      action={action}
      hasFailed={hasFailed}
      onCreateANewOrderBtnClick={() => {
        if (location.pathname !== '/orders/create') {
          history.push('/orders/create');
        }

        reset(getDefaultValues(userClientId, undefined, undefined));

        // --- Code smell --- //
        setValue('customer_role', CustomerRole.Orderer);
        setIsResettingCustomerRole(true);
        // --- Code smell --- //

        setHasSucceeded(false);
        setHasFailed(false);
      }}
      onCreateASimilarOrderBtnClick={() => {
        if (location.pathname !== '/orders/create') {
          history.push(
            !!formSubmissionData
              ? `/orders/${formSubmissionData?.id}/recreate`
              : '/orders/create'
          );
        }

        setHasSucceeded(false);
        setHasFailed(false);
      }}
    />
  ) : (
    <FormProvider {...methods}>
      <StepsContext.Provider
        value={{
          sender: { subsidiaries, hubOptions, ...senderStepProps },
          recipient: { subsidiaries, hubOptions, ...recipientStepProps },
          orderSettings: orderSettingsStepProps,
        }}
      >
        <form
          id="create-edit-recreate-form"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          {isDialog ? (
            <DialogStepper
              title={title}
              steps={steps}
              nextButtonProps={nextButtonProps}
              onNextBtnClick={onNextBtnClick}
              onCancelBtnClick={onHide ? onHide : onCancelBtnClick}
              submitBtnLabel={submitBtnLabel}
              isStepIndicatorShown={isStepIndicatorShown}
              isLoading={isLoading}
              dialogProps={{
                onHide: onHide ? onHide : onCancelBtnClick,
                visible: isDialogVisible,
                style: { height: 650 },
              }}
            />
          ) : (
            <Stepper
              title={title}
              steps={steps}
              nextButtonProps={nextButtonProps}
              onNextBtnClick={onNextBtnClick}
              onCancelBtnClick={onCancelBtnClick}
              submitBtnLabel={submitBtnLabel}
              isStepIndicatorShown={isStepIndicatorShown}
              isLoading={isLoading}
            />
          )}
        </form>
      </StepsContext.Provider>
    </FormProvider>
  );
}

export default CreateEditRecreate;
