import './Profile.scss';

import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ToastContext from '../../../context/ToastContext';
import useAxios from '../../../hooks/useAxios';
import useMediaQuery from '../../../hooks/useMediaQuery';
import usePageTitle from '../../../hooks/usePageTitle';
import usePrevious from '../../../hooks/usePrevious';
import { ClientResource } from '../../../types/api/clients';
import { ReduxState } from '../../../types/redux';
import { getChangedValues } from '../../../utils/helpers/object';
import { errorToast, successToast } from '../../../utils/helpers/primereact';
import {
  FormFields,
  getInitialValues,
  toApiData,
  validationSchema,
} from './Profile.functions';
import ProfileContent from './ProfileContent';

function Profile() {
  const { t } = useTranslation();

  usePageTitle(t('Profile'));

  const formRef = useRef<FormikProps<any>>(null);
  const isSmallerDevice = useMediaQuery('(max-width: 800px)');

  const toastRef = useContext(ToastContext)?.toastRef!;

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const { data: clientData } = useAxios<ClientResource>(
    {
      url: `/clients/${clientLoggedIn}`,
    },
    { skipWhen: !clientLoggedIn }
  );

  const {
    data: updateUserData,
    error: updateUserDataError,
    isLoading: isLoadingUpdateUserData,
    reload: updateUserDataRequest,
  } = useAxios();

  const prevUserData = usePrevious(updateUserData);
  const prevUserError = usePrevious(updateUserDataError);

  function handleFormSubmission(values: FormFields) {
    const apiData = getChangedValues(
      toApiData(values),
      formRef.current?.initialValues
    );

    if (Object.keys(apiData).length) {
      updateUserDataRequest({
        url: `/clients/${clientLoggedIn}/self`,
        method: 'PUT',
        data: apiData,
      });
    } else {
      successToast(
        toastRef,
        t('User data updated'),
        t('User data is updated sucessfully.')
      );
    }
  }

  useEffect(() => {
    if (prevUserData === updateUserData) {
      return;
    }

    if (updateUserData) {
      successToast(
        toastRef,
        t('User data updated'),
        t('User data is updated sucessfully.')
      );
    }
  }, [prevUserData, t, toastRef, updateUserData]);

  useEffect(() => {
    if (prevUserError === updateUserDataError) {
      return;
    }

    errorToast(
      toastRef,
      t('Update user data failed'),
      t('An error occured while trying to update user data.')
    );
  }, [prevUserError, t, toastRef, updateUserDataError]);

  return (
    <div className="profile-page">
      <h1 className="title">{t('Profile')}</h1>
      <p className="subtitle">
        {t(
          'Here you can view your personal info and change your password easy.'
        )}
      </p>

      <div>
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(clientData)}
          onSubmit={handleFormSubmission}
          validateOnBlur
          validateOnChange
          validateOnMount
          validationSchema={validationSchema(t)}
          enableReinitialize
        >
          <Form>
            <ProfileContent
              data={clientData}
              isSmallerDevice={isSmallerDevice}
            />
          </Form>
        </Formik>

        <div className="p-d-flex p-jc-end p-mt-3">
          <Button
            type="button"
            label={isLoadingUpdateUserData ? t('Saving...') : t('Save changes')}
            onClick={() => formRef?.current?.handleSubmit()}
            disabled={isLoadingUpdateUserData}
            data-cy="submit-btn"
          />
        </div>
      </div>
    </div>
  );
}

export default Profile;
