import dayjs from 'dayjs';
import _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useAxios from '../../../hooks/useAxios';
import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryAutoCompleteInputParam from '../../../hooks/useSearchQueryAutoCompleteInputParam';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { ClientImportListTypeCollection } from '../../../types/api/clients';
import { ImportListTypeResource } from '../../../types/api/importlisttypes';
import { ReduxState } from '../../../types/redux';
import { debounceTimeout } from '../../../utils/constants/misc';
import { dateToFilterText } from '../../../utils/helpers/dataTable';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';
import TableHeaderFilters from '../../DataTable/Table/HeaderFilters/TableHeaderFilters';
import {
  OrdinaryFilter,
  RequiredFilter,
  filterByValue,
} from '../../DataTable/Table/HeaderFilters/TableHeaderFilters.function';
import AutoCompleteInput from '../../Forms/AutoCompleteInput/AutoCompleteInput';
import { ClientListTypeOption } from './BulkOrders.types';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();

  const location = useLocation();

  const [headerFiltersCount, setHeadersFilterCount] = useState<number>(0);

  const [listTypeFilter, setListTypeFilter] = useState('');
  const [listTypeFilterObj, setListTypeFilterObj] =
    useState<ClientListTypeOption | null>(null);

  const [nameFilter, setNameFilter] = useState(
    () => getSearchQueryParam(location.search, 'name') ?? ''
  );

  const [dateFromFilter, setDateFromFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_from') ?? ''
      ) ?? new Date()
  );

  const [dateToFilter, setDateToFilter] = useState<Date | null>(
    () =>
      tryDateSearchParam(
        getSearchQueryParam(location.search, 'date_to') ?? ''
      ) ?? new Date()
  );

  const [barcodeFilter, setBarcodeFilter] = useState(
    () => getSearchQueryParam(location.search, 'barcode') ?? ''
  );

  const debouncedNameFilter = useDebounce(nameFilter, debounceTimeout);
  const debouncedListTypeFilter = useDebounce(listTypeFilter, debounceTimeout);
  const debouncedBarcodeFilter = useDebounce(barcodeFilter, debounceTimeout);

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const { data: listTypeData } = useAxios<ClientImportListTypeCollection>(
    `/clients/${clientLoggedIn}/importlists/types` +
      queryString({ name: debouncedListTypeFilter }),
    { skipWhen: _.isObject(listTypeFilterObj) }
  );

  const listTypeOptions = useMemo(
    () =>
      listTypeData?.map((listType) => {
        return {
          label: listType.name,
          value: listType,
        };
      }) ?? [],
    [listTypeData]
  );

  const filtersArr = useMemo(
    () => [
      dateFromFilter,
      dateToFilter,
      listTypeFilterObj,
      debouncedNameFilter,
      debouncedBarcodeFilter,
    ],
    [
      dateFromFilter,
      dateToFilter,
      debouncedBarcodeFilter,
      debouncedNameFilter,
      listTypeFilterObj,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [
    setPage,
    dateFromFilter,
    dateToFilter,
    listTypeFilterObj,
    debouncedNameFilter,
    debouncedBarcodeFilter,
  ]);

  useSearchQueryParam('name', debouncedNameFilter);
  useSearchQueryParam('barcode', debouncedBarcodeFilter);

  useSearchQueryDateParam('date_from', dateFromFilter);
  useSearchQueryDateParam('date_to', dateToFilter);

  useSearchQueryAutoCompleteInputParam<
    ClientListTypeOption,
    ImportListTypeResource
  >({
    param: 'listType',
    filterValue: listTypeFilter,
    setFilterValue: setListTypeFilter,
    filterValueObj: listTypeFilterObj,
    setFilterValueObj: setListTypeFilterObj,
    axiosRequestConfig: (listTypeId) => `/importlisttypes/${listTypeId}`,
    initialFilterValueDataPath: 'name',
    filterValueObjPath: 'id',
    initialDataModifier: (listTypeResource) => ({
      id: String(listTypeResource.id),
      name: listTypeResource.name,
    }),
  });

  const httpFiltersObj = useMemo(
    () => ({
      page: haveFiltersChanged ? 1 : page,
      limit,
      client_id: clientLoggedIn,
      name: debouncedNameFilter,
      import_list_type_id: listTypeFilterObj?.id,
      barcode: debouncedBarcodeFilter,
      created_date_from: dateFromFilter
        ? httpDateFormat(dateFromFilter)
        : undefined,
      created_date_to: dateToFilter ? httpDateFormat(dateToFilter) : undefined,
    }),
    [
      clientLoggedIn,
      dateFromFilter,
      dateToFilter,
      debouncedBarcodeFilter,
      debouncedNameFilter,
      haveFiltersChanged,
      limit,
      listTypeFilterObj?.id,
      page,
    ]
  );

  const headerFiltersForm = useMemo<JSX.Element>(
    () => (
      <>
        <div className="filter">
          <label htmlFor="filter_date">{t('Date From')}</label>

          <Calendar
            value={dateFromFilter ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date"
            onChange={(e) => {
              setDateFromFilter(e.value as Date | null);
            }}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_date">{t('Date To')}</label>

          <Calendar
            value={dateToFilter ?? undefined}
            monthNavigator
            yearNavigator
            yearRange={`2009:${dayjs().format('YYYY')}`}
            dateFormat="dd/mm/yy"
            maxDate={new Date()}
            inputId="filter_date"
            onChange={(e) => {
              setDateToFilter(e.value as Date | null);
            }}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_imported_list_name">{t('Name')}</label>

          <InputText
            id="filter_imported_list_name"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_barcode">{t('Barcode')}</label>

          <InputText
            id="filter_barcode"
            value={barcodeFilter}
            onChange={(e) => setBarcodeFilter(e.target.value)}
          />
        </div>

        <div className="filter">
          <label htmlFor="filter_list_type">{t('List Type')}</label>

          <AutoCompleteInput
            id="filter_list_type"
            filterValue={listTypeFilter}
            value={listTypeFilterObj}
            options={listTypeOptions}
            onFilterChange={setListTypeFilter}
            onSelectionChange={setListTypeFilterObj}
          />
        </div>
      </>
    ),
    [
      barcodeFilter,
      dateFromFilter,
      dateToFilter,
      listTypeFilter,
      listTypeFilterObj,
      listTypeOptions,
      nameFilter,
      t,
    ]
  );

  const headerFilters = useMemo(() => {
    const required: RequiredFilter[] = [
      {
        label: t('Date'),
        value: dateToFilterText([dateFromFilter, dateToFilter]),
      },
    ].filter(filterByValue);

    const ordinary: OrdinaryFilter[] = [
      {
        label: t('Name'),
        value: nameFilter,
        onDelete: () => setNameFilter(''),
      },
      {
        label: t('Barcode'),
        value: barcodeFilter,
        onDelete: () => setBarcodeFilter(''),
      },
      {
        label: t('List Type'),
        value: listTypeFilterObj
          ? listTypeOptions.find((m) => m.value.id === listTypeFilterObj.id)
              ?.label ?? ''
          : '',
        onDelete: () => {
          setListTypeFilter('');
          setListTypeFilterObj(null);
        },
      },
    ].filter(filterByValue);

    setHeadersFilterCount(required.length + ordinary.length);

    return <TableHeaderFilters required={required} ordinary={ordinary} />;
  }, [
    barcodeFilter,
    dateFromFilter,
    dateToFilter,
    listTypeFilterObj,
    listTypeOptions,
    nameFilter,
    t,
  ]);

  function resetAllFilters() {
    setDateFromFilter(new Date());
    setDateToFilter(new Date());
    setNameFilter('');
    setBarcodeFilter('');
    setListTypeFilter('');
    setListTypeFilterObj(null);
  }

  return {
    headerFiltersForm,
    headerFilters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
