import { userPrefixedString } from './user';

function getStorageItem(
  key: string,
  isLocalStorage: boolean,
  isUserPrefixed: boolean
): string | null {
  let item: string | null = null;

  try {
    const result = (isLocalStorage ? localStorage : sessionStorage).getItem(
      isUserPrefixed ? userPrefixedString(key) : key
    );

    item = typeof result === 'string' ? JSON.parse(result) : result;
  } catch (e) {}

  return item;
}

function setStorageItem(
  key: string,
  item: string,
  isLocalStorage: boolean,
  isUserPrefixed: boolean
) {
  try {
    (isLocalStorage ? localStorage : sessionStorage).setItem(
      isUserPrefixed ? userPrefixedString(key) : key,
      JSON.stringify(item)
    );
  } catch (e) {}
}

export function removeStorageItem(
  key: string,
  isLocalStorage: boolean,
  isUserPrefixed: boolean
) {
  try {
    (isLocalStorage ? localStorage : sessionStorage).removeItem(
      isUserPrefixed ? userPrefixedString(key) : key
    );
  } catch (e) {}
}

// Local
export function getLocalStorageItem(key: string) {
  return getStorageItem(key, true, false);
}
export function setLocalStorageItem(key: string, item: string) {
  return setStorageItem(key, item, true, false);
}
export function removeLocalStorageItem(key: string) {
  return removeStorageItem(key, true, false);
}

export function getUserPrefixedLocalStorageItem(key: string) {
  return getStorageItem(key, true, true);
}
export function setUserPrefixedLocalStorageItem(key: string, item: string) {
  return setStorageItem(key, item, true, true);
}
export function removeUserPrefixedLocalStorageItem(key: string) {
  return removeStorageItem(key, true, true);
}

// Session
export function getSessionStorageItem(key: string) {
  return getStorageItem(key, false, false);
}
export function setSessionStorageItem(key: string, item: string) {
  return setStorageItem(key, item, false, false);
}
export function removeSessionStorageItem(key: string) {
  return removeStorageItem(key, false, false);
}

export function getUserPrefixedSessionStorageItem(key: string) {
  return getStorageItem(key, false, true);
}
export function setUserPrefixedSessionStorageItem(key: string, item: string) {
  return setStorageItem(key, item, false, true);
}
export function removeUserPrefixedSessionStorageItem(key: string) {
  return removeStorageItem(key, false, true);
}
