import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  PaymentMethod,
  UnregisteredClientId,
} from '../../../../../types/api/orders';
import { LabelValue } from '../../../../../types/options';
import { ReduxState } from '../../../../../types/redux';
import { Unpacked } from '../../../../../types/util';
import { currencyFormat } from '../../../../../utils/helpers/formatting';
import Step from '../../../../Stepper/Inline/Step';
import { FormFields } from '../CreateEditRecreate.functions';

function Payments(): JSX.Element {
  const { t } = useTranslation();

  const loggedInUserClientId = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((s) => s.user.client_id);

  const klient_od_id = useWatch<FormFields, 'klient_od_id'>({
    name: 'klient_od_id',
  });

  const klient_do_id = useWatch<FormFields, 'klient_do_id'>({
    name: 'klient_do_id',
  });

  const customer_role = useWatch<FormFields, 'customer_role'>({
    name: 'customer_role',
  });

  const { fields, replace } = useFieldArray<FormFields, 'payments'>({
    name: 'payments',
  });

  const paymentMethodOptions = useMemo<LabelValue<PaymentMethod>[]>(
    () => [
      { value: PaymentMethod.Cash, label: t('Cash') },
      { value: PaymentMethod.Invoice, label: t('Invoice') },
    ],
    [t]
  );

  const paymentCustomerOptions = useMemo(
    () => [
      {
        value: klient_od_id,
        label: t('Sender pays'),
      },
      {
        value: klient_do_id,
        label: t('Recipient pays'),
      },
      ...(customer_role === 'orderer'
        ? [
            {
              value: loggedInUserClientId,
              label: t('Orderer pays'),
            },
          ]
        : []),
    ],
    [customer_role, klient_do_id, klient_od_id, loggedInUserClientId, t]
  );

  function onPaymentChange<F extends keyof Unpacked<FormFields['payments']>>(
    idx: number,
    field: F,
    value: Unpacked<FormFields['payments']>[F]
  ) {
    replace(fields.map((p, i) => (i === idx ? { ...p, [field]: value } : p)));
  }

  return (
    <Step title={t('Payments')} fullWidth>
      {!fields.length ? (
        <div className="subtitle p-text-center">
          {t("An error occured while calculating order's payments")}
        </div>
      ) : (
        <div className="payments">
          {fields.map((payment, idx) => {
            const id = `payment_${payment.id}`;
            // Payments don't always include an ID,
            //  hence the need to rely on combination of multiple properties.
            const key = `${payment.usluga_id}-${payment.opis}-${payment.iznos}-${payment.ediznos}-${payment.kolicina}`;

            return (
              <div className="p-mb-3" key={key}>
                <Tooltip target={`#${id}`} content={payment.opis} />

                <p className="p-mb-1">
                  <span id={id} className="p-pr-3">
                    {payment.usluga_ime}

                    <span
                      className="p-ml-1 p-text-secondary"
                      style={{ fontSize: 12, color: 'var(--surface-400)' }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                  </span>
                </p>

                <div className="p-grid">
                  <div className="p-col p-sm-12 p-md-3">
                    <InputText
                      value={currencyFormat(payment.iznos!, {
                        showCurrency: true,
                      })}
                      disabled
                    />
                  </div>

                  <div className="p-col p-sm-12 p-md-4">
                    <Dropdown
                      value={fields[idx].gotovina}
                      onChange={(e) =>
                        onPaymentChange(idx, 'gotovina', e.value)
                      }
                      options={paymentMethodOptions}
                      disabled={
                        fields[idx].klient_id === UnregisteredClientId.Sender ||
                        fields[idx].klient_id === UnregisteredClientId.Recipient
                      }
                    />
                  </div>

                  <div className="p-col p-sm-12 p-md-5">
                    <Dropdown
                      value={fields[idx].klient_id}
                      onChange={(e) =>
                        onPaymentChange(idx, 'klient_id', e.value)
                      }
                      options={paymentCustomerOptions}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Step>
  );
}

export default Payments;
