import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import HeadlessStepperContext from '../HeadlessStepperContext';

export type HeaderProps = {
  title: string;
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  isStepIndicatorShown?: boolean;
};

function Header({
  title,
  isStepIndicatorShown = true,
  isExpanded,
  setIsExpanded,
}: HeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { totalSteps, stepIndex } = useContext(HeadlessStepperContext);

  return (
    <div className="stepper-header">
      <div>
        <span className="stepper-title">{title}</span>
        <span
          className={classNames('step-indicator', {
            'p-d-none': !isStepIndicatorShown,
          })}
        >
          {t('Step')} {stepIndex + 1} {t('of')} {totalSteps}
        </span>
      </div>

      <div>
        <Button
          type="button"
          icon={isExpanded ? 'fas fa-compress' : 'fas fa-expand'}
          tooltip={isExpanded ? t('Compress') : t('Expand')}
          tooltipOptions={{ position: isExpanded ? 'left' : 'right' }}
          onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
          className="p-button-rounded p-button-text p-button-plain"
        />
      </div>
    </div>
  );
}

export default Header;
