import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';

import { getOrderStatusConfig } from '../../../configs/orders';
import { OrderStatus } from '../../../enums/orders';
import { OrderCollection, OrderResource } from '../../../types/api/orders';
import { Numeric } from '../../../types/general';
import { Unpacked } from '../../../types/util';
import { emptyCell } from '../../../utils/constants/tables';
import { dateTimeCell, weightCell } from '../../../utils/helpers/dataTable';
import {
  currencyFormat,
  trailingEllipsisFormat,
} from '../../../utils/helpers/formatting';
import { contextMenuModel } from '../../../utils/helpers/primereact';

export type Status = {
  id: Numeric;
  name: string;
  total: Numeric;
};

export function getColumnHeadersMap(t: TFunction) {
  return {
    seriski_broj: t('Serial No.'),
    status_id: t('Status'),
    datum_kreiranje: t('Creation Date'),
    datum_priem: t('Reception Date'),
    datum_isporaka: t('Delivery Date'),
    reference1: t('Reference No. 1'),
    reference2: t('Reference No. 2'),
    klient_od_ime: t('Sender Name'),
    adresa_od: t('Sender Address'),
    klient_do_ime: t('Receiver Name'),
    adresa_do: t('Receiver Address'),
    kolicina: t('Amount'),
    tezina: t('Weight'),
    otkup: t('Redemption'),
    cena: t('Price'),
    komentar: t('Comment'),
  };
}

function getIcon(status_id: OrderStatus, status_name: string) {
  const color =
    getOrderStatusConfig()[status_id].color ?? 'var(--bluegray-200)';

  return (
    <span data-tip={status_name} data-for="tooltip-right">
      <FontAwesomeIcon icon={faCircle} style={{ color }} />
    </span>
  );
}

export function additionalColumnProperties(
  t: TFunction,
  column: keyof ReturnType<typeof getColumnHeadersMap> | 'actions',
  setContextMenuSelection: Dispatch<SetStateAction<any>>,
  setAction?: any,
  setIsPrintAddressBookOn?: any,
  setIsPrintStickerOn?: any,
  printActionOverlayPanel?: any
) {
  switch (column) {
    case 'seriski_broj':
      return {
        body: (row: OrderResource) => {
          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(row[column]!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'status_id':
      return {
        body: (row: OrderResource & { time_from_pickup: string }) => {
          const statusIcon = getIcon(parseInt(row[column]), row['status_name']);
          return <span>{statusIcon}</span>;
        },
        style: {
          width: 100,
          flex: '0 0 auto',
          justifyContent: 'center',
        },
        bodyStyle: {
          width: 100,
          flex: '0 0 auto',
          justifyContent: 'center',
        },
      };

    case 'reference1':
    case 'reference2':
      return {
        body: (row: OrderResource) => {
          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(row[column]!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },

        style: {
          width: 150,
        },
        bodyStyle: {
          width: 150,
        },
      };

    case 'datum_kreiranje':
    case 'datum_priem':
    case 'datum_isporaka':
      return {
        body: (row: OrderResource) => {
          const dateFormatted = dateTimeCell(row[column] ?? '').toString();

          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(dateFormatted!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },
        style: {
          width: 200,
        },
        bodyStyle: {
          width: 200,
        },
      };

    case 'klient_od_ime':
    case 'adresa_od':
    case 'klient_do_ime':
    case 'adresa_do':
      return {
        body: (row: OrderResource) => {
          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(row[column]!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },
        style: {
          width: 250,
        },
        bodyStyle: {
          width: 250,
        },
      };

    case 'kolicina':
      return {
        body: (row: OrderResource) => {
          return <span>{Intl.NumberFormat().format(Number(row[column]))}</span>;
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'tezina':
      return {
        body: (row: OrderResource) => {
          return weightCell(t, row[column]);
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'otkup':
      return {
        body: (row: OrderResource) => {
          return (
            <span>
              {currencyFormat(row[column] ?? '', { showCurrency: true })}
            </span>
          );
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'cena':
      return {
        body: (row: OrderResource) => {
          return (
            <span>
              {currencyFormat(row[column] ?? '', { showCurrency: true })}
            </span>
          );
        },
        style: {
          width: 140,
        },
        bodyStyle: {
          width: 140,
        },
      };

    case 'komentar':
      return {
        body: (row: OrderResource) => {
          return !!row[column] ? (
            <span data-tip={row[column]} data-for="tooltip-right">
              {trailingEllipsisFormat(row[column]!, 0, 27)}
            </span>
          ) : (
            emptyCell
          );
        },
        style: {
          width: 280,
        },
        bodyStyle: {
          width: 280,
        },
      };

    case 'actions':
      return {
        body: (row: OrderResource) => {
          return (
            <div>
              <Button
                type="button"
                icon="fas fa-file-alt"
                tooltip={t('View details')}
                tooltipOptions={{ position: 'top' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuSelection(row);
                  setAction('view-details');
                }}
                style={{ padding: 0 }}
                className="p-mr-2 p-button-text p-button-plain"
              />

              <Button
                type="button"
                icon="fas fa-edit"
                tooltip={t('Edit order')}
                tooltipOptions={{ position: 'top' }}
                disabled={parseInt(row.status_id) >= OrderStatus.Cancelled}
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuSelection(row);
                  setAction('edit');
                }}
                style={{ padding: 0 }}
                className="p-mr-2 p-button-text p-button-plain"
              />

              <Button
                type="button"
                icon="fas fa-search-location"
                tooltip={t('Status tracking')}
                tooltipOptions={{ position: 'top' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuSelection(row);
                  setAction('track');
                }}
                style={{ padding: 0 }}
                className="p-mr-2 p-button-text p-button-plain"
              />

              <Button
                type="button"
                icon="fas fa-print"
                tooltip={t('Print')}
                tooltipOptions={{ position: 'top' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuSelection(row);
                  printActionOverlayPanel.current.toggle(e);
                }}
                disabled={parseInt(row.status_id) >= OrderStatus.Cancelled}
                style={{ padding: 0 }}
                className="p-mr-2 p-button-text p-button-plain"
              />

              <OverlayPanel
                ref={printActionOverlayPanel}
                id="overlay_panel"
                className="overlaypanel-demo"
              >
                <Button
                  type="button"
                  icon="fas fa-address-book"
                  tooltip={t('Print address document')}
                  tooltipOptions={{ position: 'top' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAction('print-address');
                    setIsPrintAddressBookOn(true);
                  }}
                  disabled={parseInt(row.status_id) >= OrderStatus.Cancelled}
                  style={{ padding: 0 }}
                  className="p-mr-2 p-p-0 p-button-text p-button-plain"
                />

                <Button
                  type="button"
                  icon="fas fa-ticket-alt"
                  tooltip={t('Print sticker')}
                  tooltipOptions={{ position: 'top' }}
                  disabled={parseInt(row.status_id) >= OrderStatus.Cancelled}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAction('print-sticker');
                    setIsPrintStickerOn(true);
                  }}
                  style={{ padding: 0 }}
                  className="p-mr-2 p-button-text p-button-plain"
                />
              </OverlayPanel>

              <Button
                type="button"
                icon="fas fa-trash"
                tooltip={t('Cancel order')}
                disabled={parseInt(row.status_id) >= OrderStatus.Cancelled}
                tooltipOptions={{ position: 'left' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuSelection(row);
                  setAction('cancel');
                }}
                style={{ padding: 0 }}
                className="p-mr-2 p-button-text p-button-plain"
              />
            </div>
          );
        },
        style: {
          width: 220,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
        bodyStyle: {
          width: 220,
          borderLeft: '1px solid #e9ecef',
          flex: '0 1 auto',
          position: 'sticky',
        },
      };

    default:
      return {};
  }
}

export function getMobileColumnHeadersMap(t: TFunction) {
  return {
    seriski_broj: t('Serial No.'),
    status_name: t('Status'),
    dokument_broj: t('Document No.'),
    klient_od_ime: t('Sender'),
    klient_do_ime: t('Receiver'),
  };
}

export function mobileAdditionalColumnProperties(
  column: keyof ReturnType<typeof getMobileColumnHeadersMap>
) {
  switch (column) {
    case 'seriski_broj':
    case 'status_name':
    case 'dokument_broj':
    case 'klient_od_ime':
    case 'klient_do_ime':
      return {
        style: {
          width: 'auto',
        },
        bodyStyle: {
          width: 'auto',
        },
      };
    default:
      return {};
  }
}

export function generateContextMenu(
  t: TFunction,
  selection: Unpacked<OrderCollection> | undefined,
  handleCMViewOrderClick: () => void,
  handleCMStatusTrackingClick: () => void,
  handleCMPrintAddressDocClick: () => void,
  handleCMPrintStickersClick: () => void,
  handleCMPickupSpecificationClick: () => void,
  handleCMRecreateClick: () => void,
  handleCMEditClick: () => void,
  handleCMCancelClick: () => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>
) {
  const canShipmentBeEditedOrCancelled =
    !!selection && parseInt(selection?.status_id) < OrderStatus.Cancelled;

  const viewOrderOption = {
    label: t('View details'),
    icon: 'fas fa-file-alt',
    command: () => {
      setCaller('context-menu');
      handleCMViewOrderClick();
    },
  };

  const statusTrackingOption = {
    label: t('Status tracking'),
    icon: 'fas fa-search-location',
    command: () => {
      setCaller('context-menu');
      handleCMStatusTrackingClick();
    },
  };

  const createASimilarOrderOption = {
    label: t('Create a similar order'),
    icon: 'fas fa-plus-square',
    command: () => {
      setCaller('context-menu');
      handleCMRecreateClick();
    },
  };

  const editOption = {
    label: t('Edit'),
    icon: 'fas fa-edit',
    command: () => {
      setCaller('context-menu');
      handleCMEditClick();
    },
  };

  const deleteOption = {
    label: t('Cancel order'),
    icon: 'fas fa-trash',
    command: () => {
      setCaller('context-menu');
      handleCMCancelClick();
    },
  };

  const printSingleAdressOption = {
    label: t('Print address document'),
    icon: 'fas fa-address-book',
    command: () => {
      setCaller('context-menu');
      handleCMPrintAddressDocClick();
    },
  };

  const printSingleStickerOption = {
    label: t('Print sticker'),
    icon: 'fas fa-ticket-alt',
    command: () => {
      setCaller('context-menu');
      handleCMPrintStickersClick();
    },
  };

  const printSinglePickupSpecOption = {
    label: t('Print pickup specification'),
    icon: 'fas fa-ticket-alt',
    command: handleCMPickupSpecificationClick,
  };

  return contextMenuModel([
    [[true, viewOrderOption]],
    [[true, createASimilarOrderOption]],
    [[canShipmentBeEditedOrCancelled, editOption]],
    [[true, statusTrackingOption]],
    [
      [canShipmentBeEditedOrCancelled, printSingleAdressOption],
      [canShipmentBeEditedOrCancelled, printSingleStickerOption],
      [canShipmentBeEditedOrCancelled, printSinglePickupSpecOption],
    ],
    [[canShipmentBeEditedOrCancelled, deleteOption]],
  ]);
}

export function generateGroupActions(
  t: TFunction,
  selectionMultiple: OrderCollection['data'] | undefined,
  handleCMPrintAddressDocClick: () => void,
  handleCMPickupSpecificationClick: () => void,
  handleCMCancelClick: () => void,
  setCaller: Dispatch<SetStateAction<'context-menu' | 'group-actions'>>
) {
  const deletableShipments = selectionMultiple?.filter(
    (o) => parseInt(o.status_id) < OrderStatus.Cancelled
  );

  const printGroupAddressesOption = {
    label: t('Print addressess group'),
    icon: 'fas fa-address-book',
    command: () => {
      setCaller('group-actions');
      handleCMPrintAddressDocClick();
    },
    disabled: deletableShipments?.length !== selectionMultiple?.length,
  };

  const printGroupPickupSpecOption = {
    label: t('Print pickup specification'),
    icon: 'fas fa-clipboard-list',
    command: () => {
      setCaller('group-actions');
      handleCMPickupSpecificationClick();
    },
    disabled: deletableShipments?.length !== selectionMultiple?.length,
  };

  const groupDeleteOption = {
    label: t('Cancel group orders'),
    icon: 'fas fa-trash',
    command: () => {
      setCaller('group-actions');
      handleCMCancelClick();
    },
  };

  return contextMenuModel([
    [
      [true, printGroupAddressesOption],
      [true, printGroupPickupSpecOption],
    ],
    [[!!deletableShipments?.length, groupDeleteOption]],
  ]);
}
