import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LabelValue } from '../../../../../../../types/options';
import { ReduxState } from '../../../../../../../types/redux';
import { invalidDecimalPointCharactersRegex } from '../../../../../../../utils/constants/regex';
import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import {
  CustomerRole,
  FormFields,
  RedemptionFieldValidation,
  RedemptionReceiver,
  RedemptionReceiverFieldValidation,
  getRedemptionReceiverTooltip,
  getRedemptionTooltip,
  validateRedemptionField,
  validateRedemptionReceiverField,
} from '../../../CreateEditRecreate.functions';
import InsuredAmount from '../Fields/InsuredAmount';
import Quantity from '../Fields/Quantity';
import ReferenceNo1 from '../Fields/ReferenceNo1';
import ReferenceNo2 from '../Fields/ReferenceNo2';
import RelatedOrder from '../Fields/RelatedOrder';
import ReturnDocument from '../Fields/ReturnDocument';
import styles from './OrderTypes.module.scss';

function Package(): JSX.Element {
  const { t } = useTranslation();

  const clientId = useSelector<ReduxState, ReduxState['user']['client_id']>(
    (s) => s.user.client_id
  );

  const { setValue } = useFormContext<FormFields>();

  const klient_od_id = useWatch<FormFields, 'klient_od_id'>({
    name: 'klient_od_id',
  });

  const klient_od_ime = useWatch<FormFields, 'klient_od_ime'>({
    name: 'klient_od_ime',
  });

  const customer_role = useWatch<FormFields, 'customer_role'>({
    name: 'customer_role',
  });

  const vrednost = useWatch<FormFields, 'vrednost'>({
    name: 'vrednost',
  });

  const otkup = useWatch<FormFields, 'otkup'>({
    name: 'otkup',
  });

  const sirina = useWatch<FormFields, 'sirina'>({
    name: 'sirina',
  });

  const visina = useWatch<FormFields, 'visina'>({
    name: 'visina',
  });

  const dolzina = useWatch<FormFields, 'dolzina'>({
    name: 'dolzina',
  });

  const redemptionReceiverOptions = useMemo<
    LabelValue<RedemptionReceiver>[]
  >(() => {
    const senderSuffix =
      customer_role === CustomerRole.Sender
        ? ''
        : klient_od_ime
        ? `(${klient_od_ime})`
        : '';

    const ordererSuffix =
      customer_role === CustomerRole.Sender ? '' : t('(me)');

    // Trim, because both suffixes may end up being empty
    const senderLabel = (t('Sender') + ' ' + senderSuffix).trim();
    const ordererLabel = (t('Orderer') + ' ' + ordererSuffix).trim();

    return [
      { label: senderLabel, value: RedemptionReceiver.Sender },
      { label: ordererLabel, value: RedemptionReceiver.Orderer },
    ];
  }, [customer_role, klient_od_ime, t]);

  const redemptionFieldValidation = useMemo(
    () => validateRedemptionField(klient_od_id, vrednost),
    [klient_od_id, vrednost]
  );

  const redemptionTooltip = useMemo(
    () => getRedemptionTooltip(t, redemptionFieldValidation),
    [redemptionFieldValidation, t]
  );

  const isRedemptionDisabled =
    redemptionFieldValidation !== RedemptionFieldValidation.Valid;

  const redemptionReceiverFieldValidation = useMemo(
    () =>
      validateRedemptionReceiverField(klient_od_id, otkup, vrednost, clientId),
    [clientId, klient_od_id, otkup, vrednost]
  );

  const redemptionReceiverTooltip = useMemo(
    () => getRedemptionReceiverTooltip(t, redemptionReceiverFieldValidation),
    [redemptionReceiverFieldValidation, t]
  );

  const isRedemptionReceiverDisabled =
    redemptionReceiverFieldValidation !==
    RedemptionReceiverFieldValidation.Valid;

  const isAnyDimensionFilledOut =
    parseFloat(sirina) > 0 || parseFloat(visina) > 0 || parseFloat(dolzina) > 0;

  return (
    <div className={styles.fourColumnLayout}>
      <Tooltip
        target=".disabled-redemption-tooltip"
        position="left"
        style={isRedemptionDisabled ? {} : { display: 'none' }}
      />

      <Tooltip
        target=".disabled-redemption-receiver-tooltip"
        position="left"
        style={isRedemptionReceiverDisabled ? {} : { display: 'none' }}
      />

      <FieldWithErrors name="tezina" label={t('Weight')}>
        <Controller
          name="tezina"
          render={({ field }) => (
            <InputText
              id="tezina"
              name="tezina"
              value={field.value}
              onChange={(e) => {
                setValue(
                  'tezina',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
              className="data-cy-weight"
            />
          )}
        />
      </FieldWithErrors>

      <Quantity />
      <InsuredAmount />
      <ReturnDocument />

      <FieldWithErrors name="sirina" label={<>{t('Width')} (m)</>}>
        <Controller
          name="sirina"
          render={({ field }) => (
            <InputText
              id="sirina"
              name="sirina"
              value={field.value}
              onChange={(e) => {
                setValue(
                  'sirina',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="visina" label={<>{t('Height')} (m)</>}>
        <Controller
          name="visina"
          render={({ field }) => (
            <InputText
              id="visina"
              name="visina"
              value={field.value}
              onChange={(e) => {
                setValue(
                  'visina',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="dolzina" label={<>{t('Length')} (m)</>}>
        <Controller
          name="dolzina"
          render={({ field }) => (
            <InputText
              id="dolzina"
              name="dolzina"
              value={field.value}
              onChange={(e) => {
                setValue(
                  'dolzina',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="volumen"
        label={
          <>
            {t('Volume')} (m<sup>3</sup>)
          </>
        }
      >
        <Controller
          name="volumen"
          render={({ field }) => (
            <InputText
              id="volumen"
              name="volumen"
              value={field.value}
              disabled={isAnyDimensionFilledOut}
              onChange={(e) => {
                setValue(
                  'volumen',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
              className="data-cy-volume"
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="otkup" label={t('Redemption')}>
        <Controller
          name="otkup"
          render={({ field }) => (
            <div
              className="disabled-redemption-tooltip"
              data-pr-tooltip={redemptionTooltip}
            >
              <InputText
                id="otkup"
                name="otkup"
                value={field.value}
                disabled={isRedemptionDisabled}
                onChange={(e) => {
                  setValue(
                    'otkup',
                    e.target.value.replace(
                      invalidDecimalPointCharactersRegex,
                      ''
                    )
                  );
                }}
                className="data-cy-redemption"
              />
            </div>
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="klient_otkup_id" label={t('Redemption receiver')}>
        <Controller
          name="klient_otkup_id"
          render={({ field }) => (
            <div
              className="disabled-redemption-receiver-tooltip"
              data-pr-tooltip={redemptionReceiverTooltip}
            >
              <Dropdown
                name="klient_otkup_id"
                inputId="klient_otkup_id"
                options={redemptionReceiverOptions}
                disabled={isRedemptionReceiverDisabled}
                placeholder={t('Not applicable')}
                value={
                  isRedemptionReceiverDisabled
                    ? redemptionReceiverFieldValidation ===
                      RedemptionReceiverFieldValidation.SameSenderAndOrderer
                      ? RedemptionReceiver.Sender
                      : null
                    : field.value
                }
                onChange={(e) => field.onChange(e.value)}
              />
            </div>
          )}
        />
      </FieldWithErrors>

      <ReferenceNo1 />
      <ReferenceNo2 />
      <RelatedOrder />
    </div>
  );
}

export default Package;
