import classNames from 'classnames';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useSelector } from 'react-redux';

import { MenuContext } from './context/MenuContext';
import SideBarWrapper from './features/Menu/customer-react/src/features/Menu/SideBar/SideBarWrapper';
import Routes from './features/Routes/Routes';
import useMediaQuery from './hooks/useMediaQuery';
import { ReduxState } from './types/redux';

type Props = {
  routerLocation: string | null;
  setRouterLocation: Dispatch<SetStateAction<string | null>>;
};

function MainSection({ routerLocation, setRouterLocation }: Props) {
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const { isMenuExpanded } = useContext(MenuContext);

  const isUserLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['isLoggedIn']
  >((state) => state.user.isLoggedIn);

  const routesClassName = classNames('content-wrapper', {
    'public-route': routerLocation?.includes('/track/') && !isUserLoggedIn,
  });

  return isUserLoggedIn ? (
    <div style={{ display: 'flex', width: '100%' }}>
      <SideBarWrapper />
      <div
        style={{
          width: isMenuExpanded ? 'calc(100% - 20rem)' : 'calc(100% - 5rem)',
          left: isMenuExpanded ? '20rem' : '5rem',
          position: !isOnMobile ? 'absolute' : 'static',
        }}
      >
        <div className={routesClassName}>
          <Routes setRouterLocation={setRouterLocation} />
        </div>
      </div>
    </div>
  ) : (
    <div className={routesClassName}>
      <Routes setRouterLocation={setRouterLocation} />{' '}
    </div>
  );
}

export default MainSection;
