import Favicon from 'react-favicon';

import citypostIco from '../../assets/img/favicons/citypost.ico';
import ecologisticIco from '../../assets/img/favicons/ecologistic.ico';
import grabitIco from '../../assets/img/favicons/grabit.ico';
import kolporterIco from '../../assets/img/favicons/kolporter.ico';
import logistarIco from '../../assets/img/favicons/logistar.ico';
import novapostaIco from '../../assets/img/favicons/novaposta.ico';
import postexpressIco from '../../assets/img/favicons/postexpress.ico';

const favicons = {
  grabit: grabitIco,
  novaposta: novapostaIco,
  ecologistic: ecologisticIco,
  citypost: citypostIco,
  postexpress: postexpressIco,
  kolporter: kolporterIco,
  logistar: logistarIco,
};

function FaviconLoader(): JSX.Element {
  const clientName =
    (process.env.REACT_APP_CLIENT_NAME as keyof typeof favicons) ?? 'grabit';

  return <Favicon url={favicons[clientName] || grabitIco} />;
}

export default FaviconLoader;
