import { RefObject, useEffect, useState } from 'react';

function useTableScrollHeight(ref: RefObject<any>): number {
  const [scrollHeight, setScrollHeight] = useState(500);

  useEffect(() => {
    function handleResize(): void {
      if (!ref?.current?.el) {
        return;
      }

      const additionalHeightEls = Array.from<HTMLElement>(
        ref.current.el.querySelectorAll(
          '.p-datatable-header, .p-datatable-scrollable-header, .p-paginator-bottom, .p-datatable-footer'
        )
      );

      const addition = additionalHeightEls.reduce(
        (acc, currentVal) => acc + currentVal.offsetHeight,
        0
      );

      // 36 = padding-bottom of .content-wrapper
      // We take the screen's width into account as well,
      //  beacause mobile devices should be handled gracefully
      setScrollHeight(
        window.innerHeight >= 576 &&
          (window.innerWidth >= 768 ||
            window.innerHeight - ref.current.el.offsetTop > 450)
          ? window.innerHeight - ref.current.el.offsetTop - 36 - addition
          : 768
      );
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, [ref]);

  return scrollHeight;
}

export default useTableScrollHeight;
