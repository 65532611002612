import './ShipmentTracking.scss';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { OrderStatus } from '../../enums/orders';
import useMediaQuery from '../../hooks/useMediaQuery';
import { OrderResource, Payment } from '../../types/api/orders';
import { currencyFormat } from '../../utils/helpers/formatting';
import EmptyMessage from '../DataTable/EmptyMessage/EmptyMessage';
import { StatusApiData, StatusTrakingInfo } from './ShipmentTracking.functions';

type Props = {
  trackData: StatusApiData | undefined;
  shipmentData: OrderResource | undefined;
  serialNo: string;
  isDataLoading: boolean;
  isUrlPublic: boolean;
  setShowGoBackBtn: Dispatch<SetStateAction<boolean>>;
  returnDoc: string | null;
  originReturnDoc: string | null;
  returnShipment: string | null;
  originReturnShipment: string | null;
};

function ShipmentTracking({
  trackData,
  shipmentData,
  serialNo,
  isDataLoading,
  isUrlPublic,
  setShowGoBackBtn,
  returnDoc,
  originReturnDoc,
  returnShipment,
  originReturnShipment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  const shipmentInfoClassName = classNames('p-d-flex', {
    'p-flex-row  p-mb-2': !isOnMobile,
    'p-flex-column  p-mb-3': isOnMobile,
  });

  const relatedShipmentInfoClassName = classNames('p-d-flex', {
    'p-flex-row  p-mb-2 p-ai-center': !isOnMobile,
    'p-flex-column  p-mb-3': isOnMobile,
  });

  const progressBarValue = useMemo(() => {
    const currentOrderStatus = trackData?.[serialNo]?.current?.code;

    if (currentOrderStatus) {
      if (currentOrderStatus === OrderStatus.Cancelled) {
        return 0;
      } else if (currentOrderStatus < 21) {
        return 25;
      } else if (
        currentOrderStatus > OrderStatus.CreatedGroupOrder &&
        currentOrderStatus < OrderStatus.HandedOverToARelocationCourier
      ) {
        return 50;
      } else if (
        currentOrderStatus > 49 &&
        currentOrderStatus < OrderStatus.Delivered
      ) {
        return 75;
      } else if (currentOrderStatus > 79) {
        return 100;
      }
    }

    return -1;
  }, [trackData, serialNo]);

  const paymentInfo = useMemo<JSX.Element[]>(
    () =>
      shipmentData?.payments?.map((p: Payment, index: number) => {
        return (
          <div key={index}>
            <span>{p.opis}: </span>

            <span style={{ float: 'right', marginLeft: '14px' }}>
              {currencyFormat(p.iznos ?? '', {
                showCurrency: true,
              })}
            </span>

            <br />
          </div>
        );
      }) ?? [],
    [shipmentData?.payments]
  );

  const rows = useMemo(() => {
    if (trackData) {
      return trackData?.[serialNo]?.history ?? [];
    }

    return [];
  }, [serialNo, trackData]);

  return (
    <div className="shipment-tracking">
      {isDataLoading ? (
        <div className="p-mt-3">
          <Skeleton width="100%" height="50px" className="p-mb-3" />
          <Skeleton width="50%" height="100px" className="p-mb-3" />
          <Skeleton width="100%" height="150px" className="p-mb-3" />
        </div>
      ) : rows.length === 0 ? (
        <EmptyMessage
          text={t('This order does not have any status data yet')}
        />
      ) : (
        <>
          <p className="subtitle p-my-4">
            {t('Order {{serialNo}}', {
              serialNo: serialNo ?? '',
            })}
          </p>

          <h4>{t('Status')}</h4>

          <div className="p-my-5">
            <ProgressBar value={progressBarValue} showValue={false} />

            {isOnMobile ? (
              <div className="p-mt-2 p-text-center">
                {progressBarValue === 0
                  ? t('Cancelled.SHE')
                  : progressBarValue === 25
                  ? t('Created')
                  : progressBarValue === 50
                  ? t('Preparing')
                  : progressBarValue === 75
                  ? t('Shipped')
                  : t('Delivered')}
              </div>
            ) : progressBarValue > 0 ? (
              <div className="p-grid p-justify-end p-align-end">
                <span
                  className="p-col p-text-right subtitle"
                  style={{ fontWeight: progressBarValue === 25 ? 600 : 300 }}
                >
                  {t('Created')}
                </span>
                <span
                  className="p-col p-text-right subtitle"
                  style={{ fontWeight: progressBarValue === 50 ? 600 : 300 }}
                >
                  {t('Preparing')}
                </span>
                <span
                  className="p-col p-text-right subtitle"
                  style={{ fontWeight: progressBarValue === 75 ? 600 : 300 }}
                >
                  {t('Shipped')}
                </span>
                <span
                  className="p-col p-text-right subtitle"
                  style={{ fontWeight: progressBarValue === 100 ? 600 : 300 }}
                >
                  {t('Delivered')}
                </span>
              </div>
            ) : (
              <div className="p-grid">
                <span className="p-col subtitle" style={{ fontWeight: 600 }}>
                  {t('Cancelled.SHE')}
                </span>
              </div>
            )}
          </div>

          <h4>{t('Order Summary')}</h4>

          <div className="p-mb-5">
            <div className={shipmentInfoClassName}>
              <span className="info-header">{t('Created')}</span>
              <div>
                <span className="p-mb-2 subtitle">
                  {dayjs(trackData?.[serialNo]?.history[0]?.date).format(
                    'DD MMM YYYY, HH:mm:ss'
                  ) ?? '-'}
                </span>
              </div>
            </div>

            <div className={shipmentInfoClassName}>
              <span className="info-header">{t('Sender Info')}</span>
              <div>
                <span className="p-mb-2 subtitle">
                  {shipmentData?.klient_od_ime}
                </span>
                <br />
                <span className="subtitle">{shipmentData?.adresa_od}</span>
              </div>
            </div>

            <div className={shipmentInfoClassName}>
              <span className="info-header">{t('Recipient Info')}</span>
              <div>
                <span className="subtitle">{shipmentData?.klient_do_ime}</span>
                <br />
                <span className="subtitle">{shipmentData?.adresa_do}</span>
              </div>
            </div>

            {shipmentData?.payments && (
              <div className={shipmentInfoClassName}>
                <span className="info-header">{t('Payments')}</span>
                <div>
                  <span className="subtitle">{paymentInfo}</span>
                  <br />
                  <span className="subtitle">{t('Total')}</span>
                  <span className="subtitle" style={{ float: 'right' }}>
                    <b>
                      {currencyFormat(shipmentData?.cena ?? '', {
                        showCurrency: true,
                      })}
                    </b>
                  </span>
                </div>
              </div>
            )}

            {returnDoc && (
              <div className={relatedShipmentInfoClassName}>
                <span className="info-header">{t('Return Document')}</span>
                <div>
                  <Button
                    label={returnDoc}
                    onClick={() => {
                      history.push(
                        isUrlPublic
                          ? `/track/${returnDoc}`
                          : `/orders/${returnDoc}/track`
                      );

                      setShowGoBackBtn(true);
                    }}
                    className="p-button-link"
                  />
                </div>
              </div>
            )}

            {originReturnDoc && (
              <div className={relatedShipmentInfoClassName}>
                <span className="info-header">
                  {t('Origin order of the return document')}
                </span>
                <div>
                  <Button
                    label={originReturnDoc}
                    onClick={() => {
                      history.push(
                        isUrlPublic
                          ? `/track/${originReturnDoc}`
                          : `/orders/${originReturnDoc}/track`
                      );

                      setShowGoBackBtn(true);
                    }}
                    className="p-button-link"
                  />
                </div>
              </div>
            )}

            {returnShipment && (
              <div className={relatedShipmentInfoClassName}>
                <span className="info-header">{t('Return Order')}</span>
                <div>
                  <Button
                    label={returnShipment}
                    onClick={() => {
                      history.push(
                        isUrlPublic
                          ? `/track/${returnShipment}`
                          : `/orders/${returnShipment}/track`
                      );

                      setShowGoBackBtn(true);
                    }}
                    className="p-button-link"
                  />
                </div>
              </div>
            )}

            {originReturnShipment && (
              <div className={relatedShipmentInfoClassName}>
                <span className="info-header">
                  {t('Origin order of the returned order')}
                </span>
                <div>
                  <Button
                    label={originReturnShipment}
                    onClick={() => {
                      history.push(
                        isUrlPublic
                          ? `/track/${originReturnShipment}`
                          : `/orders/${originReturnShipment}/track`
                      );

                      setShowGoBackBtn(true);
                    }}
                    className="p-button-link"
                  />
                </div>
              </div>
            )}
          </div>

          <h4>{t('Detailed Status Info')}</h4>

          <DataTable value={rows} className="datatable-responsive" scrollable>
            <Column
              field="date"
              header={t('Date')}
              body={(row: StatusTrakingInfo) => (
                <div className="p-d-flex">
                  <span className="p-column-title">{t('Date')}</span>
                  <span className="p-column-value">
                    {dayjs(row.date).format('DD MMM YYYY, HH:mm:ss')}
                  </span>
                </div>
              )}
            />
            <Column
              field="text"
              header={t('Status')}
              body={(row: StatusTrakingInfo) => (
                <>
                  <span className="p-column-title">{t('Status')}</span>
                  <span className="p-column-value">{row.text}</span>
                </>
              )}
            />
            <Column
              field="location"
              header={t('Location')}
              body={(row: StatusTrakingInfo) => (
                <>
                  <span className="p-column-title">{t('Location')}</span>
                  <span className="p-column-value">{row.location}</span>
                </>
              )}
            />
          </DataTable>
        </>
      )}
    </div>
  );
}

export default ShipmentTracking;
