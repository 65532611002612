import './Tracking.scss';

import { AxiosRequestConfig } from 'axios';
import { Button } from 'primereact/button';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import useAxios from '../../../hooks/useAxios';
import usePrevious from '../../../hooks/usePrevious';
import { OrderResource } from '../../../types/api/orders';
import { ReduxState } from '../../../types/redux';
import { errorToast } from '../../../utils/helpers/primereact';
import LanguageSelector from '../../Header/LanguageSelector';
import ShipmentTracking from '../../ShipmentTracking/ShipmentTracking';
import {
  RouteParams,
  StatusApiData,
} from '../../ShipmentTracking/ShipmentTracking.functions';

function Tracking(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const { serialNo } = useParams<RouteParams>();

  const { toastRef } = useContext(ToastContext);

  const [showGoBackBtn, setShowGoBackBtn] = useState<boolean>(false);

  const clientLoggedIn = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user
  );

  const requestStatusConfig = useMemo(() => {
    let config: AxiosRequestConfig = {
      url: '/orders/status',
      data: { serialnumber: [serialNo] },
      method: 'POST',
    };
    if (!clientLoggedIn || !clientLoggedIn.isLoggedIn) {
      config['headers'] = { 'X-Client-Id': process.env.REACT_APP_CLIENT_ID };
    }

    return config;
  }, [clientLoggedIn, serialNo]);

  const {
    data: orderStatusTrackingData,
    error: orderStatusTrackingError,
    isLoading: isOrderStatusTrackingLoading,
  } = useAxios<StatusApiData>(requestStatusConfig, { skipWhen: !serialNo });

  const orderStatusTrackingErrorPrev = usePrevious(orderStatusTrackingError);

  useEffect(() => {
    if (
      !orderStatusTrackingError ||
      orderStatusTrackingError === orderStatusTrackingErrorPrev
    ) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading status data.')
      );
    }
  }, [orderStatusTrackingError, orderStatusTrackingErrorPrev, t, toastRef]);

  const requestShipmentInfoConfig = useMemo(() => {
    let config: AxiosRequestConfig = {
      url: `/orders/${orderStatusTrackingData?.[serialNo]?.identifier}`,
    };

    if (!clientLoggedIn || !clientLoggedIn.isLoggedIn) {
      config['headers'] = { 'X-Client-Id': process.env.REACT_APP_CLIENT_ID };
    }

    return config;
  }, [clientLoggedIn, orderStatusTrackingData, serialNo]);

  const {
    data: shipmentData,
    error: shipmentError,
    isLoading: isShipmentLoading,
    reload: reloadShipmentData,
  } = useAxios<OrderResource>(requestShipmentInfoConfig, {
    skipWhen: true,
  });

  const shipmentErrorPrev = usePrevious(shipmentError);

  useEffect(() => {
    if (!shipmentError || shipmentError === shipmentErrorPrev) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading order data.')
      );
    }
  }, [shipmentError, shipmentErrorPrev, t, toastRef]);

  const requestReturnDocConfig = useMemo(() => {
    let config: AxiosRequestConfig = {
      url: `/orders/${shipmentData?.return_document_id}`,
    };

    if (!clientLoggedIn || !clientLoggedIn.isLoggedIn) {
      config['headers'] = { 'X-Client-Id': process.env.REACT_APP_CLIENT_ID };
    }

    return config;
  }, [clientLoggedIn, shipmentData?.return_document_id]);

  const { data: returnDocData, reload: reloadReturnDocData } =
    useAxios<OrderResource>(requestReturnDocConfig, {
      skipWhen: true,
    });

  const requestOriginReturnDocConfig = useMemo(() => {
    let config: AxiosRequestConfig = {
      url: `/orders/${shipmentData?.origin_return_document_id}`,
    };

    if (!clientLoggedIn || !clientLoggedIn.isLoggedIn) {
      config['headers'] = { 'X-Client-Id': process.env.REACT_APP_CLIENT_ID };
    }

    return config;
  }, [clientLoggedIn, shipmentData?.origin_return_document_id]);

  const { data: originReturnDocData, reload: reloadOriginReturnDocData } =
    useAxios<OrderResource>(requestOriginReturnDocConfig, {
      skipWhen: true,
    });

  const requestReturnShipmentConfig = useMemo(() => {
    let config: AxiosRequestConfig = {
      url: `/orders/${shipmentData?.return_shipment_id}`,
    };

    if (!clientLoggedIn || !clientLoggedIn.isLoggedIn) {
      config['headers'] = { 'X-Client-Id': process.env.REACT_APP_CLIENT_ID };
    }

    return config;
  }, [clientLoggedIn, shipmentData?.return_shipment_id]);

  const { data: returnShipmentData, reload: reloadReturnShipmentData } =
    useAxios<OrderResource>(requestReturnShipmentConfig, {
      skipWhen: true,
    });

  const requestOriginReturnShipmentConfig = useMemo(() => {
    let config: AxiosRequestConfig = {
      url: `/orders/${shipmentData?.origin_return_shipment_id}`,
    };

    if (!clientLoggedIn || !clientLoggedIn.isLoggedIn) {
      config['headers'] = { 'X-Client-Id': process.env.REACT_APP_CLIENT_ID };
    }

    return config;
  }, [clientLoggedIn, shipmentData?.origin_return_shipment_id]);

  const {
    data: originReturnShipmentData,
    reload: reloadOriginReturnShipmentData,
  } = useAxios<OrderResource>(requestOriginReturnShipmentConfig, {
    skipWhen: true,
  });

  useEffect(() => {
    if (orderStatusTrackingData?.[serialNo]?.identifier) {
      reloadShipmentData();
    }
  }, [orderStatusTrackingData, reloadShipmentData, serialNo]);

  useEffect(() => {
    if (shipmentData?.return_document_id) {
      reloadReturnDocData();
    }
  }, [reloadReturnDocData, shipmentData]);

  useEffect(() => {
    if (shipmentData?.origin_return_document_id) {
      reloadOriginReturnDocData();
    }
  }, [reloadOriginReturnDocData, shipmentData]);

  useEffect(() => {
    if (shipmentData?.return_shipment_id) {
      reloadReturnShipmentData();
    }
  }, [reloadReturnShipmentData, shipmentData]);

  useEffect(() => {
    if (shipmentData?.origin_return_shipment_id) {
      reloadOriginReturnShipmentData();
    }
  }, [reloadOriginReturnShipmentData, shipmentData]);

  const returnDocumentSerialNo = useMemo(() => {
    return shipmentData?.return_document_id && returnDocData
      ? returnDocData.seriski_broj
      : null;
  }, [returnDocData, shipmentData?.return_document_id]);

  const originReturnDocumentSerialNo = useMemo(() => {
    return shipmentData?.origin_return_document_id && originReturnDocData
      ? originReturnDocData.seriski_broj
      : null;
  }, [originReturnDocData, shipmentData?.origin_return_document_id]);

  const returnShipmentSerialNo = useMemo(() => {
    return shipmentData?.return_shipment_id && returnShipmentData
      ? returnShipmentData.seriski_broj
      : null;
  }, [returnShipmentData, shipmentData?.return_shipment_id]);

  const originReturnShipmentSerialNo = useMemo(() => {
    return shipmentData?.origin_return_shipment_id && originReturnShipmentData
      ? originReturnShipmentData.seriski_broj
      : null;
  }, [originReturnShipmentData, shipmentData?.origin_return_shipment_id]);

  return (
    <div className="view-status-page">
      {!clientLoggedIn.isLoggedIn && (
        <div style={{ float: 'right' }}>
          <LanguageSelector />
        </div>
      )}

      {showGoBackBtn && (
        <Button
          type="button"
          icon="fas fa-chevron-left"
          label={t('Go Back')}
          onClick={() => {
            history.goBack();
            setShowGoBackBtn(false);
          }}
          className="p-button-secondary p-button-text"
        />
      )}

      <h1 className="title">{t('Order Tracking')}</h1>

      <ShipmentTracking
        trackData={orderStatusTrackingData}
        shipmentData={shipmentData}
        serialNo={serialNo ?? ''}
        isDataLoading={isOrderStatusTrackingLoading || isShipmentLoading}
        isUrlPublic={true}
        setShowGoBackBtn={setShowGoBackBtn}
        returnDoc={returnDocumentSerialNo}
        originReturnDoc={originReturnDocumentSerialNo}
        returnShipment={returnShipmentSerialNo}
        originReturnShipment={originReturnShipmentSerialNo}
      />
    </div>
  );
}

export default Tracking;
