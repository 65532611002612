import './BulkOrders.scss';

import { Button } from 'primereact/button';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import usePageTitle from '../../../hooks/usePageTitle';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { ImportListCollection } from '../../../types/api/importlists';
import { Unpacked } from '../../../types/util';
import {
  downloadAddressBook,
  downloadFile,
  downloadSticker,
  getFileName,
} from '../../../utils/helpers/files';
import { queryString } from '../../../utils/helpers/http';
import Table from '../../DataTable/Table/Table';
import {
  additionalColumnProperties,
  generateContextMenu,
  getColumnHeadersMap,
} from './BulkOrders.functions';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import ImportLogDialog from './Dialogs/View/ImportLog/ImportLogDialog';
import ShipmentsDialog from './Dialogs/View/Shipments/ShipmentsDialog';
import useTableFilters from './useTableFilters';

function BulkOrders(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  usePageTitle(t('Batch Orders'));

  const { bottomRightToastRef } = useContext(ToastContext);

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Unpacked<ImportListCollection>>();

  const {
    headerFilters,
    headerFiltersForm,
    headerFiltersCount,
    resetAllFilters,
    httpFiltersObj,
  } = useTableFilters(page, setPage!, limit);

  const { columns, columnOptions, selectedColumns, setSelectedColumns } =
    useTableColumns(
      page,
      limit,
      columnHeadersMap,
      columnHeadersMap,
      additionalColumnProperties
    );

  const canLoadData =
    httpFiltersObj.created_date_from && httpFiltersObj.created_date_to;

  const { data, isLoading, reload, error } = useAxios<ImportListCollection>(
    '/importlists' + queryString(httpFiltersObj),
    {
      skipWhen: !canLoadData,
    }
  );

  const dataWithNumericParcelsCount = useMemo(
    () =>
      data
        ? {
            ...data,
            data: data.data.map((r) => ({
              ...r,
              parcels_count: parseInt(r.parcels_count),
            })),
          }
        : data,
    [data]
  );

  function handleCmViewLogClick() {
    if (!selection) {
      return;
    }

    showImportLogDialog();
  }

  function handleCmViewShipmentsClick() {
    if (!selection) {
      return;
    }

    showShipmentsDialog();
  }

  function handleFileImportClick() {
    history.push('/orders/batch/file-import');
  }

  function handleWebImportClick() {
    history.push('/orders/batch/web-import');
  }

  const { id: bulkOrderID } = useParams<{ id?: string }>();

  const {
    show: showImportLogDialog,
    hide: hideImportLogDialog,
    isVisible: isImportLogDialogVisible,
  } = useRouteDialog('/orders/batch', `${bulkOrderID ?? selection?.id}/log`);

  const {
    show: showShipmentsDialog,
    hide: hideShipmentsDialog,
    isVisible: isShipmentsDialogVisible,
  } = useRouteDialog('/orders/batch', `${bulkOrderID ?? selection?.id}/orders`);

  function handleCmExportToCsv() {
    if (!selection) {
      return;
    }

    downloadFile(
      `/importlists/${selection?.id}`,
      getFileName(t('BatchOrders'), selection.name),
      FileTypes.CSV,
      bottomRightToastRef,
      (res: { content: string }) => res.content
    );
  }

  function handleCmPrintAddressBookClick() {
    if (!selection) {
      return;
    }

    const queryParams = {
      list_id: selection ? parseInt(selection.id) : undefined,
    };

    downloadAddressBook(
      queryParams,
      getFileName(t('BatchOrders'), [selection.name, t('AddressDocument')]),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  function handleCmPrintStickersClick() {
    if (!selection) {
      return;
    }

    downloadSticker(
      {
        list_id: selection.id,
      },
      getFileName(t('BatchOrders'), [selection.name, t('Sticker')]),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  function handleCmPrintSpecificationClick() {
    if (!selection) {
      return;
    }

    downloadFile(
      `${process.env.REACT_APP_REPORT_URL}/specification/pdf?list_id=${selection.id}`,
      getFileName(t('BatchOrders'), [selection.name, t('Specification')]),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  return (
    <div className="bulk-orders">
      <h1 className="title">{t('Batch Orders')}</h1>
      <p className="subtitle">{t('View and manage batch orders')}</p>

      <Button
        type="button"
        label={t('Web Import')}
        icon="fas fa-globe"
        className="p-mr-2 main-btn"
        onClick={() => handleWebImportClick()}
      />

      <Button
        type="button"
        label={t('File Import')}
        icon="fas fa-file-import"
        className="main-btn p-button-outlined"
        onClick={() => handleFileImportClick()}
      />

      <ImportLogDialog
        id={bulkOrderID ?? selection?.id}
        name={selection?.name}
        visible={isImportLogDialogVisible}
        onHide={hideImportLogDialog}
        showShipmentsDialog={showShipmentsDialog}
      />

      <ShipmentsDialog
        id={bulkOrderID ?? selection?.id}
        name={selection?.name}
        visible={isShipmentsDialogVisible}
        onHide={hideShipmentsDialog}
        showImportLogDialog={showImportLogDialog}
      />

      <DeleteDialog
        selectedList={selection}
        isShown={isDeleteDialogVisible}
        onHide={() => setIsDeleteDialogVisible(false)}
        reloadImportedLists={reload}
      />

      <Table
        columnOptions={columnOptions}
        columns={columns}
        contextMenuModel={generateContextMenu(
          t,
          selection,
          handleCmViewLogClick,
          handleCmViewShipmentsClick,
          handleCmPrintAddressBookClick,
          handleCmPrintStickersClick,
          handleCmPrintSpecificationClick,
          handleCmExportToCsv,
          () => setIsDeleteDialogVisible(true)
        )}
        data={dataWithNumericParcelsCount}
        hasError={!!error}
        headerFilters={headerFilters}
        headerFiltersCount={headerFiltersCount}
        headerFiltersForm={headerFiltersForm}
        isLoading={isLoading}
        onHeaderFiltersResetAllBtnClick={resetAllFilters}
        onRowDoubleClick={() => handleCmViewShipmentsClick()}
        rebuildTooltip
        ref={tableRef}
        reload={reload}
        isReloadDisabled={!canLoadData}
        rows={limit}
        selectedColumns={selectedColumns}
        selection={selection}
        setLimit={setLimit}
        setPage={setPage}
        setSelectedColumns={setSelectedColumns}
        setSelection={setSelection}
        setSortField={setSortField}
        setSortOrder={setSortOrder}
        sortField={sortField}
        sortOrder={sortOrder}
        storageString="bulkOrder_dataTable"
        displayActionColumn
      />
    </div>
  );
}

export default BulkOrders;
