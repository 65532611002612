import { TFunction } from 'react-i18next';

import { noColumnStyle } from '../../../../../../configs/primereact';
import { dateTimeCell } from '../../../../../../utils/helpers/dataTable';

export type ImportLog = {
  logs: {
    datum: string;
    klient_id: string;
    poraka_tip: string;
    poraka: string;
  }[];
  import_result: string;
  deleted: number;
};

export type SingleBulkOrder = {
  id: string;
  name: string;
  barcode: string;
  client_name: string;
};

export function getColumHeadersMap(
  t: TFunction
): Record<keyof Pick<ImportLog['logs'][0], 'datum' | 'poraka'> | 'no', string> {
  return {
    no: t('No.'),
    datum: t('Date'),
    poraka: t('Message'),
  };
}

export function additionalColumnProperties(column: string) {
  switch (column) {
    case 'no':
      return noColumnStyle;

    case 'datum':
      return {
        body: (row: any) => dateTimeCell(row[column]),
        style: {
          width: 180,
        },
        bodyStyle: {
          width: 180,
        },
      };

    case 'poraka':
      return {
        style: {
          width: 'auto',
          minWidth: 350,
        },
        bodyStyle: {
          width: 'auto',
          minWidth: 350,
        },
      };

    default:
      return {};
  }
}
