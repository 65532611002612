import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ToastContext from '../../../../../context/ToastContext';
import useAxios from '../../../../../hooks/useAxios';
import usePrevious from '../../../../../hooks/usePrevious';
import { ClientResource } from '../../../../../types/api/clients';
import { ImportListTypeResource } from '../../../../../types/api/importlisttypes';
import { PlaceResource } from '../../../../../types/api/places';
import { ReduxState } from '../../../../../types/redux';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import Step from '../../../../Stepper/Formik/Step';
import Stepper from '../../../../Stepper/Formik/Stepper';
import ImportResult from '../ImportResult';
import {
  FormValues,
  getInitialValues,
  getValidationSchemas,
} from './FileImporter.functions';
import AddressInfo from './Steps/AddressInfo';
import FileImport from './Steps/FileImport/FileImport';
import ListType from './Steps/ListType';
import Payments from './Steps/Payments/Payments';

function FileImporter(): JSX.Element {
  const { t } = useTranslation();

  const {
    data: listTypeData,
    reload: listTypeReload,
    isLoading: isListTypeLoading,
  } = useAxios<ImportListTypeResource>();

  const [hasSucceeded, setHasSucceeded] = useState<boolean>(false);
  const [hasFailed, setHasFailed] = useState<boolean>(false);

  const validationSchemas = useMemo(() => getValidationSchemas(t), [t]);

  const userLoggedIn = useSelector<ReduxState, ReduxState['user']['user_id']>(
    (state) => state.user.user_id
  );

  const clientLoggedIn = useSelector<
    ReduxState,
    ReduxState['user']['client_id']
  >((state) => state.user.client_id);

  const { toastRef } = useContext(ToastContext);

  const { data: clientData } = useAxios<ClientResource>(
    {
      url: `/clients/${clientLoggedIn}`,
    },
    { skipWhen: !clientLoggedIn }
  );

  const { data: placeData } = useAxios<PlaceResource>(
    `/places/${clientData?.mesto_id}`,
    { skipWhen: !clientData?.mesto_id }
  );

  const {
    data: bulkOrderData,
    error: bulkOrderError,
    isLoading: isBulkOrderLoading,
    reload: bulkOrderReload,
  } = useAxios<{ generalMsg?: string }, { generalMsg?: string }>();

  const bulkOrderDataPrevious = usePrevious(bulkOrderData);
  const bulkOrderErrorPrevious = usePrevious(bulkOrderError);

  useEffect(() => {
    if (!bulkOrderData || bulkOrderData === bulkOrderDataPrevious) {
      return;
    }

    setHasSucceeded(true);
    setHasFailed(false);
  }, [bulkOrderData, bulkOrderDataPrevious, t, toastRef]);

  useEffect(() => {
    if (!bulkOrderError || bulkOrderError === bulkOrderErrorPrevious) {
      return;
    }

    setHasFailed(true);
    setHasSucceeded(false);
  }, [bulkOrderErrorPrevious, t, toastRef, bulkOrderError]);

  return isBulkOrderLoading ? (
    <DialogSpinner />
  ) : hasSucceeded ? (
    <ImportResult
      onImportAgainBtnClick={() => {
        setHasSucceeded(false);
      }}
    />
  ) : hasFailed ? (
    <ImportResult
      hasFailed
      subtitle={bulkOrderError?.response?.data.generalMsg}
      onImportAgainBtnClick={() => {
        setHasFailed(false);
      }}
    />
  ) : (
    <Stepper
      title={t('File Import')}
      customSubmitButton={{ label: t('Import') }}
      goToRoute="/orders/batch"
      initialValues={getInitialValues(clientData, placeData, listTypeData)}
      enableReinitialize
      onSubmit={(values) => {
        bulkOrderReload({
          url: '/orders/import',
          method: 'POST',
          data: {
            user_id: String(userLoggedIn),
            listtype: values.list_type.name,
            place: values.place,
            street: values.street,
            broj: values.house_number,
            vlez: values.entrance.length === 0 ? null : values.entrance,
            stan: values.flat.length === 0 ? null : values.flat,
            postarina: values.postage,
            otkup: values.redemption,
            povraten_dokument: values.return_document,
            osiguruvanje: values.insurance,
            csv: values.csv,
            delimiter: values.delimiter,
            import_all_or_none: values.import_all_or_none,
          },
        });
      }}
      isBackBtnDisabled={isListTypeLoading}
      isNextBtnDisabled={({ isLastStep, values }) =>
        isListTypeLoading || (isLastStep && !(values as FormValues).csv)
      }
      isStepIndicatorShown={!!listTypeData}
      className="bulk-orders-file-importer"
    >
      <Step
        title={t('List Type')}
        subtitle={t('Select a list type')}
        validationSchema={validationSchemas.listType}
      >
        <ListType listTypeData={listTypeData} listTypeReload={listTypeReload} />
      </Step>

      {!isListTypeLoading && listTypeData?.klient_isSender === '1' && (
        <Step
          title={t('Address Info')}
          subtitle={t('What is the pickup location?')}
          validationSchema={validationSchemas.addressInfo}
        >
          <AddressInfo />
        </Step>
      )}

      {!isListTypeLoading && listTypeData?.changeable_payment === '1' && (
        <Step title={t('Payments')} subtitle={t('Who pays for what?')}>
          <Payments />
        </Step>
      )}

      <Step
        title={t('File')}
        subtitle={t('Upload your Excel or CSV file')}
        validationSchema={validationSchemas.file}
      >
        <FileImport />
      </Step>
    </Stepper>
  );
}

export default FileImporter;
