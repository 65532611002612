import { Field, Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../../context/ToastContext';
import useAxios from '../../../../hooks/useAxios';
import usePrevious from '../../../../hooks/usePrevious';
import { errorToast, successToast } from '../../../../utils/helpers/primereact';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import UnauthPage from '../../../page/UnauthPage';
import {
  FormFields,
  SuccessObj,
  generateErrorMsg,
  getInitialValues,
  getValidationSchema,
  toApiData,
} from './UserActivate.functions';

type RouteParams = {
  code: string;
};

function UserActivate(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  const { code } = useParams<RouteParams>();

  const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);

  const formRef = useRef<FormikProps<any>>(null);
  const toastRef = useContext(ToastContext)?.toastRef!;

  const { data, error, isLoading, reload } = useAxios<SuccessObj>();

  const prevData = usePrevious(data);
  const prevError = usePrevious(error);

  useEffect(() => {
    if (!data || data === prevData) {
      return;
    }

    successToast(
      toastRef,
      t('Success'),
      data?.message ?? t('User successfully activated')
    );

    setDialogVisibility(true);
  }, [t, data, prevData, toastRef]);

  useEffect(() => {
    if (!error || error === prevError) {
      return;
    }

    let err = error?.response?.data;
    errorToast(
      toastRef,
      t('Error'),
      t('Activate user failed {{errorMessage}}.', {
        errorMessage: err ? generateErrorMsg(t, err) : '',
      })
    );

    formRef?.current?.resetForm();
  }, [t, error, prevError, toastRef]);

  function handleFormSubmision(values: FormFields) {
    reload({
      url: '/users/activate',
      data: toApiData(values),
      method: 'PUT',
      headers: { 'X-Client-Id': process.env.REACT_APP_CLIENT_ID },
    });
  }

  return (
    <>
      <Dialog
        header={t('Activation Complete!')}
        visible={dialogVisibility}
        onHide={() => {
          setDialogVisibility(false);
        }}
        footer={
          <Button
            type="button"
            label={t('Login')}
            onClick={() => history.push('/login')}
          />
        }
      >
        <p>{t('Your account has been sucessfully activated.')}</p>
        <p>
          {t(
            'You can now log in using the username and password you chose during the registration.'
          )}
        </p>
      </Dialog>

      <UnauthPage title={t('Activate your account')}>
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(code)}
          validationSchema={getValidationSchema(t)}
          onSubmit={handleFormSubmision}
          validateOnBlur
        >
          {({ isValid }) => (
            <Form>
              <div className="p-fluid">
                <FieldWithErrors name="password" label={t('Password')}>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    className="p-inputtext p-component"
                  />
                </FieldWithErrors>

                <FieldWithErrors
                  name="repassword"
                  label={t('Confirm password')}
                >
                  <Field
                    id="repassword"
                    name="repassword"
                    type="password"
                    className="p-inputtext p-component"
                  />
                </FieldWithErrors>

                <Button
                  type="submit"
                  label={t('Activate')}
                  className="p-mt-5"
                  disabled={!isValid || isLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      </UnauthPage>
    </>
  );
}

export default UserActivate;
