import { TFunction } from 'react-i18next';

import { EmailAction, EmailRecipient } from '../enums/email';

export function getEmailActionLabels(
  t: TFunction
): Record<EmailAction, string> {
  return {
    [EmailAction.COD]: t('Cash on Delivery'),
    [EmailAction.Invoice]: t('Invoice'),
  };
}

export function getEmailRecipientLabels(
  t: TFunction
): Record<EmailRecipient, string> {
  return {
    [EmailRecipient.To]: t('To'),
    [EmailRecipient.Cc]: t('Cc'),
    [EmailRecipient.Bcc]: t('Bcc'),
  };
}
